<template>
    <div>
        <el-input placeholder="请选择机构" :disabled="disabled" v-model.trim="orgName">
        </el-input>
        <el-tree class="filter-tree" ref="jdtree" :disabled="disabled" show-checkbox @check="handleCheckChange"
            :node-key="propsName" :expand-on-click-node="false" :check-on-click-node="true" :data="treeData"
            :props="defaultProps" :default-checked-keys="orgId" :filter-node-method="filterNode">
        </el-tree>
    </div>
</template>
<script>
export default {
    name: "jdTree",
    props: {
        value: {
            type: Array,
            default: () => {
                return []
            }
        },
        defaultProps: {
            type: Object,
            default: () => {
                return {
                    children: 'children',
                    label: 'label'
                }
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        propsName: {
            type: String,
            default: "id"
        },
    },
    watch: {
        value:{
            handler(val){
                this.orgId = val
            },
            immediate: true,
        },
        orgName:{
            handler(val){
                this.$refs.jdtree.filter(val);
            },
        }
    },
    data() {
        return {
            orgName: [],
            orgId: [],
            treeData: [{
                id: "12222",
                label: '一级 1',
                children: [{
                    id: "433333",
                    label: '二级 1-1',
                    children: [{
                        id: "94444",
                        label: '三级 1-1-1'
                    }, {
                        id: "1044440",
                        label: '三级 1-1-2'
                    }, {
                        id: "1244440",
                        label: '三级 1-14'
                    }]
                }]
            }],
        }
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data[this.defaultProps.label].indexOf(value) !== -1;
        },
        async getDataFromApi() {
            // if(this.propsName==='111'){
            //     this.treeData=  await this.$api.login('',{})
            // }else if(this.propsName==='222'){
            //     this.treeData=  await this.$api.login1('',{})
            // }
            console.log(this.value,'valuevalue')
            // this.orgId = this.value
        },
        handleCheckChange(data) {
            this.orgId = this.$refs.jdtree.getCheckedNodes()
            this.orgName = this.$refs.jdtree.getCheckedNodes().map(item => item[this.defaultProps.label])
            this.$emit('input', this.orgId)
        },
    },
    mounted() {
        this.getDataFromApi()
    },
};
</script>
<style scoped lang="scss">
.el-icon-share {
    color: $primary;
    margin: 0 5px;
}

::v-deep .is-current {
    span {}
}
</style>