export default {
  path: '/system',
  name: 'system',
  component: 'router-view.vue',
  redirect: { name: 'person_Info_list' },
  meta: {
    isMenu: true,
    redirect: true,
    title: "系统管理",
  },
  children: [
    {
      path: 'person',
      name: 'person',
      component: 'router-view.vue',
      redirect: { name: 'person_Info_list' },
      meta: {
        isMenu: true,
        redirect: true,
        title: "用户管理",
      },
      children: [
        {
          path: 'personInfo',
          name: 'person_Info',
          component: 'router-view.vue',
          redirect: { name: 'person_Info_list' },
          meta: {
            isMenu: true,
            // redirect:true,
            title: "用户信息管理",
          },
          children: [{
            path: 'list',
            name: 'person_Info_list',
            component: 'person/personInfo/index.vue',
            meta: {
              isMenu: false
            },
          }, {
            path: 'add',
            name: 'person_Info_add',
            component: 'person/personInfo/add.vue',
            meta: {
              title: "新增",
              isMenu: false
            },
          }, {
            path: 'edit',
            name: 'person_Info_edit',
            component: 'person/personInfo/edit.vue',
            meta: {
              title: "修改",
              isMenu: false
            },
          }, {
            path: 'view',
            name: 'person_Info_view',
            component: 'person/personInfo/view.vue',
            meta: {
              title: "查看",
              isMenu: false
            },
          }]
        },
        {
          path: 'prop-config',
          name: 'person_prop_config',
          component: 'person/prop-config.vue',
          meta: {
            isMenu: true,
            title: "用户信息字段配置",
          },
        },
      ]
    },
    {
      path: 'roleManager',
      name: 'roleManager',
      component: 'router-view.vue',
      redirect: { name: 'role_Info_list' },
      meta: {
        isMenu: true,
        title: "角色管理",
      },
      children: [{
        path: 'list',
        name: 'role_Info_list',
        component: 'person/roleManager/index.vue',
        meta: {
          isMenu: false
        },
      }, {
        path: 'add',
        name: 'role_Info_add',
        component: 'person/roleManager/form.vue',
        meta: {
          title: "新增",
          isMenu: false
        },
      }, {
        path: 'edit',
        name: 'role_Info_edit',
        component: 'person/roleManager/form.vue',
        meta: {
          title: "修改",
          isMenu: false
        },
      }, {
        path: 'view',
        name: 'role_Info_view',
        component: 'person/roleManager/form.vue',
        meta: {
          title: "查看",
          isMenu: false
        },
      }]
    },
    {
      path: 'orgmanager',
      name: 'person_orgmanager',
      component: 'person/orgmanager/index.vue',
      meta: {
        isMenu: true,
        title: "组织管理",
      },
    },
    {
      path: 'factoryer',
      name: 'person_factoryer',
      component: 'person/factoyer/index.vue',
      meta: {
        isMenu: true,
        title: "厂商管理",
      },
    },
    {
      path: 'web_config',
      name: 'web_config',
      component: 'person/web-config.vue',
      meta: {
        isMenu: true,
        title: "网站配置",
      },
    },
    {
      path: 'screen',
      name: 'person_screen',
      component: 'screen/index.vue',
      meta: {
        isMenu: true,
        title: "大屏设置",
      },
    },
  ]
}