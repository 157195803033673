import { post } from "@/request/http";

export default {
    // 设备预警相关接口
    postQueryDeviceWarningPage(param,msg) {
        return post("/device/event/device-warning/query/page",param,msg)
    },
    postProcessDeviceWarning(param,msg) {
        return post("/device/event/device-warning/process",param,msg)
    },
    postProcessDeviceWarningBatch(param,msg) {
        return post("/device/event/device-warning/process/batch",param,msg)
    },
    postIgnoreDeviceWarning(param,msg) {
        return post("/device/event/device-warning/ignore",param,msg)
    },
    postIgnoreDeviceWarningBatch(param,msg) {
        return post("/device/event/device-warning/ignore/batch",param,msg)
    },
    postDeviceWarning2Error(param,msg) {
        return post("/device/event/device-warning/to-error",param,msg)
    },
    postDeviceWarning2ErrorBatch(param,msg) {
        return post("/device/event/device-warning/to-error/batch",param,msg)
    },
    postSetDeviceWarningRemark(param,msg) {
      return post("/device/event/device-warning/remark",param,msg)
    },
    postExportDeviceWarningBatch(param,msg) {
        return post("/device/event/device-warning/export/excel",param,msg)
    },
    // exportDeviceWarningBatch(param,msg) {
    //     return post("/device/device-warning/export/excel",param,msg)
    // },
    // deviceWarningRemark(param,msg) { //编辑备注
    //     return post("/device/device-warning/edit/remark",param,msg)
    // },
    // deviceWarningQuery(param,msg) { //主键查询所有信息
    //     return post("/device/device-warning/query",param,msg)
    // },
    // deviceWarningProcessing(param,msg) { //处理中 分页条件查询
    //     return post("/device/device-warning/query/page/processing",param,msg)
    // },
    // deviceWarningPending(param,msg) { //待处理 分页条件查询
    //     return post("/device/device-warning/query/page/pending",param,msg)
    // },
    // deviceWarningProcessed(param,msg) { //已理中 分页条件查询
    //     return post("/device/device-warning/query/page/processed",param,msg)
    // },
    // deviceWarningProcess(param,msg) { //处理
    //     return post("/device/device-warning/process",param,msg)
    // },
    // deviceWarning2Abnormal(param,msg) { //处理
    //     return post("/device/device-warning/to-abnormal",param,msg)
    // },
    // ignoreDeviceWarningBatch(param,msg) {
    //     return post('/device/device-warning/ignore-batch', param,msg)
    // },
    // ignoreWarningByDevice(param, msg) { //忽略
    //     return post("/device/device-warning/ignore", param, msg)
    // },
    // processDeviceWarningBatch(param,msg) {
    //     return post("/device/device-warning/process-batch",param,msg)
    // },
    // deviceWarning2AbnormalBatch(param,msg) {
    //     return post("/device/device-warning/to-abnormal-batch",param,msg)
    // },



    // 监测点预警相关接口
    postQueryMonitorWarningPage(param,msg) { // 分页查询监测点预警信息
        return post("/device/event/monitor-warning/query/page",param,msg)
    },
    postProcessMonitorWarning(param,msg) {
        return post("/device/event/monitor-warning/process",param,msg)
    },
    postProcessMonitorWarningBatch(param,msg) {
        return post("/device/event/monitor-warning/process/batch",param,msg)
    },
    postIgnoreMonitorWarning(param,msg) {
        return post("/device/event/monitor-warning/ignore",param,msg)
    },
    postIgnoreMonitorWarningBatch(param,msg) {
        return post("/device/event/monitor-warning/ignore/batch",param,msg)
    },
    postSetMonitorWarningRemark(param,msg) {
        return post("/device/event/monitor-warning/remark",param,msg)
    },
    postExportMonitorWarningBatch(param,msg) {
        return post("/device/event/monitor-warning/export/excel",param,msg)
    },
    postQueryMonitorWarningDeviceList(param,msg) {
        return post("/device/event/monitor-warning/query/detail",param,msg)
    },
    // exportMonitorWarningBatch(param,msg) {
    //     return post("/device/monitor-warning/export/excel",param,msg)
    // },
    // monitorWarningRemark(param,msg) { //编辑备注
    //     return post("/device/monitor-warning/edit/remark",param,msg)
    // },
    // monitorWarningQuery(param,msg) { //主键查询所有信息
    //     return post("/device/monitor-warning/query",param,msg)
    // },
    // monitorWarningProcessing(param,msg) { //处理中 分页条件查询
    //     return post("/device/monitor-warning/query/page/processing",param,msg)
    // },
    // monitorWarningPending(param,msg) { //待处理 分页条件查询
    //     return post("/device/monitor-warning/query/page/pending",param,msg)
    // },
    // monitorWarningProcessed(param,msg) { //已理中 分页条件查询
    //     return post("/device/monitor-warning/query/page/processed",param,msg)
    // },
    // monitorWarningProcess(param,msg) { //处理
    //     return post("/device/monitor-warning/process",param,msg)
    // },
    // processMonitorWarningBatch(param,msg) {
    //     return post("/device/monitor-warning/process-batch",param,msg)
    // },
    // ignoreWarningByMonitor(param, msg) { //忽略
    //     return post("/device/monitor-warning/ignore", param, msg)
    // },
    // ignoreMonitorWarningBatch(param,msg) {
    //     return post('/device/monitor-warning/ignore-batch', param,msg)
    // },


    // 设备异常
    postQueryDeviceErrorPage(param,msg) {
        return post("/device/event/error/query/page",param,msg)
    },
    postIgnoreDeviceError(param,msg) {
        return post("/device/event/error/ignore",param,msg)
    },
    postIgnoreDeviceErrorBatch(param,msg) {
        return post("/device/event/error/ignore/batch",param,msg)
    },
    postProcessDeviceError(param,msg) {
        return post("/device/event/error/process",param,msg)
    },
    postProcessDeviceErrorBatch(param,msg) {
        return post("/device/event/error/process/batch",param,msg)
    },
    postExportDeviceErrorBatch(param,msg) {
        return post("/device/event/error/export/excel",param,msg)
    },
    postSetDeviceErrorRemark(param,msg) {
        return post("/device/event/error/remark",param,msg)
    },
    // ignoreWarningByAbnormal(param, msg) { //忽略
    //     return post("/device/device-abnormal/ignore", param, msg)
    // },
    // deviceAbnormalQuery(param,msg) { //主键查询所有信息
    //     return post("/device/device-abnormal/query",param,msg)
    // },
    // deviceAbnormalProcessing(param,msg) { //处理中 分页条件查询
    //     return post("/device/device-abnormal/query/page/processing",param,msg)
    // },
    // deviceAbnormalPending(param,msg) { //待处理 分页条件查询
    //     return post("/device/device-abnormal/query/page/pending",param,msg)
    // },
    // deviceAbnormalProcessed(param,msg) { //已理中 分页条件查询
    //     return post("/device/device-abnormal/query/page/processed",param,msg)
    // },
    // deviceErrorType(param, msg) { //模糊搜索
    //     return post("/device/device-abnormal/error-type", param, msg)
    // },
    // ignoreDeviceAbnormalBatch(param,msg) {
    //     return post("/device/device-abnormal/ignore-batch",param,msg)
    // },
    // processDeviceAbnormalBatch(param,msg) {
    //     return post("/device/device-abnormal/process-batch",param,msg)
    // },
    // exportDeviceAbnormalBatch(param,msg) {
    //     return post("/device/device-abnormal/export/excel",param,msg)
    // },
    // deviceAbnormalRemark(param,msg) { //编辑备注
    //     return post("/device/device-abnormal/edit/remark",param,msg)
    // },
    // deviceAbnormalProcess(param,msg) { //编辑备注
    //     return post("/device/device-abnormal/process",param,msg)
    // },
    // delDeviceAbnormalBatch(param,msg) {
    //     return post("/device/device-abnormal/ignore-batch",param,msg)
    // },



}