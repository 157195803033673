const getters = {
    msg(state) {
        var msg = '';
        if (state.psd == '') {
            msg = '密码不能空'
        } else {
            msg = "设置成功"
        }
        return msg;
    }
}
export default getters