<template>
  <el-dialog :title="$store.state.configObj.title" :visible.sync="$store.state.dlShow"
        :width="$store.state.configObj.width || '500px'" class="el-zoom-in-center">
        <template v-if="$store.state.configObj.slotName == '厂商'">
            <el-form :model="form" ref="formName" label-position="left" label-width="80px">
                <el-form-item label="厂商名称" :label-width="$store.state.configObj.labelWidth" prop="name"
                    :rules="!$store.state.configObj.disabled ? { required: true, message: '请输入厂商名称', trigger: 'change' } : null">
                    <el-input :disabled="$store.state.configObj.disabled" clearable v-model.trim="form.name"
                        maxlength="30" show-word-limit autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="厂商规模" :label-width="$store.state.configObj.labelWidth" prop="limitNum">
                    <el-input-number clearable :disabled="$store.state.configObj.disabled" v-model="form.limitNum"
                        :controls="false" :min="0" :max="99999" step-strictly :step="1"></el-input-number>
                </el-form-item>
                <el-form-item label="所属机构" :label-width="$store.state.configObj.labelWidth" prop="limitNum">
                    <jSelectTree :dataList="options.orgNum" v-model="form.orgNum"
                        :disabled="$store.state.configObj.disabled"
                        :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }" />
                </el-form-item>
                <el-form-item label="备注" :label-width="$store.state.configObj.labelWidth" prop="remark">
                    <el-input :disabled="$store.state.configObj.disabled" clearable type="textarea"
                        v-model="form.remark" maxlength="100" show-word-limit rows="4"></el-input>
                </el-form-item>
            </el-form>
        </template>
        <template v-if="$store.state.configObj.slotName == '关联合作厂商'">
            <el-form :model="form" ref="formName" label-position="left" label-width="80px">
                <el-form-item label="合作厂商" label-width="100px" prop="manufacturerId">
                    <el-select clearable filterable v-model="form.manufacturerId" placeholder="请选择"
                        :disabled="$store.state.configObj.disabled">
                        <el-option v-for="item in options.value" :key="item.codeValue" :label="item.codeDesc"
                            :value="item.codeValue">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '异常信息'">
          <el-descriptions :column="2" :size="size" border>
            <el-descriptions-item label="异常名称"><copy-text>{{form.name}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="设备编号"><copy-text>{{form.deviceCode}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="设备名称"><copy-text>{{form.deviceName}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="监测点名称"><copy-text>{{form.monitorName}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="行政区划"><copy-text>{{[form.regionProvince, form.regionCity, form.regionCounty].join('-')}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="详细地址"><copy-text>{{form.address}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="异常时间"><copy-text>{{form.errorTime}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="异常原因"><copy-text>{{form.errorInfo}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="备注" span="3"><copy-text>{{form.remark}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="解决方案" span="3" v-if="form.eventProcess === 'PROCESSED'"><copy-text>{{form.solution}}</copy-text></el-descriptions-item>
          </el-descriptions>
          <jTitle title="处理进度" />
          <el-steps style="width: 100%" :active="form.eventLogsMap && form.eventLogsMap['PROCESSED'] ? 3 : (form.eventLogsMap && form.eventLogsMap['PROCESSING'] ? 1 : 0)" align-center finish-status="success" class="steps">
            <el-step title="待处理">
              <template #description>
                  <span v-if="form.eventLogsMap['PROCESSING'] && form.eventLogsMap['PROCESSING'].createUserName">
                    处理人: <copy-text>{{form.eventLogsMap['PROCESSING'].createUserName}}</copy-text>
                    <br />
                  </span>
                <span v-if="form.eventLogsMap['PROCESSING'] && form.eventLogsMap['PROCESSING'].updateTime">
                    处理时间: <copy-text>{{form.eventLogsMap['PROCESSING'].updateTime}}</copy-text>
                    <br />
                  </span>
                <span v-if="form.eventLogsMap['PROCESSING'] && form.eventLogsMap['PROCESSING'].remark">
                    备注: <copy-text>{{form.eventLogsMap['PROCESSING'].remark}}</copy-text>
                    <br />
                  </span>
              </template>
            </el-step>
            <el-step title="处理中">
              <template #description>
                  <span v-if="form.eventLogsMap['PROCESSED'] && form.eventLogsMap['PROCESSED'].createUserName">
                    处理人: <copy-text>{{form.eventLogsMap['PROCESSED'].createUserName}}</copy-text>
                    <br />
                  </span>
                <span v-if="form.eventLogsMap['PROCESSED'] && form.eventLogsMap['PROCESSED'].updateTime">
                    处理时间: <copy-text>{{form.eventLogsMap['PROCESSED'].updateTime}}</copy-text>
                    <br />
                  </span>
                <span v-if="form.eventLogsMap['PROCESSED'] && form.eventLogsMap['PROCESSED'].remark">
                    备注: <copy-text>{{form.eventLogsMap['PROCESSED'].remark}}</copy-text>
                    <br />
                  </span>
              </template>
            </el-step>
            <el-step title="已处理">
              <template #description>
                  <span v-if="form.eventLogsMap['PROCESSED']">
                    已处理
                  </span>
              </template>
            </el-step>
          </el-steps>
        </template>
        <template v-else-if="$store.state.configObj.slotName === '监测点预警信息'">
          <jTitle title="预警信息" styleProps="margin-top: 0"/>
          <el-descriptions :column="2" :size="size" border>
            <el-descriptions-item label="预警名称"><copy-text>{{form.name}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="预警监测点"><copy-text>{{form.monitorName}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="预警内容"><copy-text>{{form.eventInfo}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="预警位置"><copy-text>{{form.address}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="预警时间"><copy-text>{{form.eventTime}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="预警状态" span="2"><copy-text>{{setLabel(form.warningStatus, options.warningStatus)}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="备注" span="3"><copy-text>{{form.remark}}</copy-text></el-descriptions-item>
            <el-descriptions-item label="解决方案" v-if="form.eventProcess == 'PROCESSED'"><copy-text>{{form.solution}}</copy-text></el-descriptions-item>
          </el-descriptions>
          <jTitle title="预警设备" />
            <el-table style="width:100%;" ref="jTable" :data="form.warningDeviceList"
                      :header-cell-style="{ 'text-align': 'center', 'background': '#ededed' }">
              <el-table-column label="设备编号" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.deviceCode }}</span>
                </template>
              </el-table-column>
              <el-table-column label="预警内容" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.eventInfo }}</span>
                </template>
              </el-table-column>
              <el-table-column label="预警状态" align="center" width="100px">
                <template slot-scope="scope">
                  <span>{{setLabel(scope.row.warningStatus, options.warningStatus)}}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="light" content="监测数据" placement="top-start">
                    <el-button type="text"
                               class="control iconfont iconfont2 icon-jianceshuju"
                               @click="() => {
                                this.$refs.drapMutipleEchart.showEchartDialog({
                                  ...scope.row,
                                  monitorName: scope.row.monitorName,
                                  deviceName: scope.row.name,
                                  deviceId: scope.row.deviceCode,
                                  propertyType: 'MONITOR'
                                })
                              }">
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="light" content="设备状态" placement="top-start">
                    <el-button type="text"
                               class="control iconfont iconfont2 icon-shebeizhuangtai"
                               @click="() => {
                                this.$refs.drapMutipleEchart.showEchartDialog({
                                  ...scope.row,
                                  monitorName: scope.row.monitorName,
                                  deviceName: scope.row.name,
                                  deviceId: scope.row.deviceCode,
                                  propertyType: 'STATE'
                                })
                              }">
                    </el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
        </template>
      <template v-else-if="$store.state.configObj.slotName === '设备预警信息'">
        <jTitle title="预警信息" styleProps="margin-top: 0"/>
        <el-descriptions :column="2" :size="size" border>
          <el-descriptions-item label="预警名称"><copy-text>{{form.name}}</copy-text></el-descriptions-item>
          <el-descriptions-item label="预警设备编号"><copy-text>{{form.deviceCode}}</copy-text></el-descriptions-item>
          <el-descriptions-item label="预警设备名称"><copy-text>{{form.deviceName}}</copy-text></el-descriptions-item>
          <el-descriptions-item label="预警监测点"><copy-text>{{form.monitorName}}</copy-text></el-descriptions-item>
          <el-descriptions-item label="预警内容"><copy-text>{{form.eventInfo}}</copy-text></el-descriptions-item>
          <el-descriptions-item label="预警位置"><copy-text>{{form.address}}</copy-text></el-descriptions-item>
          <el-descriptions-item label="预警时间"><copy-text>{{form.eventTime}}</copy-text></el-descriptions-item>
          <el-descriptions-item label="预警状态" span="2"><copy-text>{{setLabel(form.warningStatus, options.warningStatus)}}</copy-text></el-descriptions-item>
          <el-descriptions-item label="备注" span="3"><copy-text>{{form.remark}}</copy-text></el-descriptions-item>
          <el-descriptions-item label="解决方案" v-if="form.eventProcess == 'PROCESSED'"><copy-text>{{form.solution}}</copy-text></el-descriptions-item>
        </el-descriptions>
        <jTitle title="处理进度" />
        <el-steps style="width: 100%" :active="form.eventLogsMap && form.eventLogsMap['PROCESSED'] ? 3 : (form.eventLogsMap && form.eventLogsMap['PROCESSING'] ? 1 : 0)" align-center finish-status="success" class="steps">
          <el-step title="待处理">
            <template #description>
                  <span v-if="form.eventLogsMap['PROCESSING'] && form.eventLogsMap['PROCESSING'].createUserName">
                    处理人: <copy-text>{{form.eventLogsMap['PROCESSING'].createUserName}}</copy-text>
                    <br />
                  </span>
              <span v-if="form.eventLogsMap['PROCESSING'] && form.eventLogsMap['PROCESSING'].updateTime">
                    处理时间: <copy-text>{{form.eventLogsMap['PROCESSING'].updateTime}}</copy-text>
                    <br />
                  </span>
              <span v-if="form.eventLogsMap['PROCESSING'] && form.eventLogsMap['PROCESSING'].remark">
                    备注: <copy-text>{{form.eventLogsMap['PROCESSING'].remark}}</copy-text>
                    <br />
                  </span>
            </template>
          </el-step>
          <el-step title="处理中">
            <template #description>
                  <span v-if="form.eventLogsMap['PROCESSED'] && form.eventLogsMap['PROCESSED'].createUserName">
                    处理人: <copy-text>{{form.eventLogsMap['PROCESSED'].createUserName}}</copy-text>
                    <br />
                  </span>
              <span v-if="form.eventLogsMap['PROCESSED'] && form.eventLogsMap['PROCESSED'].updateTime">
                    处理时间: <copy-text>{{form.eventLogsMap['PROCESSED'].updateTime}}</copy-text>
                    <br />
                  </span>
              <span v-if="form.eventLogsMap['PROCESSED'] && form.eventLogsMap['PROCESSED'].remark">
                    备注: <copy-text>{{form.eventLogsMap['PROCESSED'].remark}}</copy-text>
                    <br />
                  </span>
            </template>
          </el-step>
          <el-step title="已处理">
            <template #description>
                  <span v-if="form.eventLogsMap['PROCESSED']">
                    已处理
                  </span>
            </template>
          </el-step>
        </el-steps>
      </template>
        <template v-else-if="$store.state.configObj.slotName == '编辑项目'">
            <el-form :model="form" ref="formName" label-position="left" label-width="90px">
                <el-form-item v-if="$store.state.configObj.title != '新增监测点项目'" label="项目编号"
                    :label-width="$store.state.configObj.labelWidth" prop="projectNum">
                    <el-input disabled clearable v-model.trim="form.projectNum"></el-input>
                </el-form-item>
                <el-form-item label="项目名称" :label-width="$store.state.configObj.labelWidth" prop="name"
                    :rules="!$store.state.configObj.disabled ? { required: true, message: '请输入项目名称', trigger: 'change' } : null">
                    <el-input :disabled="$store.state.configObj.disabled" clearable v-model.trim="form.name"
                        maxlength="30" placeholder="请输入" show-word-limit autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="项目负责人" :label-width="$store.state.configObj.labelWidth" prop="managerName"
                    placeholder="请输入">
                    <el-input :disabled="$store.state.configObj.disabled" clearable v-model.trim="form.managerName"
                        maxlength="30" show-word-limit autocomplete="off"></el-input>
                </el-form-item>
              <el-form-item label="关联机构" :label-width="$store.state.configObj.labelWidth" prop="orgList"
                            placeholder="请选择">
<!--                <jSelectTree :dataList="options.orgNum" v-model="form.orgNum"-->
<!--                             :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }" />-->
                <jMenuTree :dataList="options.orgNum" v-model="form.orgList"
                           :disabled="$store.state.configObj.disabled"
                           :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }" />
              </el-form-item>
            </el-form>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '解绑所属机构'">
            <el-form :model="form" ref="formName" label-position="left" label-width="80px">
                <div class="flex-row">
                    <span>当前用户:<span class="control">{{ (form.name||"") + (form.username ? '[' + form.username + ']' : '')
                            }}</span></span>
                    <span>当前机构:<span class="control">{{ form.orgName || '当前机构' }}</span></span>
                </div>
                <jTitle type="title" title="选择新所属机构" />
                <jSelectTree :dataList="options.orgNum" v-model="form.orgNum"
                    :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }" />
            </el-form>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '切换所属机构-用户'">
            <el-form :model="form" ref="formName" label-position="left" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="当前用户：" :label-width="$store.state.configObj.labelWidth">
                            <span>{{ (form.name||"") + (form.username ? '[' + form.username + ']' : '') }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="当前机构：" :label-width="$store.state.configObj.labelWidth">
                            <span>{{ form.orgName || '当前机构' }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <jTitle type="title" title="选择新所属机构" />
                <jSelectTree :dataList="options.orgNum" v-model="form.orgNum"
                    :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }" />
            </el-form>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '切换所属机构-监测点'">
            <el-form :model="form" ref="formName" label-position="left" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="监测点编号：" :label-width="$store.state.configObj.labelWidth" prop="deviceCode">
                            <span>{{ form.monitorPointNum }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="监测点：" :label-width="$store.state.configObj.labelWidth" prop="deviceCode">
                            <span>{{ form.monitorPointName }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <jTitle type="title" title="选择新所属机构" />
                <jSelectTree :dataList="options.orgNum" v-model="form.orgNum"
                    :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }" />
            </el-form>
        </template>
      <template v-else-if="$store.state.configObj.slotName == '添加节点'">
        <el-form :model="form" ref="formName" label-position="left">
          <el-form-item label="" label-width="1px" prop="selectType"
                        :rules="{ required: true, message: '请选择节点类型', trigger: 'change' }">
            <el-radio-group size="medium" v-model="form.selectType">
              <el-radio label="1">添加机构</el-radio>
              <el-radio label="2">关联厂商</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="机构名称" v-if="form.selectType == 1" label-width="80px" prop="orgName"
                        :rules="{ required: true, message: '请输入机构名称', trigger: 'change' }">
            <el-input :disabled="$store.state.configObj.disabled" clearable v-model="form.orgName"
                      maxlength="20" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="选择厂商" v-if="form.selectType == 2" label-width="80px" prop="value"
                        :rules="{ required: true, message: '请选择厂商', trigger: 'change' }">
            <el-select clearable filterable v-model="form.value" placeholder="请选择"
                       :disabled="$store.state.configObj.disabled">
              <el-option v-for="item in options.value" :key="item.codeValue" :label="item.codeDesc"
                         :value="item.codeValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
        <template v-else-if="$store.state.configObj.slotName == '编辑节点'">
            <el-form :model="form" ref="formName" label-position="left" v-loading="!form.selectType">
                <el-form-item label="" label-width="1px" prop="selectType"
                    :rules="{ required: true, message: '请选择节点类型', trigger: 'change' }">
                    <el-radio-group size="medium" v-model="form.selectType">
                        <el-radio label="1">添加机构</el-radio>
                        <el-radio label="2">关联厂商</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="机构名称" v-if="form.selectType == 1" label-width="100px" prop="orgName"
                    :rules="{ required: true, message: '请输入机构名称', trigger: 'change' }">
                    <el-input :disabled="$store.state.configObj.disabled" clearable v-model="form.orgName"
                        maxlength="20" show-word-limit></el-input>
                </el-form-item>
              <el-form-item label="机构负责人" v-if="form.selectType == 1" label-width="100px" prop="value">
                <el-select clearable filterable v-model="form.headerUserId" placeholder="请选择"
                           :disabled="$store.state.configObj.disabled"
                           :options="filteredHeaderUserOptions">
                  <el-option v-for="item in filteredHeaderUserOptions" :key="item.codeValue" :label="item.codeDesc"
                             :value="item.codeValue">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="紧急联系人1" v-if="form.selectType == 1" label-width="100px" prop="value">
                <el-select clearable filterable v-model="form.emergencyContact1UserId" placeholder="请选择"
                           :disabled="$store.state.configObj.disabled"
                           :options="filteredEmergencyContact1Options">
                  <el-option v-for="item in filteredEmergencyContact1Options" :key="item.codeValue" :label="item.codeDesc"
                             :value="item.codeValue">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="紧急联系人2" v-if="form.selectType == 1" label-width="100px" prop="value">
                <el-select clearable filterable v-model="form.emergencyContact2UserId" placeholder="请选择"
                           :disabled="$store.state.configObj.disabled"
                           :options="filteredEmergencyContact2Options">
                  <el-option v-for="item in filteredEmergencyContact2Options" :key="item.codeValue" :label="item.codeDesc"
                             :value="item.codeValue">
                  </el-option>
                </el-select>
              </el-form-item>
                <el-form-item label="选择厂商" v-if="form.selectType == 2" label-width="100px" prop="value"
                    :rules="{ required: true, message: '请选择厂商', trigger: 'change' }">
                    <el-select clearable filterable v-model="form.value" placeholder="请选择"
                        :disabled="$store.state.configObj.disabled">
                        <el-option v-for="item in options.value" :key="item.codeValue" :label="item.codeDesc"
                            :value="item.codeValue">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '选择所属机构'">
            <el-form :model="form" ref="formName" label-position="left" label-width="80px">
                <jTitle type="title" title="所属机构" />
                <jTree treeType="id" v-model="form.orgId"
                    :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }" />
            </el-form>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '批量新增'">
            <el-form :model="form" ref="formName" label-position="left">
                <el-form-item class="down-load" label="上传模板" prop="attachment" label-width="100px" :rules="[{ required: true, message: '附件不能为空', type: 'array', trigger: 'change' },
    { validator: $global.isEmptyArray }]">
                    <importExecl v-model="form.attachment" @close="closed" ref="importExecl"
                        :disabled="$store.state.configObj.disabled" :maxSize="1" :maxNum="1"
                        :actionUrl="form.actionUrl"
                        @onFinish="onBatchAddFinish">
                    </importExecl>
                    <a class="fileName" @click="downloadFileByBlob(form.getTemplate, form.fileName)"
                        :download="form.fileName">模板下载</a>
                </el-form-item>
            </el-form>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '提示'">
            <el-alert :title="$store.state.configObj.content" type="info" :closable="false" show-icon>
                <template slot="">
                    <span class="tips">{{ $store.state.configObj.tips }}</span>
                </template>
            </el-alert>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '处理方案'">
            <el-alert :title="$store.state.configObj.content" type="info" :closable="false" show-icon>
                <template slot="">
                    <span v-if="$store.state.configObj.tips" class="tips">{{ $store.state.configObj.tips }}</span>
                </template>
            </el-alert>
            <el-form :model="form" ref="formName" label-position="left" style="margin-top:15px">
                <!-- <el-form-item label="是否复位" label-width="80px" prop="reset"
                    :rules="!$store.state.configObj.disabled ? { required: true, message: '请选择是否复位', trigger: 'change' } : null">
                    <el-radio-group class="tag-title" v-model="form.reset" size="mini">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item label="处理方案" label-width="80px" prop="solution">
                    <el-input v-model="form.solution" placeholder="请输入" type="textarea" maxlength="300" show-word-limit
                        :rows="5"></el-input>
                </el-form-item>
            </el-form>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '设备预警处理方式'">
            <el-form :model="form" ref="formName" label-position="left" style="margin-top:15px">
                <el-form-item label="" label-width="0px" prop="solutionType"
                    :rules="!$store.state.configObj.disabled ? { required: true, message: '请选择处理方式', trigger: 'change' } : null">
                    <el-radio-group class="tag-title" v-model="form.solutionType" size="mini">
                        <el-radio label="1">忽略本次预警</el-radio>
                        <el-radio label="2">流转至处理中</el-radio>
                        <el-radio label="3">流转至设备异常</el-radio>
                    </el-radio-group>
                </el-form-item>
<!--              <el-form-item label="备注" :label-width="$store.state.configObj.labelWidth" prop="remark" v-if="form.solutionType == 2">-->
              <el-form-item label="备注" :label-width="$store.state.configObj.labelWidth" prop="remark">
                <el-input clearable type="textarea"
                          v-model="form.remark" maxlength="100" show-word-limit rows="4"></el-input>
              </el-form-item>
            </el-form>
        </template>
      <template v-else-if="$store.state.configObj.slotName == '监测点预警处理方式'">
        <el-form :model="form" ref="formName" label-position="left" style="margin-top:15px">
          <el-form-item label="" label-width="0px" prop="solutionType"
                        :rules="!$store.state.configObj.disabled ? { required: true, message: '请选择处理方式', trigger: 'change' } : null">
            <el-radio-group class="tag-title" v-model="form.solutionType" size="mini">
              <el-radio label="1">忽略本次预警</el-radio>
              <el-radio label="2">流转至处理中</el-radio>
            </el-radio-group>
          </el-form-item>
<!--          <el-form-item label="备注" :label-width="$store.state.configObj.labelWidth" prop="remark" v-if="form.solutionType == 2">-->
          <el-form-item label="备注" :label-width="$store.state.configObj.labelWidth" prop="remark" >
            <el-input clearable type="textarea"
                      v-model="form.remark" maxlength="100" show-word-limit rows="4"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-else-if="$store.state.configObj.slotName == '设备异常处理方式'">
        <el-form :model="form" ref="formName" label-position="left" style="margin-top:15px">
          <el-form-item label="" label-width="0px" prop="solutionType"
                        :rules="!$store.state.configObj.disabled ? { required: true, message: '请选择处理方式', trigger: 'change' } : null">
            <el-radio-group class="tag-title" v-model="form.solutionType" size="mini">
              <el-radio label="1">忽略异常</el-radio>
              <el-radio label="2">流转至处理中</el-radio>
            </el-radio-group>
          </el-form-item>
<!--          <el-form-item label="备注" :label-width="$store.state.configObj.labelWidth" prop="remark" v-if="form.solutionType == 2">-->
          <el-form-item label="备注" :label-width="$store.state.configObj.labelWidth" prop="remark">
            <el-input clearable type="textarea"
                      v-model="form.remark" maxlength="100" show-word-limit rows="4"></el-input>
          </el-form-item>
        </el-form>
      </template>
        <template v-else-if="$store.state.configObj.slotName == '产品属性'">
            <div class="flex-start-row" v-if="form.length">
                <div class="el-form-box" v-for="(item, i) in form" :key="i" @click="clickNode(item)">
                    <el-descriptions class="margin-top" :column="1" border>
                        <el-descriptions-item>
                            <template slot="label">
                                名称：
                            </template>
                            {{ item.name }}
                        </el-descriptions-item>
                        <el-descriptions-item v-for="(cItem, cId) in item.valueTypeList" :key="cId">
                            <template slot="label">
                                数据类型：
                            </template>
                            {{ setLabel(cItem.type, options.valueTypeContent) }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
            <el-empty :image-size="200" v-else></el-empty>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '模型定义'">
            <el-form :model="form" ref="formName" label-position="left" label-width="100px">
                <jTitle title="基本信息" />
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="标识：" :label-width="$store.state.configObj.labelWidth"
                            prop="jetlinksPropertyId">
                            <el-input disabled clearable v-model.trim="form.jetlinksPropertyId"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="名称：" :label-width="$store.state.configObj.labelWidth" prop="name">
                            <el-input disabled clearable v-model.trim="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div v-if="['标签定义', '属性定义'].includes(this.$store.state.configObj.title)">
                    <el-row v-for="(valueType, i) in form.valueTypeList" :key="i">
                        <!-- <el-col :span="24" v-if="valueType.pType" style="text-align:center">
                            <el-tag class="m-r-5" type="success">结构体</el-tag>
                        </el-col> -->
                        <el-col :span="12">
                            <el-form-item label="数据类型：" :label-width="$store.state.configObj.labelWidth">
                                <el-tag class="m-r-5" type="success">{{ setLabel(valueType.type,
        options.valueTypeContent)
                                    }}</el-tag>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                v-if="['int', 'long', 'double', 'float'].includes(valueType.type) && valueType.unit"
                                label="单位：" :label-width="$store.state.configObj.labelWidth" prop="unit">
                                <el-tag class="m-r-5" type="success">{{ setLabel(valueType.unit,
        options.units) }}</el-tag>
                            </el-form-item>
                            <el-form-item
                                v-if="['string', 'text'].includes(valueType.type) && ((valueType || {}).maxLength || (valueType.expands || {}).maxLength || (valueType.expands || {}).length)"
                                label="长度：" :label-width="$store.state.configObj.labelWidth" prop="unit">
                                <el-input disabled clearable
                                    :value="((valueType || {}).maxLength || (valueType.expands || {}).maxLength || (valueType.expands || {}).length)">
                                    <template slot="append">Byte</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item v-if="['password'].includes(valueType.type) && valueType.maxLength"
                                label="最大长度：" :label-width="$store.state.configObj.labelWidth" prop="maxLength">
                                <el-input disabled clearable :value="valueType.maxLength">
                                    <template slot="append">Byte</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item v-if="valueType.type === 'boolean' && valueType.trueText" label="布尔值："
                                :label-width="$store.state.configObj.labelWidth">
                                <el-tag class="m-r-5" type="success">{{ valueType.trueText + '（' + valueType.trueValue +
        '）'
                                    }}</el-tag>
                            </el-form-item>
                            <el-form-item v-if="['array', 'enum'].includes(valueType.type)" label="枚举："
                                :label-width="$store.state.configObj.labelWidth">
                                <el-tag class="m-r-5" v-for="(cItem, i) in valueType.elements" :key="i"
                                    type="success">{{
        cItem.text + '（值：' + cItem.value + '）'
    }}</el-tag>
                            </el-form-item>
                            <el-form-item v-if="valueType.type === 'file' && valueType.fileType" label="文件类型："
                                :label-width="$store.state.configObj.labelWidth">
                                <el-tag class="m-r-5" type="success">{{ setLabel(valueType.fileType,
        options.fileType) }}</el-tag>
                            </el-form-item>
                            <el-form-item v-if="valueType.type === 'date' && valueType.format" label="日期格式："
                                :label-width="$store.state.configObj.labelWidth">
                                <el-input disabled clearable :value="valueType.format"></el-input>
                            </el-form-item>
                            <el-form-item v-if="valueType.scale && valueType.scale" label="精度："
                                :label-width="$store.state.configObj.labelWidth" prop="source">
                                <span>{{ valueType.scale }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <el-row v-else-if="this.$store.state.configObj.title == '功能定义'">
                    <el-col :span="12">
                        <el-form-item label="是否异步：" :label-width="$store.state.configObj.labelWidth" prop="storageType">
                            <el-input disabled clearable :value="form.async ? '是' : '否'"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="最大长度：" :label-width="$store.state.configObj.labelWidth" prop="maxLength">
                            <el-input disabled clearable :value="form.maxLength">
                                <template slot="append">Byte</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-else-if="this.$store.state.configObj.title == '事件定义'">
                    <el-col :span="12">
                        <el-form-item label="级别：" :label-width="$store.state.configObj.labelWidth" prop="storageType">
                            <el-tag class="m-r-5" type="success">{{
        setLabel(form.level, options.level
        ) }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="输出参数:" :label-width="$store.state.configObj.labelWidth">
                            <el-tag class="m-r-5" type="success">{{ setLabel((form.valueType || {}).type,
        options.valueTypeContent)
                                }}</el-tag>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" v-if="form.source">
                        <el-form-item label="来源：" :label-width="$store.state.configObj.labelWidth" prop="source">
                            <el-input disabled clearable :value="setLabel(form.source, options.source)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="读写类型：" :label-width="$store.state.configObj.labelWidth" prop="rightType">
                            <el-tag class="m-r-5" type="info" v-for="(cItem, i) in form.rightType" :key="i">{{ cItem
                                }}</el-tag>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="说明：" :label-width="$store.state.configObj.labelWidth" prop="description">
                            <el-input disabled clearable type="textarea" :rows="5"
                                v-model.trim="form.description"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <jTitle title="字典说明" />
                <el-row v-if="$store.state.configObj.title == '属性定义'">
                    <el-form-item label="属性类型 ：" :label-width="$store.state.configObj.labelWidth" prop="propertyType"
                        :rules="!$store.state.configObj.disabled ? { required: true, message: '请选择查询方式', trigger: 'change' } : null">
                        <el-select clearable filterable v-model="form.propertyType" placeholder="请选择"
                            :disabled="$store.state.configObj.disabled">
                            <el-option v-for="item in options.propertyType" :key="item.codeValue" :label="item.codeDesc"
                                :value="item.codeValue">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item :label="setRemarkLabel()" :label-width="$store.state.configObj.labelWidth"
                            prop="remark">
                            <el-input type="textarea" :rows="5" v-model.trim="form.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <jTitle title="其他配置" v-if="!!form.storageType" />
                <el-row v-if="!!form.storageType">
                    <el-col :span="12">
                        <el-form-item label="存储配置：" :label-width="$store.state.configObj.labelWidth" prop="storageType">
                            <el-input disabled clearable
                                :value="setLabel(form.storageType, options.storageType)"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <jTitle v-if="$store.state.configObj.title == '属性定义'" title="指标配置" />
                <el-row v-if="$store.state.configObj.title == '属性定义'">
                    <el-table style="width:100%;" ref="jTable" :data="form.metrics"
                        :header-cell-style="{ 'text-align': 'center', 'background': '#ededed' }">
                        <el-table-column label="标识" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.id }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="名称" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="指标值" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.range ? scope.row.value.join('-') : scope.row.value }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <jTitle v-if="$store.state.configObj.title == '功能定义'" title="输入参数" />
                <jTitle v-if="$store.state.configObj.title == '事件定义'" title="Json对象" />
                <el-row v-if="['功能定义', '事件定义'].includes($store.state.configObj.title)">
                    <el-table style="width:100%;" ref="jTable" :data="form.inputs"
                        :header-cell-style="{ 'text-align': 'center', 'background': '#ededed' }">
                        <el-table-column label="标识" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.id }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="名称" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="数据类型" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>{{ setLabel(scope.row.valueType.type, options.valueTypeContent) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="数值" :min-width="200">
                            <template slot-scope="scope">
                                <span v-if="scope.row.valueType.maxLength || scope.row.valueType.length">最大长度:
                                    <el-tag class="m-r-5" type="success">{{
        scope.row.valueType.maxLength || scope.row.valueType.length
    }}Byte</el-tag></span>
                                <span v-else-if="scope.row.valueType.type === 'boolean'">布尔值:
                                    <el-tag class="m-r-5" type="success">{{ scope.row.valueType.trueText + '（' +
        scope.row.valueType.trueValue
        + '）'
                                        }}</el-tag>
                                    <el-tag class="m-r-5" type="success">{{ scope.row.valueType.falseText + '（' +
        scope.row.valueType.falseValue
        + '）'
                                        }}</el-tag>
                                </span>
                                <span v-else-if="scope.row.valueType.maxLength">单位:
                                    <el-tag class="m-r-5" type="success">{{
        setLabel(scope.row.valueType.unit,
            options.units) }}</el-tag>
                                </span>
                                <span v-else-if="scope.row.valueType.type === 'file'">文件类型:
                                    <el-tag class="m-r-5" type="success">{{ setLabel(scope.row.valueType.fileType,
        options.fileType) }}</el-tag>
                                </span>
                                <span v-else-if="scope.row.valueType.type === 'date'">日期格式:
                                    <el-input style="width:120px" disabled clearable
                                        :value="scope.row.valueType.format"></el-input>
                                </span>
                                <span v-else-if="scope.row.valueType.scale">精度:
                                    <el-tag class="m-r-5" type="success">{{ scope.row.valueType.scale }}</el-tag>
                                </span>
                            </template>
                        </el-table-column>

                    </el-table>
                </el-row>
                <el-row v-if="$store.state.configObj.title == '功能定义'">
                    <el-col :span="12">
                        <el-form-item label="输出参数:" :label-width="$store.state.configObj.labelWidth">
                            <el-tag class="m-r-5" type="success">{{ setLabel((form.output || {}).type,
        options.valueTypeContent)
                                }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="(form.output || {}).unit">
                        <el-form-item label="单位:" :label-width="$store.state.configObj.labelWidth">
                            <el-tag class="m-r-5" type="success">{{ setLabel((form.output || {}).unit, options.units)
                                }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="(form.output || {}).maxLength">
                        <el-form-item label="最大长度:" :label-width="$store.state.configObj.labelWidth">
                            <el-tag class="m-r-5" type="success">{{ (form.output || {}).maxLength
                                }}</el-tag>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '编辑备注'">
            <template slot="">
                <el-input :disabled="$store.state.configObj.disabled" type="textarea" v-model.trim="form.remark"
                    maxlength="100" show-word-limit rows="4"></el-input>
            </template>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '设置产品类型'">
            <el-form :model="form" ref="formName" label-position="left">
                <el-form-item label="产品类型" label-width="80px" prop="productType">
                    <el-select clearable filterable v-model="form.productType" placeholder="请选择"
                        :disabled="$store.state.configObj.disabled">
                        <el-option v-for="item in options.productType" :key="item.codeValue" :label="item.codeDesc"
                            :value="item.codeValue">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '折线图'">
            <template slot="title">
                <div class="flex-row">
                    <el-radio-group class="tag-title" v-model="labelTag" size="mini">
                        <el-radio-button label="1">数据曲线</el-radio-button>
                        <el-radio-button label="2">数据列表</el-radio-button>
                    </el-radio-group>
                    <div class="flex-row">
                        <el-radio-group v-model="tagTime" size="small" style="margin-right:5px"
                            @change="datetimerange = [$tool.getFormatTime(tagTime * 1), $tool.getFormatTime(0)]">
                            <el-radio-button label="1">1天</el-radio-button>
                            <el-radio-button label="7">1周</el-radio-button>
                            <el-radio-button label="30">1月</el-radio-button>
                        </el-radio-group>
                        <el-date-picker v-model="datetimerange" type="datetimerange" range-separator="-" size="mini"
                            style="margin-right:30px;width:200px" start-placeholder="开始日期" end-placeholder="结束日期"
                            align="right" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </div>
                </div>
            </template>
            <echartline style="height:520px" :value="{ labelTag, datetimerange }" />
        </template>
        <template v-else-if="$store.state.configObj.slotName == '绑定设备'">
            <jBindDevice ref="jBindDevice" v-model="form.checkList" />
        </template>
        <template v-else-if="$store.state.configObj.slotName == '物模型TSL'">
            <template>
                <vue-json-editor v-model="form.code" :showBtns="false" :mode="'text'" lang="zh"
                    :expandedOnStart="true" />
            </template>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '修改密码'">
            <template>
                <updatePsw ref="updatePsw" @success="successCb" :form="JSON.parse(JSON.stringify(form))" :showJFrom="showJFrom" />
            </template>
        </template>
        <template v-else-if="$store.state.configObj.slotName == '选择设备类型'">
            <template>
                <div class="flex-wrap">
                    <span @click="setDeviceTypeClass('1')" :class="form.deviceType=='1'?'active':''"
                        class="iconfont icon-diannao"><span>普通设备</span></span>
                    <span @click="setDeviceTypeClass('2')" :class="form.deviceType=='2'?'active':''"
                        class="iconfont icon-shebei1"><span>聚合设备</span></span>
                </div>
            </template>
        </template>
        <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="cancle" v-if="!$store.state.configObj.hideCancle">{{ $store.state.configObj.leftText || '取 消' }}</el-button>
            <el-button size="mini" type="danger" @click="centerClick" v-if="$store.state.configObj.centerText">{{ $store.state.configObj.centerText || '解 绑' }}</el-button>
            <el-button type="primary" size="mini" v-if="!$store.state.configObj.hideSubmit" v-preventReClick
                @click="submit" :loading="submitLoading || $store.state.dlSubmitLoading">
              {{ $store.state.configObj.rightText || '确 定' }}
            </el-button>
        </div>
    </el-dialog>
</template>
<script>
import units from "./units.js"
import echartline from "@/components/line.vue";
import vueJsonEditor from 'vue-json-editor'
import updatePsw from "@/components/updatePsw.vue";
import CopyText from "@/components/jCopyText/index.vue";
import drapMutipleEchart from "@/components/drapMutipleEchart.vue";

export default {
    data() {
        return {
            form: {
                selectType: "",
                solution: "预警(异常)错误上报!"
            },
            options: {
                value: []
            },
            labelTag: '1',
            datetimerange: [],
            tagTime: '',
            submitLoading: false,
            loading: true,
        };
    },
    components: {drapMutipleEchart, CopyText, echartline, vueJsonEditor, updatePsw },
    watch: {
        "$store.state.dlShow": {
            handler(newVal) {
                this.showJFrom = newVal
                if (newVal) {
                    this.init(this.$store.state.dlFormObj, newVal)
                } else {
                    this.form = {
                        selectType: "",
                        solution: "预警(异常)错误上报!"
                    }
                }
            },
            immediate: true,
        },
    },
    // 自定义指令 —— 拖动div
    directives: {
        preventReClick(el, binding) {
            el.addEventListener('click', () => {
                if (!el.disabled) {
                    el.disabled = true
                    setTimeout(() => {
                        el.disabled = false
                    }, binding.value || 2000)
                }
            })
        }
    },
    mounted() {
        // this.init()
    },
    methods: {
        successCb(res) {
           this.$store.commit('setDlShow', false)
        },
        setDeviceTypeClass(idx) {
            this.$store.commit('setDlShow', false)
            this.$router.push({
                name: idx == '1' ? "device_manage_detail" : "deviceMul_manage_detail",
                query: {
                    isEdit: true
                }
            })
        },
        setRemarkLabel() {
            let reamrkLabel = {
                '属性定义': '属性备注',
                '功能定义': '功能备注',
                '事件定义': '事件备注',
                '标签定义': '标签备注',
            }
            return reamrkLabel[this.$store.state.configObj.title] + '：'
        },
        gotoDetail(id, name) {
            this.$store.commit('setDlShow', false)
            this.$router.push({
                name
            })
            sessionStorage.setItem('deviceId', id)
            let pageNum = parseInt(idx / 50) + 1
            sessionStorage.setItem('pageNum', pageNum)
        },
        async init(val, show) {
            let newVal = JSON.parse(JSON.stringify(val))
            if (['添加节点', '关联合作厂商'].includes(this.$store.state.configObj.slotName)) {
                let res = await this.$api.manufacturerQueryPageall({
                    entity: {},
                })
                if (res) {
                    this.options.value = res.map(item => {
                        return { codeDesc: item.name, codeValue: item.id }
                    })
                }
            } else if  (['编辑节点'].includes(this.$store.state.configObj.slotName)) {
                // 查询厂商options
                let res = await this.$api.manufacturerQueryPageall({
                  entity: {},
                })
                if (res) {
                  this.options.value = res.map(item => {
                    return { codeDesc: item.name, codeValue: item.id }
                  })
                }
                // 查询用户options
                const param = {
                  entity: {
                    orgNum: this.$store.state.dlFormObj.orgNum,
                  }
                }
                res = await this.$api.userQuery(param);
                if (res) {
                  this.options.userInfos = res.map(item => {
                    // return { codeDesc: item.name, codeValue: item.id }
                    return { codeDesc: item.username, codeValue: item.id }
                  })
                }
            } else if (["设备预警信息", "预警信息", "异常信息"].includes(this.$store.state.configObj.slotName)) {
                this.$set(this.options, "warningStatus", JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS)
            } else if (["监测点预警信息",].includes(this.$store.state.configObj.slotName)) {
                this.$set(this.options, "warningStatus", JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS)
            }
            else if (['编辑项目', '解绑所属机构', '厂商', '切换所属机构-用户', '切换所属机构-监测点'].includes(this.$store.state.configObj.slotName)) {
                let optionsOrgNum = await this.$api.orgTreeQuery({ entity: {} })
                this.$set(this.options, 'orgNum', [optionsOrgNum])
            } else if (this.$store.state.configObj.slotName == '模型定义') {
                this.$set(this.options, 'state', JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.STORAGE_TYPE)
                let valueTypeContent = [
                    { codeDesc: "int(整数型)", codeValue: 'int' },
                    { codeDesc: "array(数组)", codeValue: 'array' },
                    { codeDesc: "boolean(布尔型)", codeValue: 'boolean' },
                    { codeDesc: "date(时间型)", codeValue: 'date' },
                    { codeDesc: "double(双精度浮点型)", codeValue: 'double' },
                    { codeDesc: "enum(枚举)", codeValue: 'enum' },
                    { codeDesc: "file(文件)", codeValue: 'file' },
                    { codeDesc: "float(单精度浮点型)", codeValue: 'float' },
                    { codeDesc: "geoPoint(地理位置)", codeValue: 'geoPoint' },
                    { codeDesc: "long(长整数型)", codeValue: 'long' },
                    { codeDesc: "object(结构体)", codeValue: 'object' },
                    { codeDesc: "password(密码)", codeValue: 'password' },
                    { codeDesc: "text(字符串)", codeValue: 'text' },
                    { codeDesc: "string(字符串)", codeValue: 'string' },
                ]
                this.$set(this.options, 'valueTypeContent', valueTypeContent)
                this.$set(this.options, 'units', units)
                let source = [
                    { codeDesc: "设备", codeValue: 'device' },
                    { codeDesc: "规则", codeValue: 'rule' },
                    { codeDesc: "手动", codeValue: 'manual' },
                ]
                this.$set(this.options, 'source', source)
                let fileType = [
                    { codeDesc: "URL(链接)", codeValue: 'url' },
                    { codeDesc: "Base64(Base64编码)", codeValue: 'base64' },
                    { codeDesc: "Binary", codeValue: 'binary' },
                ]
                this.$set(this.options, 'fileType', fileType)
                let propertyType = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.PRODUCT_PROPERTY_TYPE
                this.$set(this.options, 'level', JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.EVENT_LEVEL)
                this.$set(this.options, 'propertyType', propertyType)
                this.$set(this.options, 'storageType', JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.STORAGE_TYPE)
            } else if (this.$store.state.configObj.slotName == '产品属性') {
                let valueTypeContent = [
                    { codeDesc: "int(整数型)", codeValue: 'int' },
                    { codeDesc: "array(数组)", codeValue: 'array' },
                    { codeDesc: "boolean(布尔型)", codeValue: 'boolean' },
                    { codeDesc: "date(时间型)", codeValue: 'date' },
                    { codeDesc: "double(双精度浮点型)", codeValue: 'double' },
                    { codeDesc: "enum(枚举)", codeValue: 'enum' },
                    { codeDesc: "file(文件)", codeValue: 'file' },
                    { codeDesc: "float(单精度浮点型)", codeValue: 'float' },
                    { codeDesc: "geoPoint(地理位置)", codeValue: 'geoPoint' },
                    { codeDesc: "long(长整数型)", codeValue: 'long' },
                    { codeDesc: "object(结构体)", codeValue: 'object' },
                    { codeDesc: "password(密码)", codeValue: 'password' },
                    { codeDesc: "text(字符串)", codeValue: 'text' },
                    { codeDesc: "string(字符串)", codeValue: 'string' },
                ]
                this.$set(this.options, 'valueTypeContent', valueTypeContent)
            } else if (this.$store.state.configObj.slotName == '设置产品类型') {
                this.$set(this.options, 'productType', [
                    { codeDesc: "请选择", codeValue: '' },
                    { codeDesc: "深部位移采集器产品", codeValue: 'collector' },
                    { codeDesc: "深部位移传感器产品", codeValue: 'sensor' },
                    { codeDesc: "其他产品", codeValue: 'other' },
                ])
            }
            let newForm = {}
            if (newVal && Object.keys(newVal).length) {
                if (Array.isArray(newVal)) {
                    newForm = newVal || []
                } else {
                    newForm = Object.assign(newForm, newVal);
                }
            } else {
                this.form = {}
            }
            for (let key of Object.keys(newForm)) {
                this.$set(this.form, key, newForm[key])
            }
            this.datetimerange = [this.$tool.getFormatTime(15), this.$tool.getFormatTime(0)]
            this.$nextTick(() => {

                this.$refs.formName && this.$refs.formName.clearValidate()
            })
        },
        setLabel(value, list, label) {
            let labels = ""
            if (Array.isArray(value) && value.length) {
                labels = (list || []).filter(item => value.includes(item.codeValue)).map(item => item.codeDesc).join(',')
                return labels
            } else {
                labels = (list || []).find(item => item.codeValue == value) || {}
                return labels.codeDesc || ''
            }
        },
        setDeviceType(value, list) {
            let obj = (list || []).find(item => item.codeValue == value) || {}
            return obj.deviceTypeValue || ''
        },
        validArea(rule, value, callback) { //验证用户名或昵称经常用到: 只能中英文，数字，下划线，减号
            if (!this.form.provinceCode) {
                callback(new Error('请选择省份'))
            } else if (!this.form.cityCode) {
                callback(new Error('请选择城市'))
            } else if (!this.form.countyCode) {
                callback(new Error('请选择区'))
            } else {
                callback()
            }
        },
        setObject(value, list, idx) {
            let labels = ""
            if (Array.isArray(value) && value.length) {
                this.form.monitorName = (list || []).filter(item => value.includes(item.codeValue)).map(item => item.codeDesc).join(',')
                this.form.monitorPointNum = (list || []).filter(item => value.includes(item.codeValue)).map(item => item.codeNum).join(',')
            } else {
                labels = (list || []).find(item => item.codeValue == value) || {}
                this.options.monitorId = this.options.monitorId.map(item => {
                    if (this.form.monitorId.includes(item.codeValue)) {
                        item.disabled = true
                    } else {
                        item.disabled = false
                    }
                    return item
                })
                this.form.monitorName = labels.codeDesc || ''
                this.form.monitorPointNum = labels.codeNum || ''
                this.form.monitorProjectId[idx] = labels.monitorProjectId || ''
                if (idx == 0) {
                    if (!labels.provinceCode) return
                    this.form.provinceCode = labels.provinceCode
                    this.getSubOption(this.form.provinceCode, 'cityCode')
                    this.form.provinceName = this.setLabelByName(this.form.provinceCode, this.options.provinceCode)
                    this.form.cityCode = labels.cityCode
                    this.getSubOption(this.form.cityCode, 'countyCode')
                    this.form.cityName = this.setLabelByName(this.form.cityCode, this.options.cityCode)
                    this.form.countyCode = labels.countyCode
                    console.log(labels, 'labelslabelslabels')
                    this.form.countyName = labels.countyName
                }
            }
        },
        cancle() {
            if (this.$store.state.configObj.slotName == '批量新增') {
                this.$refs.importExecl && this.$refs.importExecl.clearFiles()
            }
            this.$set(this, 'form', {
                selectType: "",
                solution: "预警(异常)错误上报!"
            })
            this.$store.commit('setDlShow', false)
        },
        setLabelByName(value, list, label) {
            let labels = ""
            if (Array.isArray(value) && value.length) {
                labels = (list || []).filter(item => value.includes(item.id)).map(item => item.name).join(',')
                return labels
            } else {
                labels = (list || []).find(item => item.id == value) || {}
                return labels.name || ''
            }
        },
        getSubOption(parentId, key) {
            this.$api.regionSubList({ entity: { id: parentId } }).then(res => {
                if (res) {
                    this.$set(this.options, key, res)
                    // this.$set(this.form, key, '')
                }
            })
        },
        closed() {
            this.submitLoading = false
            this.$bus.$emit('dlSubmit', { name: "关闭并搜索", title: "关闭并搜索" })
        },
        onBatchAddFinish() {
          this.submitLoading = false;
        },
        submit() {
            if (this.$refs.formName) {
                this.$refs.formName.validate((valid) => {
                    if (valid) {
                        if (this.$store.state.configObj.slotName == '批量新增') {
                            this.submitLoading = true;
                            this.$refs.importExecl.uploadFile()
                        } else {
                            this.$bus.$emit('dlSubmit', { name: this.$store.state.configObj.name || this.$store.state.configObj.slotName, title: this.$store.state.configObj.title || '', formData: this.form });
                        }
                    }
                });
            } else if (['修改密码'].includes(this.$store.state.configObj.title)) {
                this.$refs.updatePsw.saveFun()
            } else {
                if (this.$store.state.configObj.slotName == '绑定设备') {
                    this.form.checkList = this.$refs.jBindDevice.getCheckList()
                } else if (['批量导出', '导出'].includes(this.$store.state.configObj.title)) {
                    this.downloadFileByBlob(this.form.actionUrl, this.form.fileName)
                }
                this.$store.commit('setDlShow', false);
                this.$bus.$emit('dlSubmit', { name: this.$store.state.configObj.name || this.$store.state.configObj.slotName, title: this.$store.state.configObj.title || '', formData: this.form });
            }
        },
        centerClick(){
            this.$bus.$emit('dlSubmit', { name:"centerClick", title: "centerClick", formData: this.form });
        },
        downloadFileByBlob(getTemplate, fileName) {
          this.submitLoading = true;
            let searchable = JSON.parse(JSON.stringify(this.$store.state.searchable))
            if (Array.isArray(searchable.bindRoleInfos)) {
                searchable.bindRoleInfos = searchable.bindRoleInfos.map(item => {
                    return { id: item }
                })
            } else {
                searchable.bindRoleInfos = [{ id: searchable.bindRoleInfos }]
            }
            if (this.form.data) {
                searchable = this.form.data
            }
            searchable.pageNum = 1
            searchable.pageSize = 1000
            this.$api[getTemplate]({ entity: { ...searchable } }).then(res => {
                if (res) {
                    this.$tool.downFileByUrl(res.fileId, fileName)
                }
              this.submitLoading = false;
            })
        },
        clickNode(row) {
            let data = {
                name: this.$store.state.configObj.name || this.$store.state.configObj.slotName,
                title: this.$store.state.configObj.title || '',
                formData: row,
            }
            if (this.$store.state.configObj.deviceId) {
                data.deviceId = this.$store.state.configObj.deviceId
            }
            this.$bus.$emit('dlSubmit', data);
        },
    },
    computed: {
      filteredHeaderUserOptions() {
        return this.options.userInfos.filter(item =>
            item.codeValue !== this.form.emergencyContact1UserId &&
            item.codeValue !== this.form.emergencyContact2UserId
        );
      },
      filteredEmergencyContact1Options() {
        return this.options.userInfos.filter(item =>
            item.codeValue !== this.form.headerUserId &&
            item.codeValue !== this.form.emergencyContact2UserId
        );
      },
      filteredEmergencyContact2Options() {
        return this.options.userInfos.filter(item =>
            item.codeValue !== this.form.headerUserId &&
            item.codeValue !== this.form.emergencyContact1UserId
        );
      }
    },
    beforeDestroy() {
        this.submitLoading = false;
        this.$store.commit('setDlShow', false);

    },
};
</script>
<style scoped lang="scss">
::v-deep .el-alert--info.is-light {
    background: rgba(244, 244, 245, .3);
}

::v-deep .el-dialog {
    margin: auto;
    margin-top: 0 !important;
    transform: translateY(-50%);
    top: 50%;
    max-width: 800px;
}

::v-deep .el-form--label-left .el-form-item__label {
    text-align: right;
}

::v-deep .jsoneditor-vue {
    height: 50vh;
}

::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-input .el-input__inner,
::v-deep .el-input,
::v-deep .el-range-editor.el-input__inner {
    max-width: 220px;
}

.tips {
    font-family: "Microsoft YaHei", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: $error;
    line-height: 22px;
}

::v-deep .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
}

.el-input-number {
    position: relative;
    width: 100%;

    ::before {
        content: "人";
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $border;
    }
}

.fileName {
    color: $primary;
    font-size: 12px;
    cursor: pointer;
    width: 80px;
    position: absolute;
    top: 0;
    right: 0;
}

.down-load {
    position: relative;
}

::v-deep .el-form-item__content span {
    line-height: 18px
}

::v-deep .el-form-item__content .el-tag {
    line-height: unset
}

::v-deep .el-form-item__content {
    max-width: inherit
}

::v-deep .h-2 .el-form-item__label {
    line-height: 16px;
}

.flex-start-row {
    width: calc(100% - 20px);
}

.flex-start-row>.el-form-box {
    width: calc((100% - 105px - 8px)/ 4);
    height: 150px;
    margin-top: 15px;
    margin-right: 15px;
    // box-shadow:0px 0px 5px #b6b5b5;
    border: 1px solid #e8e8e8;
    cursor: pointer;
}

.flex-start-row>.el-form-box:nth-child(4n) {
    margin-right: 0;
}

.el-icon-circle-plus-outline {
    cursor: pointer;
    margin-left: 10px;
    font-size: 18px;
}

.el-icon-remove-outline {
    cursor: pointer;
    margin-left: 5px;
    font-size: 18px;
}

.iconfont {
    font-size: 30px;
    margin: 0 15px;
    cursor: pointer;
    padding: 10px;
    border: 2px dashed #ccc;
    border-radius: 3px;

    span {
        font-size: 14px;
        // line-height: 30px;
        margin-left: 10px;
        transform: translateY(-5px);
        display: inline-block;
    }
}

.iconfont:hover,
.iconfont.active {
    color: #409EFF;
    border-color: #409EFF;
}

.iconfont2 {
  margin: 0 2px;
  border: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  span {
  }
}

.steps {
  ::v-deep .el-step{
    .el-step__description {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
}
</style>