<template>
  <span @click="onCopy">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'CopyText',
  data() {
    return {

    }
  },
  methods: {
    onCopy(data) {
      // console.log(data)
      // if (window.clipboardData) {
      //   window.clipboardData.setData('text', data);
      //   this.$message.success(`复制成功!`)
      // } else {
      //   (function () {
      //     document.oncopy = function (e) {
      //       e.clipboardData.setData('text', data);
      //       e.preventDefault();
      //       document.oncopy = null;
      //     }
      //   })('要复制的内容');
      //   document.execCommand('Copy');
      //   this.$message.success(`复制成功!`)
      // }
      const text = this.$el.innerText;
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$message.success(`复制成功!`)
    }
  }
}
</script>

<style scoped lang="scss">
span:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>