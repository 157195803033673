<template>
    <div class="p-l-50 w-auto">
        <jForm :pageName="''" ref="persionform" :isShow="showJFrom" :isEdit="isEdit" @save="save"
            :baseForm="baseForm" :onSubmit="false" :jForm="formData" :showControl="false" :center="true" formWidth="400px" />
    </div>
</template>
<script>

export default {
    name: "formEdit",
    props: {
        form: [Object, Array],
        showJFrom: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            baseForm: [
                {
                    label: '原密码', prop: 'userOldPassword', type: 'password', default: '', placeholder: '请输入原密码', span: 24,
                    rules: [{ required: true, message: '请输入原密码', trigger: 'change' }]
                },
                {
                    label: '新密码', prop: 'userNewPassword', type: 'password', default: '', placeholder: '请输入新密码', span: 24,
                    rules: [{ required: true, validator: this.$global.password, trigger: ['change', 'blur'] }]                },
                {
                    label: '确认新密码', prop: 'confirmPassword', type: 'password', default: '', placeholder: '请输入确认新密码',
                    span: 24, valiFun: "confirmPassword", valiprop: 'userNewPassword',
                    rules: [{ required: true, validator: this.$global.confirmPassword, trigger: ['change', 'blur'] }]
                },
            ],
            isEdit: true,
            formData: {},
            pageName: "修改密码"
        };
    },
    watch: {
        form: {
            handler(newVal) {
                this.pageName = newVal.pageName
                if (newVal.pageName == '管理员') {
                    this.baseForm = [
                        {
                            label: '管理员密码', prop: 'adminPassword', type: 'password', default: '', placeholder: '请输入管理员密码', span: 24,
                            rules: [{ required: true, message: '请输入管理员密码', trigger: 'change' }]
                        },
                        {
                            label: '用户新密码', prop: 'userNewPassword', type: 'password', default: '', placeholder: '请输入新密码', span: 24,
                            rules: [{ required: true, validator: this.$global.password, trigger: ['change', 'blur'] }]
                        },
                        {
                            label: '确认新密码', prop: 'confirmPassword', type: 'password', default: '', placeholder: '请输入确认新密码',
                            span: 24, valiFun: "confirmPassword", valiprop: 'userNewPassword',
                            rules: [{ required: true, validator: this.$global.confirmPassword, trigger: ['change', 'blur'] }]
                        },
                    ]
                }
            },
            immediate: true,
        },
    },
    mounted() {

    },
    methods: {

        saveFun() {
            this.$refs.persionform.saveFun()
        },
        save(data) {
            let formData = JSON.parse(JSON.stringify(data))
            if (this.pageName == '修改密码') {
                this.$api.changePws({ entity: { ...formData } }, '修改成功').then(res => {
                    if (res) {
                        this.$emit('success', res)
                        sessionStorage.setItem('access_token', '')
                        this.$store.commit('setAccessToken', '')
                        window.location.reload();
                        sessionStorage.setItem('menulist', JSON.stringify([]))
                    }
                })
            } else {
                this.$api.changePwsByAdmin({ entity: { ...formData, userId: this.form.id } }, '修改成功').then(res => {
                    if (res) {
                        this.$emit('success', res)
                    }
                })
            }
        },
    },
}
</script>
<style scoped lang="scss">
.w-auto {
    margin: 0 auto;
}
</style>