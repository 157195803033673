<template>
    <div class="h-100 w-100" style="position: relative;" >
        <div class="h-100 w-100"
            v-if="Object.keys(chartData).length && chartData.seriesData && chartData.seriesData.length && chartData.labelTag == '1'">
            <div id="container" style="height: 100%"></div>
            <div class="flex-start-row">最新的数据:
                <span v-for="(item, i) in nowInfo" style="margin-left: 10px;color:#91cc75">{{ item.name }}:{{ item.value
                    }}</span>
            </div>
        </div>
        <div class="h-100 w-100" v-else-if="tableData.length && chartData.labelTag == '2'">
            <jTable v-model="columnConfig" :tableData="tableData" :total="total" @search="search"
                :controlName="''">
            </jTable>
        </div>
        <el-empty v-else :image-size="200"></el-empty>
    </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
    name: 'HomeView',
    props: {
        value: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            chartData: {

            },
            loading: false,
            total: 0,
            tableData: [],
            columnConfig: [],
            showTable: false,
            keyList: [],
            timestamps: [],
            nowInfo: []
        }
    },
    watch: {
        value: {
            handler(newDate) {
                if (!this.$store.state.dlShow) return
                this.chartData = newDate || {}
                this.init()
            },
            immediate: true
        },
    },
    mounted() {

    },
    methods: {
        init() {
            
            if (this.chartData.labelTag == '1') {
                this.queryDevicePropertiesData()
            } else {
                this.$bus.$emit('changePageNum', 1)
                this.queryList({ pageNum: 1, pageSize: 10 })
            }
        },
        setTableLabel() {
            let columnConfig = []
            for (let key of this.keyList) {
                if (key == 'timestamp') {
                    columnConfig.unshift({ label: '时间', prop: key })
                } else {
                    columnConfig.push({ label: key, prop: key })
                }
            }
            return columnConfig
        },
        queryDevicePropertiesData() {
            let entity = {
                deviceId: this.$store.state.configObj.deviceId,
                propertys: this.$store.state.configObj.propertys,
                propertyType: this.$store.state.configObj.propertyType,
            }
            if (Array.isArray(this.chartData.datetimerange) && this.chartData.datetimerange.length == 2) {
                let startTime = this.chartData.datetimerange[0]
                let endTime = this.chartData.datetimerange[1]
                if (startTime && endTime) {
                    entity.where = `createTime<=${new Date(endTime).getTime()} and createTime>=${new Date(startTime).getTime()}`
                }
            }
            this.chartData.seriesData = []
            if (!entity.propertys) {
                
                return
            }
            this.$api.queryDevicePropertiesData({ entity }).then(resData => {
                
                if (resData) {
                    this.timestamps = []
                    this.chartData.seriesData = []
                    this.nowInfo = []
                    for (let item of resData || []) {
                        var values = item.map(cItem => {
                            this.timestamps.push(this.convertToBeijingTime(cItem.timestamp))
                            return [cItem.timestamp, cItem.value, cItem.unit || ''];
                        });
                        if (item && item[0] && item[0].propertyName) {
                            this.nowInfo.push({ name: item[0].propertyName, value: values[0][1] + values[0][2] })
                            this.chartData.seriesData.push({
                                name: item[0].propertyName,
                                type: 'line',
                                symbolSize: 3,
                                data: values
                            })
                        }
                    }
                   
                    this.setOption()
                }
            })
        },

        setOption() {
            let _this = this
            let option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        var timeString = `时间: ${_this.convertToBeijingTime(params[0].data[0])}<br/>`;
                        var valueStrings = params.map(param => `${param.seriesName}: ${param.value[1]} ${param.value[2]}`).join('<br/>');
                        return timeString + valueStrings;
                    }
                },
                legend: {
                    data: _this.chartData.seriesData.map(series => series.name)
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    right: 10,
                    top: 10,
                    feature: {
                        saveAsImage: {},
                        restore: {}
                    }
                },
                dataZoom: [
                    {
                        startValue: this.timestamps[0],
                        endValue: this.timestamps[this.timestamps.length - 1]
                    },
                    {
                        type: 'inside',
                        xAxisIndex: 0,
                        zoomOnMouseWheel: true, // 滚轮是否触发缩放
                        // moveOnMouseMove: true, // 鼠标滚轮触发滚动
                        // moveOnMouseWheel: true,
                        // "zoomLock": false,
                    }
                ],
                xAxis: {
                    type: 'time',
                },
                yAxis: {
                     // minInterval:0.5,
                },
                series: _this.chartData.seriesData
            }
            this.$nextTick(() => {
                let dom = document.getElementById('container');
                let myChart = echarts.init(dom);
                if (option && typeof option === 'object') {
                    myChart.setOption(option);
                }
                window.addEventListener('resize', myChart.resize);
            })
        },
        convertToBeijingTime(timestamp) {
            return new Date(timestamp).toLocaleString()
        },
        search(formData) {
            this.queryList(formData)
        },
        async queryList(formData) {
            let params = {
                entity: formData || {},
                pageSize: this.$store.state.pageSize,
                pageNum: formData.pageNum
            }
            params.entity.deviceId = this.$store.state.configObj.deviceId
            params.entity.propertys = this.$store.state.configObj.propertys
            params.entity.propertyType = this.$store.state.configObj.propertyType
            if (Array.isArray(this.chartData.datetimerange) && this.chartData.datetimerange.length == 2) {
                let startTime = this.chartData.datetimerange[0]
                let endTime = this.chartData.datetimerange[1]
                if (startTime && endTime) {
                    params.entity.where = `createTime<=${new Date(endTime).getTime()} and createTime>=${new Date(startTime).getTime()}`
                }
            }
            if (!params.entity.deviceId) return
            delete params.entity.pageSize
            delete params.entity.pageNum
            this.showTable = false
            let res = await this.$api.queryDevicePropertiesDataPage(params)
            if (res) {
                this.keyList = []
                this.tableData = (res.resultList || []).map(item => {
                    item.timestamp = new Date(item.timestamp * 1).toLocaleString()
                    for (let key of Object.keys(item) || []) {
                        !this.keyList.includes(key) && this.keyList.push(key)
                    }
                    return item
                })
                this.columnConfig = this.setTableLabel()
                this.total = res.totalNum
                // this.$nextTick(()=>{
                //     this.showTable = true
                // })
            }
            
        },
    },
}
</script>
<style scoped lang="scss">
.tag-title {
    position: absolute;
    left: 0px;
    top: - 50px;
}
</style>