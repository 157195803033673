<template>
  <div class="login login-bg flex-column" v-loading.fullscreen="fullscreenLoading" element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.7)">
    <img class="login-bg-img" :src="setSystemConfig.loginBackgroundFileUrl?$FileBaseURL+setSystemConfig.loginBackgroundFileUrl:require('@/assets/img/login.png')" />
    <div class="title" style="position: relative;">
      <!-- 使用地灾作为默认logo -->
      <!-- <img class="img-1" :src="setSystemConfig.loginBackgroundLogoFileUrl?$FileBaseURL+setSystemConfig.loginBackgroundLogoFileUrl:require('@/assets/img/login-title.png')" />-->
      <!-- 使用星浩科作为默认logo -->
      <img class="img-1" :src="setSystemConfig.loginBackgroundLogoFileUrl?$FileBaseURL+setSystemConfig.loginBackgroundLogoFileUrl:require('@/assets/web-config/login-logo.png')" />
    </div>
    <div class="login-wrap flex-wrap">
<!--      <span class="login-title">{{ setSystemConfig.systemName || '地质灾害预警管理系统' }}</span>-->
      <span class="login-title">{{ setSystemConfig.systemName || '监测预警管理系统' }}</span>
      <el-form class="login-input" ref="formName" :model="loginInfo" label-width="0">
        <el-form-item prop="username" :rules="[{ required: true, message: '请输入用户名和用户编号', trigger: 'change' },
        { validator: $global.userName }]">
          <el-input type="text" v-model.trim="loginInfo.username" :placeholder="loginType == 1 ? '用户名和用户编号' : '手机号'">
            <template slot="prefix">
              <img src="@/assets/img/u22.svg" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="loginType == 1" prop="password" :rules="[{ required: true, message: '请输入登录密码', trigger: 'change' },]">
          <el-input :type="showPassword ? 'text' : 'password'" v-model.trim="loginInfo.password" placeholder="登录密码">
            <template slot="prefix">
              <img src="@/assets/img/u14.svg" />
            </template>
            <template slot="suffix">
              <el-switch v-model="showPassword">
              </el-switch>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password" v-if="loginType == 3" :rules="[{ required: true, message: '请输入登录密码', trigger: 'change' },
        { validator: confirmPassword }]">
          <el-input type="text" v-model.trim="loginInfo.password" placeholder="修改登录密码">
            <template slot="prefix">
              <img src="@/assets/img/u14.svg" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword" v-if="loginType == 3" :rules="[{ required: true, message: '请输入确认登录密码', trigger: 'change' },
        { validator: confirmPassword }]">
          <el-input type="text" v-model.trim="loginInfo.confirmPassword" placeholder="确认登录密码">
            <template slot="prefix">
              <img src="@/assets/img/u14.svg" />
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="loginIn" class="w-100">登录</el-button>
    </div>
    <div class="footer">
      <a>建议您使用IE9+、FireFox、Google Chrome、分辨率1366*768及以上浏览网站，获取更好的用户体验</a>
      <br />
      <a href="https://beian.miit.gov.cn">闽ICP备19019241号</a>
    </div>
  </div>
</template>

<script>
import setRouterByMenu from '@/main'
const Base64 = require("js-base64").Base64
export default {
  name: 'login',
  data() {
    return {
      loginInfo: {
        username: ' ',
        password: '',
        checked: false,
      },
      showPassword: false,
      loginType: 1,
      codeName: '获取验证码',
      loading: false,
      setSystemConfig: {},
      fullscreenLoading: false,
    }
  },
  mounted() {
    this.getCookie()
    this.getImageInfo()
  },
  methods: {
    confirmPassword(rule, value, callback) {
      var result = (value + '').match(/^[a-zA-Z](?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&+=]).{7,17}$/);
      if (result == null) {
        callback(new Error('以字母开头的8-18位字母、数字和特殊字符组成'))
      } else if (this.loginInfo.password && this.loginInfo.confirmPassword && this.loginInfo.password != this.loginInfo.confirmPassword) {
        callback(new Error('修改密码与确认密码不一致'))
      } else {
        callback()
      }
    },
    async getImageInfo() {
      let res = await this.$api.getSystemConfig({ entity: {} })
      this.setSystemConfig = {
        systemName: res.systemName || "",
        organizationName: res.organizationName || "",
        systemLogoFileUrl: res.systemLogoFileUrl,
        loginBackgroundFileUrl: res.loginBackgroundFileUrl,
        loginBackgroundLogoFileUrl: res.loginBackgroundLogoFileUrl,
        iconFileUrl: res.iconFileUrl
      }
      this.$store.commit('setSystemConfig', this.setSystemConfig)
      if (this.setSystemConfig.iconFileUrl) {
        this.resetLink(this.setSystemConfig.iconFileUrl)
      } else {
        this.resetLink("/file/get/a938d278e3dd4bd520240608003027")
      }
      if (this.setSystemConfig.systemName) {
        this.resetTitle(this.setSystemConfig.systemName)
      } else {
        // this.resetTitle("地质灾害预警管理系统")
        this.resetTitle("监测预警管理平台")
      }
    },
    resetTitle(systemName) {
      const title = document.querySelector('title[rel~="title"]');
      if (title) {
        title.innerHTML = systemName;
      } else {
        const newTitle = document.createElement('title');
        newTitle.rel = 'title';
        newTitle.href = systemName;
        document.getElementsByTagName('head')[0].appendChild(newTitle);
      }
    },
    resetLink(iconFileUrl) {
      const link = document.querySelector('link[rel~="icon"]');
      if (link) {
        link.href = this.$FileBaseURL + iconFileUrl;
      } else {
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = this.$FileBaseURL + iconFileUrl;
        document.getElementsByTagName('head')[0].appendChild(newLink);
      }
    },
    confirmRead(rule, value, callback) {
      if (!value) {
        callback(new Error('用户服务与隐私协议未勾选'))
      } else {
        callback()
      }
    },
    setCookie(userId, days) {
      let date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days);
      window.document.cookie =
          "userId" + "=" + Base64.encode(userId) + ";path=/;expires=" + date.toGMTString();
    },
    getCookie() {
      if (document.cookie.length > 0) {
        let arr = document.cookie.split("; ");
        for (let i = 0; i < arr.length; i++) {
          let arr2 = arr[i].split("=");
          if (arr2[0] === "userId") {
            this.loginInfo.username = Base64.decode(arr2[1]);
          } else if (arr2[0] === "word") {
            this.loginInfo.password = Base64.decode(arr2[1]);
          }
        }
      }
    },
    getCode() {
      if (this.loading) return

      this.codeName = 30
      let timer = setInterval(() => {
        if (this.codeName === 0) {

          this.codeName = '获取验证码'
          clearTimeout(timer)
        } else {
          this.codeName -= 1
        }
      }, 1000)
    },
    async mCode() {
      let dvCode = await this.$api.dictionaryQuery({ entity: {} })
      sessionStorage.setItem('dvCode', JSON.stringify(dvCode))
    },
    loginIn() {
      this.fullscreenLoading = true;
      this.$refs.formName.validate(valid => {
        if (valid) {
          let params = {
            entity: {
              ...this.loginInfo
            }
          };
          this.$api.loginApi(params, '')
              .then(res => {
                if (!res) return;
                this.$store.commit('setAccessToken', res.token_type + ' ' + res.access_token);
                return this.$api.loginUserInfo({ entity: {} });
              })
              .then(res => {
                if (!res) return;
                this.$store.commit('setUserInfo', JSON.stringify(res));
                this.setCookie(this.loginInfo.username, 1);
                this.mCode();
                let subMenus = res.bindRoleInfos[0].menuInfos || [];
                if (!subMenus.length) {
                  this.$message.error('该角色未配置菜单，请联系管理员');
                  return;
                }
                let routers = [];
                this.setTreeName(subMenus, routers);
                setRouterByMenu(routers);
                sessionStorage.setItem('menulist', JSON.stringify(routers));
              })
              .catch(error => {
                console.error('Login failed:', error);
                this.$message.error('登录失败，请检查网络或联系管理员');
              })
              .finally(() => {
                this.fullscreenLoading = false;
              });
        } else {
          this.fullscreenLoading = false;
        }
      });
    },
    setTreeName(treeData, menulist) {
      for (let item of treeData) {
        item.name = item.path
        delete item.path
        menulist.push(JSON.parse(JSON.stringify(item)))
        delete menulist[menulist.length - 1].subMenus
        if (item.subMenus && Array.isArray(item.subMenus) && item.subMenus.length) {
          menulist[menulist.length - 1].children = []
          this.setTreeName(item.subMenus, menulist[menulist.length - 1].children)
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  height: 100vh;
  color: #fff;
  font-family: 'PingFang SC ', 'PingFang SC', sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 40px 30px;

  .img-1 {
    height: 65px;
  }

  .title-2 {
    position: absolute;
    left: 200px;
    top: 45%;
    transform: translateY(-50%);
    width: 480px;
  }

  .sub-title {
    font-size: 20px;
    margin-top: 20px;
    display: block;
  }
}

.login-bg-img{
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.login-wrap {
  border-width: 0px;
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  padding: 40px 35px;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 0px;
  box-shadow: none;
  flex-direction: column;
  width: 280px;
  border-radius: 12px;

  >img {
    width: 60px;
  }

  .login-title {
    font-style: normal;
    font-size: 20px;
    display: block;
    margin: 0 0 30px 0;
    transform: translateY(-5px);
    font-family: Alibaba PuHuiTi;
    font-weight: 700;
    color: #080808;
  }
}

::v-deep .el-input__prefix {
  margin-left: 5px;
  transform: translateY(3px);

  img {
    width: 18px;
  }
}

.el-input {
  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid $border;
  }
}

::v-deep .el-input--prefix .el-input__inner {
  padding-left: 45px;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: 45px;
}

.flex-row {
  margin: 20px 0 30px 0;

  span {
    color: $primary;
    font-size: 12px;
    cursor: pointer;
  }
}

.el-form-item {
  margin-bottom: 15px;
}

em {
  color: $primary;
}

.control {
  line-height: 40px;
  font-size: 12px;
}

.w-100 {
  margin: 16px 0 12px 0;
}

.footer {
  font-family: Alibaba PuHuiTi;
  line-height:normal;
  font-size: 14px;
  width: 100%;
  text-align: center;
  //background: #fff;
  padding: 10px 0;
  color:#ffffff;
  position: relative;
}
</style>
