export default {
  path: '/device',
  name: 'device',
  component: 'router-view.vue',
  redirect: {name:'product_manage_list'},
  meta: {
    isMenu: true,
    title: "设备管理",
  },
  children: [
    {
      path: 'asset-manage',
      name: 'asset_manage',
      component: 'router-view.vue',
      redirect: {name:'product_manage_list'},
      meta: {
        isMenu: true,
        redirect:true,
        title: "设备资产管理",
      },
      children: [
        {
          path: 'product-manage',
          name: 'product_manage',
          component: 'router-view.vue',
          redirect: {name:'product_manage_list'},
          meta: {
            isMenu: true,
            redirect:true,
            title: "产品管理",
          },
          children: [
            {
              path: 'list',
              name: 'product_manage_list',
              component: 'device/product/index.vue',
              meta: {
                isMenu: false,
              },
            },
            {
              path: 'form',
              name: 'product_manage_form',
              component: 'device/product/form.vue',
              meta: {
                isMenu: false,
                title: "产品详情",
              },
            }
          ]
        },
        {
          path: 'device-manage',
          name: 'device_manage',
          component: 'router-view.vue',
          redirect: {name:'device_manage_list'},
          meta: {
            isMenu: true,
            title: "设备管理",
          },
          children: [
            {
              path: 'list',
              name: 'device_manage_list',
              component: 'device/device/index.vue',
              meta: {
                isMenu: false,
                title: "列表",
              },
            },
            {
              path: 'detail',
              name: 'device_manage_detail',
              component: 'device/device/form.vue',
              meta: {
                isMenu: false,
                title: "设备管理详情",
              },
            },
            {
              path: 'detailMul',
              name: 'deviceMul_manage_detail',
              component: 'device/device/formMul.vue',
              meta: {
                isMenu: false,
                title: "设备管理详情",
              },
            },
          ]
        },
        {
          path: 'composite-device',
          name: 'composite_device',
          component: 'device/CompositeDevice/index.vue',
          meta: {
            isMenu: true,
            title: "组合设备管理",
          },
        },
      ]
    },
    {
      path: 'device-data',
      name: 'device_data',
      component: 'deviceData/index.vue',
      meta: {
        isMenu: true,
        title: "设备数据",
      },
    },
    {
      path: 'DevOps',
      name: 'DevOps',
      component: 'router-view.vue',
      meta: {
        isMenu: true,
        redirect:true,
        title: "设备运维",
      },
      children: [
        {
          path: 'DevOps-error-monitor',
          name: 'DevOps_error_monitor',
          component: 'router-view.vue',
          redirect: {name:'DevOps_monitor_pending'},
          meta: {
            isMenu: true,
            redirect:true,
            title: "设备异常监控"
          },
          children: [
            {
              path: 'pending',
              name: 'DevOps_monitor_pending',
              component: 'DevOps/pending.vue',
              meta: {
                isMenu: false,
                title: "待处理",
              },
            },
            {
              path: 'processing',
              name: 'DevOps_monitor_processing',
              component: 'DevOps/processing.vue',
              meta: {
                isMenu: false,
                title: "处理中",
              },
            },
            {
              path: 'processed',
              name: 'DevOps_monitor_processed',
              component: 'DevOps/processed.vue',
              meta: {
                isMenu: false,
                title: "已处理",
              },
            },
          ]
        },
        // {
        //   path: 'DevOps-error-solve',
        //   name: 'DevOps_error_solve',
        //   component:'DevOps/errorSolve.vue',
        //   meta: {
        //     isMenu: true,
        //     title:"设备异常处理"
        //   },
        // },
      ]
    },
  ]
}