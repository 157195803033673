const state = {
    access_token: sessionStorage.getItem('access_token')||false,
    searchable: {},
    dlShow: false,
    dlSubmitLoading: false,
    userInfo:sessionStorage.getItem('userInfo')||{},
    configObj: {
        // title: "提示",
        // slotName: "删除",
        // content: "确认删除该用户吗？",
        // tips: "此操作将不可逆，请您谨慎操作",
        // width:"30%",
        // disable:false
    },
    systemConfig:sessionStorage.getItem('systemConfig')?JSON.parse(sessionStorage.getItem('systemConfig')):{},
    dlFormObj:{},
    isHome:sessionStorage.getItem('isHome')||true,
    pageSize:50,

}
export default state