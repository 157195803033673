<template>
    <el-form class="app-search-form" :class="{ 'flex-c': center }" ref="form" :label-position="position"
        :model="formData" label-width="auto">
        <el-divider v-if="pageName" content-position="center">{{ pageName }}</el-divider>
        <el-row :gutter="24" class="flex-start-row" :style="{ width: formWidth }">
            <el-col :span="['title'].includes(configItem.type) ? 24 : (configItem.span || 8)"
                v-for="(configItem, i) in baseForm" :key="i"
                v-if="!configItem.showByView || (configItem.showByView && !isEdit)">
                <!-- -- title -- -->
                <el-form-item v-if="configItem.type === 'title'" class="app-search-item" label-width="0px">
                    <jTitle :title="configItem.title" />
                </el-form-item>
                <!-- -- input -- -->
                <el-form-item v-else-if="['input', 'password'].includes(configItem.type)" class="app-search-item"
                    :label="configItem.label" :prop="configItem.prop"
                    :rules="isEdit ? (configItem.valiFun ? setRules(configItem.rules, configItem.valiFun, configItem.valiprop) : configItem.rules) || null : null">
                    <el-input v-if="!configItem.prop1" :disabled="configItem.disabled || !isEdit" clearable
                        style="width:100%" v-model.trim="formData[configItem.prop]"
                        :placeholder="configItem.placeholder"
                        :maxlength="configItem.maxlength ? configItem.maxlength : 100000"
                        :show-password="configItem.type === 'password'" :show-word-limit="configItem.maxlength > 0" />
                    <el-input v-else disabled style="width:100%"
                        :value="formData[configItem.prop1] ? [formData[configItem.prop1] || '', formData[configItem.prop] || ''].join('_') : formData[configItem.prop]"
                        :placeholder="configItem.placeholder" />
                </el-form-item>
                <!-- -- inputs -- -->
                <div v-else-if="configItem.type === 'inputs'">
                    <el-form-item class="app-search-item selects" :label="configItem.label" :prop="configItem.prop"
                        :rules="isEdit ? configItem.rules || null : null">
                        <div class="flex-start-row">
                            <el-input v-for="(cItem, idx) in configItem.list" :key="idx" clearable v-model="formData[cItem.prop]"
                                style="margin-right: 5px;" :disabled="cItem.disabled || !isEdit"
                                :placeholder="cItem.placeholder">
                            </el-input>
                        </div>
                    </el-form-item>
                </div>
                <!-- -- textarea -- -->
                <el-form-item v-else-if="configItem.type === 'textarea'" class="app-search-item"
                    :label="configItem.label" :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <el-input :disabled="configItem.disabled || !isEdit" type="textarea" style="width:100%"
                        v-model="formData[configItem.prop]" :placeholder="configItem.placeholder"
                        :maxlength="configItem.maxlength ? configItem.maxlength : 100000"
                        :show-word-limit="configItem.maxlength > 0" />
                </el-form-item>
                <!-- -- number -- -->
                <el-form-item v-else-if="configItem.type === 'number'" class="app-search-item" :label="configItem.label"
                    :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <el-input-number :disabled="configItem.disabled || !isEdit" :max="99999"
                        v-model="formData[configItem.prop]" :controls="false" :min="0" step-strictly
                        :step="1"></el-input-number>
                </el-form-item>
                <!-- -- radio -- -->
                <el-form-item v-else-if="configItem.type === 'radio'" class="app-search-item" :label="configItem.label"
                    :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <template>
                        <el-radio-group v-model="formData[configItem.prop]" size="medium">
                            <el-radio v-for="(item, i) in baseOption[configItem.prop]"
                                :disabled="configItem.disabled || !isEdit" border :key="i" :label="item.value">{{
        item.label }}</el-radio>
                        </el-radio-group>
                    </template>
                </el-form-item>
                <!-- -- checkbox -- -->
                <el-form-item v-else-if="configItem.type === 'checkbox'" class="app-search-item"
                    :label="configItem.label" :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <template>
                        <el-checkbox-group v-model="formData[configItem.prop]">
                            <el-checkbox v-for="(item, i) in baseOption[configItem.prop]"
                                :disabled="configItem.disabled || !isEdit" border :key="i" :label="item.value">{{
        item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </template>
                </el-form-item>
                <!-- -- tree -- -->
                <el-form-item v-else-if="configItem.type === 'button'" class="app-search-item" :label="configItem.label"
                    :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <template>
                        <el-button class="button-origin" :disabled="configItem.disabled || !isEdit" @click="selectOrgId"
                            style="width:100%;text-align:left">{{
        formData.placeholder }}</el-button>
                    </template>
                </el-form-item>
                <!-- -- tree -- -->
                <el-form-item v-else-if="configItem.type === 'tree'" class="app-search-item" :label="configItem.label"
                    :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <template>
                        <jdTree treeType="id" v-model="formData[configItem.prop]"
                            :disabled="configItem.disabled || !isEdit"
                            :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }" />
                    </template>
                </el-form-item>
                <!-- -- tree -- -->
                <el-form-item v-else-if="configItem.type === 'jSelectTree'" class="app-search-item"
                    :label="configItem.label" :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <template>
                        <jSelectTree :dataList="baseOption[configItem.prop]" v-model="formData[configItem.prop]"
                            :disabled="configItem.disabled || !isEdit"
                            :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }" />
                    </template>
                </el-form-item>
                <!-- -- tree -- -->
                <el-form-item v-else-if="configItem.type === 'jMenuTree'" class="app-search-item"
                    :label="configItem.label" :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <template>
                        <jMenuTree :dataList="baseOption[configItem.prop]" v-model="formData[configItem.prop]"
                            :disabled="configItem.disabled || !isEdit"
                            :defaultProps="{ children: 'subMenus', label: 'name', value: 'id' }" />
                    </template>
                </el-form-item>
                <!-- -- select -- -->
                <el-form-item v-else-if="configItem.type === 'select'" class="app-search-item" :label="configItem.label"
                    :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <el-select clearable v-model="formData[configItem.prop]" @change="eventFun($event, configItem)"
                        :multiple="Array.isArray(formData[configItem.prop]) || false"
                        :disabled="configItem.disabled || !isEdit" :placeholder="configItem.placeholder"
                        :filterable="configItem.filterable || true">
                        <el-option v-for="item in (configItem.options || baseOption[configItem.prop])" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- selects -->
                <div v-else-if="configItem.type === 'selects'">
                    <el-form-item v-for="(item, i) in formData[configItem.prop]" :key="i"
                        class="app-search-item selects" :label="configItem.label" :prop="configItem.prop + '.' + i"
                        :rules="isEdit ? configItem.rules || null : null">
                        <div class="flex-start-row">
                            <el-select v-for="(cItem, idx) in configItem.list" :key="idx" clearable v-model="formData[cItem.prop][i]"
                                @change="cItem.change ? eventFun($event, cItem) : null" style="margin-right: 5px;"
                                :multiple="Array.isArray(formData[cItem.prop][i]) || false"
                                :disabled="cItem.disabled || !isEdit" :placeholder="cItem.placeholder"
                                :filterable="cItem.filterable || true">
                                <el-option v-for="item in (configItem.options || baseOption[cItem.prop])"
                                    :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                            <span v-if="isEdit" class="el-icon-circle-plus-outline" @click="addList(configItem)"></span>
                            <span v-if="isEdit && i != 0" class="el-icon-remove-outline"
                                @click="delList(configItem, i)"></span>
                        </div>
                    </el-form-item>
                </div>
                <div v-else-if="configItem.type === 'area'">
                    <el-form-item class="app-search-item" :label="configItem.label" label-width="80px"
                        prop="provinceCode,cityCode,countyCode"
                        :rules="isEdit ? [{ required: configItem.required, validator: validArea, trigger: 'change' }] || null : null">
                        <div class="flex-row">
                            <el-select filterable v-model="formData.provinceCode" placeholder="省份"
                            :disabled="configItem.disabled || !isEdit" @change="($event) => {
        getSubOption($event, 'cityCode')
        formData.provinceName = setLabelByName($event, baseOption.provinceCode)
    }">
                                <el-option v-for="item in baseOption.provinceCode" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <el-select filterable class="mg-5" v-model="formData.cityCode" placeholder="市"
                                :disabled="configItem.disabled || !isEdit" @change="($event) => {
        getSubOption($event, 'countyCode')
        formData.cityName = setLabelByName($event, baseOption.cityCode)
    }">
                                <el-option v-for="item in baseOption.cityCode" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <el-select filterable v-model="formData.countyCode" placeholder="区"
                                :disabled="configItem.disabled || !isEdit"
                                @change="formData.countyName = setLabelByName($event, baseOption.countyCode)">
                                <el-option v-for="item in baseOption.countyCode" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </el-form-item>
                </div>
                <!-- -- time date-- -->
                <el-form-item v-else-if="(configItem.type === 'time' || configItem.type === 'date')"
                    class="app-search-item" :label="configItem.label" :prop="configItem.prop"
                    :rules="isEdit ? configItem.rules || null : null">
                    <el-date-picker v-model="formData[configItem.prop]" :disabled="configItem.disabled || !isEdit"
                        :type="configItem.type === 'time' ? 'datetimerange' : 'daterange'" range-separator="-"
                        start-placeholder="开始日期" end-placeholder="结束日期"
                        :value-format="configItem.type == 'time' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
                        :format="configItem.type == 'time' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'">
                    </el-date-picker>
                </el-form-item>
                <!-- -- time date-- -->
                <el-form-item v-else-if="(configItem.type === 'oneDate' || configItem.type === 'oneTime')"
                    class="app-search-item" :label="configItem.label" :prop="configItem.prop"
                    :rules="isEdit ? configItem.rules || null : null">
                    <el-date-picker v-model="formData[configItem.prop]" :disabled="configItem.disabled || !isEdit"
                        :type="configItem.type === 'oneTime' ? 'time' : 'date'"
                        :placeholder="configItem.type === 'oneTime' ? '选择时间' : '选择日期'"
                        :value-format="configItem.type == 'oneTime' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
                        :format="configItem.type == 'oneTime' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'">
                    </el-date-picker>
                </el-form-item>
                <!-- -- image-- -->
                <el-form-item v-else-if="configItem.type === 'image'" class="app-search-item" :label="configItem.label"
                    :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <jUploadImg v-model="formData[configItem.prop]" v-if="!loading" :width="configItem.width"
                        :height="configItem.height" :disabled="configItem.disabled || !isEdit"
                        :maxSize="configItem.maxSize" :tip="configItem.tip" :tips="configItem.tips"
                        :maxNum="configItem.maxNum">
                    </jUploadImg>
                </el-form-item>
                <el-form-item v-else-if="configItem.type === 'image2'" class="app-search-item" :label="configItem.label"
                    :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <jUploadImg2 v-model="formData[configItem.prop]" v-if="!loading" :width="configItem.width"
                        :height="configItem.height" :disabled="configItem.disabled || !isEdit"
                        :fileType="configItem.fileType" :maxSize="configItem.maxSize" :tip="configItem.tip"
                        :tips="configItem.tips" :maxNum="configItem.maxNum">
                    </jUploadImg2>
                </el-form-item>
                <!-- -- file-- -->
                <el-form-item v-else-if="configItem.type === 'file'" class="app-search-item" :label="configItem.label"
                    :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null">
                    <jUploadFile v-model="formData[configItem.prop]" v-if="!loading"
                        :disabled="configItem.disabled || !isEdit" :maxSize="configItem.maxSize"
                        :fileType="configItem.fileType" :tip="configItem.tip" :maxNum="configItem.maxNum">
                    </jUploadFile>
                </el-form-item>
            </el-col>
        </el-row>
        <div class="j-form-bottom" v-if="showControl">
            <el-button size="mini" @click="cancle">取消</el-button>
            <el-button type="primary" v-if="isEdit" size="mini" @click="saveFun">保存</el-button>
        </div>
    </el-form>
</template>
<script>
export default {
    name: "jForm",
    props: {
        jForm: {
            type: Object,
            default: () => {
                return {}
            }
        },
        isShow: {
            type: Boolean,
            default: true
        },
        onSubmit: {
            type: Boolean,
            default: true
        },
        baseForm: {
            type: Array,
            default: () => {
                return [
                    // { label: '', prop: '', type: '',default:'[]|{}', required: false,disabled:false, placeholder:'',
                    // [{ required: true, message: '请输入用户编号/用户名/手机号', trigger: 'change' },
                    // { validator: $global.isEmpty }]}
                ]
            }
        },
        pageName: {
            type: String,
            default: ""
        },
        isEdit: {
            type: Boolean,
            default: true
        },
        showControl: {
            type: Boolean,
            default: true
        },
        labelWidth: {
            type: String,
            default: "100px"
        },
        position: {
            type: String,
            default: "right"
        },
        formWidth: {
            type: String,
            default: "100%"
        },
        center: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            formData: {

            },
            baseOption: {

            },
            filterText: "",
            loading: false,
            showList: {

            }
        }
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
        isShow: {
            handler(val) {
                if (val) {
                    this.init()
                } else {
                    this.formData = {}
                }
            },
            immediate: true,
        },
        deep: true
    },
    created() {
        // this.init()
    },
    methods: {
        init() {
            this.getOption()
        },
        validArea(rule, value, callback) { //验证用户名或昵称经常用到: 只能中英文，数字，下划线，减号
            if (!this.formData.provinceCode) {
                callback(new Error('请选择省份'))
            } else if (!this.formData.cityCode) {
                callback(new Error('请选择城市'))
            } else if (!this.formData.countyCode) {
                callback(new Error('请选择区'))
            } else {
                callback()
            }
        },
        getSubOption(parentId, key) {
            this.$api.regionSubList({ entity: { id: parentId } }).then(res => {
                if (res) {
                    this.$set(this.baseOption, key, res)
                    // this.$set(this.form, key, '')
                }
            })
        },
        setLabelByName(value, list, label) {
            let labels = ""
            if (Array.isArray(value) && value.length) {
                labels = (list || []).filter(item => value.includes(item.id)).map(item => item.name).join(',')
                return labels
            } else {
                labels = (list || []).find(item => item.id == value) || {}
                return labels.name || ''
            }
        },
        setRules(rules, setRules, prop) {
            if (Array.isArray(rules)) {
                rules[0].validator = (rule, value, callback) => {
                    this.$global[setRules](rule, value, callback, this.formData[prop])
                }
            } else {
                rules.validator = (rule, value, callback) => {
                    this.$global[setRules](rule, value, callback, this.formData[prop])
                }
            }
            return rules
        },
        async getOption() {
            for (let item of this.baseForm) {
                let prop = item.prop
                if (item.list) {
                    prop = "list"
                }
                if (['select', 'radio', 'checkbox', 'jMenuTree', 'jSelectTree', 'selects', 'area'].includes(item.type)) {
                    switch (prop) {
                        case 'sex':
                            this.$set(this.baseOption, prop, [
                                { label: '男', value: 'MALE' },
                                { label: '女', value: 'FEMALE' },
                            ])
                            break;
                        case 'isSendMessage':
                          this.$set(this.baseOption, prop, [
                            { label: "是", value: true },
                            { label: "否", value: false },
                          ])
                          break;
                        case 'isSync':
                            this.$set(this.baseOption, prop, [
                                { label: "是", value: true },
                                { label: "否", value: false },
                            ])
                            break;
                        case 'orgNum':
                            this.baseOption[prop] = [await this.$api.orgTreeQuery({ entity: {} })]
                            break;
                        case 'monitorType':
                            this.baseOption[prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.MONITOR_TYPE.map(item => {
                                return { label: item.codeDesc, value: item.codeValue }
                            })
                            break;
                        case 'menuInfos':
                            let treeData = await this.$api.treeQueryAll({ entity: {} })
                            this.baseOption[prop] = treeData[0].subMenus
                            break;
                        case 'productId':
                            let productId = await this.$api.queryProductList({ entity: { productState: "1" } }) || []
                            this.baseOption[prop] = productId.map(item => {
                                return { label: item.name, value: item.id * 1, ...item }
                            })
                            break;
                        case 'deviceTypeValue':
                            this.baseOption[prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_TYPE.map(item => {
                                return { label: item.codeDesc, value: item.codeValue }
                            })
                            break;
                        case 'manufacturerId':
                            let manufacturerId = await this.$api.manufacturerQueryPageall({ entity: {} })
                            this.baseOption[prop] = manufacturerId.map(item => {
                                return { label: item.name, value: item.id }
                            })
                            break;
                        case 'deviceState':
                            this.baseOption[prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_STATE.map(item => {
                                return { label: item.codeDesc, value: item.codeValue }
                            })
                            break;
                        case 'warningStatus':
                            this.baseOption[prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
                                return { label: item.codeDesc, value: item.codeValue }
                            })
                            break;
                        case 'fatherDeviceCode':
                            let fatherDeviceCode = await this.$api.gatewayDeviceList({
                                entity: {},
                            })
                            this.baseOption[prop] = (fatherDeviceCode || []).map(item => {
                                return { label: item.deviceCode, value: item.deviceCode }
                            })
                            break;
                        case 'monitorProjectId':
                            // let monitorProjectId = await this.$api.projectQueryPage({
                            //     entity: {},
                            //     pageSize: 500,
                            //     pageNum: 1,
                            // })
                            // let monitorProject = monitorProjectId.resultList.map(item => {
                            //     return { label: item.name, value: item.id }
                            // })
                          let monitorProjectId = await this.$api.projectQueryOptions2({entity: {} })
                          let monitorProject = monitorProjectId.map(item => {
                            return { label: item.name, value: item.id }
                          })
                            this.baseOption.monitorProjectId = monitorProject
                            break;
                        case 'admin1UserId':
                          let emergencyContactUserId = await this.$api.postQueryOrgUserAll({
                            entity: {
                              orgNum: this.$route.query.orgNum
                            },
                          })
                          let emergencyContactUserList = emergencyContactUserId.map(item => {
                            return { label: item.username, value: item.id }
                          })
                          console.log('emergencyContactUserList:', emergencyContactUserList)
                          this.baseOption.admin1UserId = emergencyContactUserList
                          this.baseOption.admin2UserId = emergencyContactUserList
                          this.baseOption.admin3UserId = emergencyContactUserList
                          break;
                        case 'admin2UserId':
                          break;
                        case 'admin3UserId':
                          break;
                        case 'provinceCode':
                            let provinceCode = await this.$api.provinceList({ entity: {} })
                            if (provinceCode) {
                                this.$set(this.baseOption, 'provinceCode', provinceCode)
                            }
                            if (this.jForm && this.jForm.provinceCode) {
                                let cityCode = await this.$api.regionSubList({ entity: { id: this.jForm.provinceCode } })
                                cityCode && this.$set(this.baseOption, 'cityCode', cityCode)
                            }
                            if (this.jForm && this.jForm.cityCode) {
                                let countyCode = await this.$api.regionSubList({ entity: { id: this.jForm.cityCode } })
                                countyCode && this.$set(this.baseOption, 'countyCode', countyCode)
                            }
                            break
                        case 'bindRoleInfos':
                            let params = {
                                entity: {},
                                pageSize: 10000,
                                pageNum: 1
                            }
                            let res = await this.$api.roleQueryPage(params)
                            if (res) {
                                let bindRoleInfosOption = res.resultList.map(item => {
                                    item.label = item.name
                                    item.value = item.id
                                    return item
                                })
                                this.baseOption[prop] = bindRoleInfosOption.filter(item => item.isEnable)
                            }
                            break;
                        case 'list':
                            let props = item.list.map(item => item.prop)
                            if (props.includes('monitorId')) {
                                let monitorInfo = await this.$api.monitorInfoQuerypage({
                                    entity: {},
                                    pageSize: 10000,
                                    pageNum: 1,
                                })
                                if (monitorInfo) {
                                    let tableData = monitorInfo.resultList.map(item => {
                                        if (this.formData && (this.formData.monitorId || []).includes(item.id)) {
                                            if (item.ministerialPlatformSyncTime) {
                                                item.ministerialPlatformSyncTime = new Date(new Date(item.ministerialPlatformSyncTime).getTime()).toLocaleString()
                                            } else {
                                                item.ministerialPlatformSyncTime = '-'
                                            }
                                            item.disabled = true
                                        }
                                        return { label: item.monitorPointName, value: item.id, codeNum: item.monitorPointNum, ...item }
                                    })
                                    this.baseOption.monitorId = tableData
                                }
                            }
                            if (props.includes('monitorProjectId')) {
                                let monitorProjectId = await this.$api.projectQueryPage({
                                    entity: {},
                                    pageSize: 500,
                                    pageNum: 1,
                                })
                                let monitorProject = monitorProjectId.resultList.map(item => {
                                    return { label: item.name, value: item.id }
                                })
                                this.baseOption.monitorProjectId = monitorProject
                            }
                            break;
                        default:
                            this.baseOption[prop] = []
                            break;
                    }
                }
            }
            console.log('baseOptions:', this.baseOption)
            let isArrays = []
            for (let item of this.baseForm) {
                if (!item.list) {
                    if (item.prop) {
                        if (Array.isArray(item.default)) {
                            isArrays.push(item.prop)
                        }
                        this.$set(this.formData, item.prop, item.default || "")
                    }
                } else {
                    for (let cItem of item.list) {
                        this.$set(this.formData, cItem.prop, cItem.default || "")
                    }
                }

            }
            if (Object.keys(this.jForm)) {
                this.formData = Object.assign(this.formData, this.jForm || {})
                for (let key of Object.keys(this.formData)) {
                    if (isArrays.includes(key) && !Array.isArray(this.formData[key])) {
                        this.formData[key] = this.formData[key] ? [this.formData[key]] : []
                    }
                }
            }
            document.addEventListener('keyup', this.setKeyUp);
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        setKeyUp(event) {
            if (event.key == "Enter") {
                this.saveFun()
            } // 打印按键名称
        },
        eventFun(value, configItem, idx) {
            if (Array.isArray(this.formData[configItem.prop])) {
                for (let key of configItem.change) {
                    this.formData[key] = []
                }
                let i = 0
                for (let item of this.formData[configItem.prop]) {
                    let obj = this.baseOption[configItem.prop].find(cItem => cItem.value == item)
                    for (let key of configItem.change) {
                        this.formData[key].push(obj[key])
                    }
                    if (i == 0) {
                        for (let key of configItem.setFirst) {
                            this.formData[key] = obj[key]
                        }
                    }
                    i++
                }
            } else {
                let obj = this.baseOption[configItem.prop].find(item => item.value == value)
                for (let key of configItem.change) {
                    this.formData[key] = obj[key]
                }
            }
        },
        addList(configItem) {
            this.formData[configItem.prop].push('')
            for (let item of configItem.list) {
                if (item.change) {
                    for (let key of item.change) {
                        this.formData[key].push('')
                    }
                }
            }
        },
        delList(configItem, idx) {
            this.formData[configItem.prop].splice(idx, 1)
            for (let item of configItem.list) {
                if (item.change) {
                    for (let key of item.change) {
                        this.formData[key].splice(idx, 1)
                    }
                }
            }
        },
        saveFun() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$emit('save', this.formData)
                }
            })
        },
        cancle() {
            if (this.$route.query.history) {
                this.$router.go(this.$route.query.history)
            } else {
                this.$router.go(-1)
            }

        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        selectOrgId() {
            this.$store.commit('setDlShow', true)
            this.$store.commit('setConfigObj', { name: '选择所属机构', disabled: true })
        },
    },
    beforeDestroy() {
        this.onSubmit && this.$bus.$off('dlSubmit')
        document.removeEventListener('keyup', this.setKeyUp);
    },
}
</script>
<style scoped lang="scss">
.el-input,
.el-button {
    max-width: 350px;
}

::v-deep .button-origin span {
    color: $border;
}

::v-deep .el-form-item__label {
    line-height: 40px;
}

::v-deep .el-input-number,
::v-deep .el-input,
::v-deep .el-select {
    width: 100%;
    max-width: 350px;
}

::v-deep .el-divider__text {
    font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
    font-style: normal;
    font-size: 24px;
    letter-spacing: 3px;
}


::v-deep .el-input .el-input__inner,
::v-deep .el-input,
::v-deep .el-range-editor.el-input__inner {
    max-width: 220px;
}

.j-form-bottom {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}

::v-deep .el-form-item__content {
    max-width: unset
}

.selects ::v-deep .el-select {
    width: auto;
}

.selects ::v-deep .el-input {
    max-width: 150px;
}

.el-icon-circle-plus-outline {
    cursor: pointer;
    margin-left: 10px;
    font-size: 18px;
    line-height: 32px;
}

.el-icon-remove-outline {
    cursor: pointer;
    margin-left: 5px;
    font-size: 18px;
    line-height: 32px;
}

.flex-c {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>