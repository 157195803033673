import { post } from "@/request/http";

export default {
    projectAdd(param, msg) { //add
        return post("/device/project/add", param, msg)
    },
    projectDel(param, msg) { //delete
        return post("/device/project/delete", param, msg)
    },
    projectUpdate(param, msg) { //update
        return post("/device/project/update", param, msg)
    },
    projectQueryPage(param, msg) { //queryPage
        // return post("/device/project/queryPage", param, msg)
        return post("/device/project/query/page/v2", param, msg)
    },
    projectQueryOptions2(param, msg) { //queryPage
        return post("/device/project/query/option", param, msg)
    },
    projectQueryOptions(param, msg) { //queryPage
        return post("/device/monitor/query-project", param, msg)
    },
    projectQueryById(param, msg) { //queryById
        return post("/device/project/queryById", param, msg)
    },
    projectUpload(param, msg) { //upload
        return post("/device/project/upload-project-info", param, msg)
    },
    projectDownload(param, msg) { //download
        return post("/device/project/download-project-infos", param, msg)
    },
    //监测点信息
    monitorInfoAdd(param, msg) { //新增监测点信息
        return post("/device/monitor/add", param, msg)
    },
    monitorInfoUpdate(param, msg) { //修改监测点信息
        return post("/device/monitor/update", param, msg)
    },
    monitorInfoDelete(param, msg) { //删除监测点信息
        return post("/device/monitor/delete", param, msg)
    },
    monitorInfoQueryById(param, msg) { //通过ID查询监测点信息详情
        return post("/device/monitor/query/id", param, msg)
    },
    monitorInfoQuery(param, msg) { //条件查询监测点信息
        return post("/device/monitor/query", param, msg)
    },
    monitorInfoQuerypage(param, msg) { //分页条件查询监测点信息
        return post("/device/monitor/query/page", param, msg)
    },
    simpleMonitorInfoQuery(param, msg) { //查询监测点的基本信息
        return post("/device/monitor/query/simple", param, msg)
    },
    monitorInfoSearch(param, msg) { //模糊搜索
        return post("/device/monitor/search", param, msg)
    },
    bindMonitorDevice(param, msg) { //监控点绑定设备
        return post("/device/device/bindMonitorDevice", param, msg)
    },
    unbindMonitorDevice(param, msg) { //监控点解绑单个设备
        return post("/device/device/unbindMonitorDevice", param, msg)
    },
    deviceMonitorBatch(param, msg) { //批量删除
        return post("/device/monitor/delete/batch", param, msg)
    },
    deviceProjectBatch(param, msg) { //批量删除
        return post("/device/project/delete-batch", param, msg)
    },
    bindOrgMonitor(param, msg) { //批量删除
        return post("/device/monitor/bind-org", param, msg)
    },
    queryMonitorById(param, msg) { //忽略
        return post("/device/monitor/query/id", param, msg)
    },
    updateProductManufacturer(param, msg) { //修改合作厂商
        return post("/device/product/updateProductManufacturer", param, msg)
    },
    queryYldataPage(param, msg) { //分页查询所有雨量设备的数据
        return post("/device/device/query/yl-dates/page", param, msg)
    },
    queryYldataPageAll(param, msg) { //查询所有雨量设备的数据
        return post("/device/device/yl-dates/all", param, msg)
    },
    queryYldataById(param, msg) { //更新指定ID雨量设备数据
        return post("/device/device/sync/yl-dates", param, msg)
    },
    updateAllYlData(param, msg) { //更新所有雨量设备数据
        return post("/device/device/sync/yl-dates/all", param, msg)
    },
    downloadYlData(param, msg) { //更新所有雨量设备数据
        return post("/device/device/download/yl-dates", param, msg)
    },
}