<template>
  <div class="router-main" :class="{'relative':['map','cockpit'].includes($route.name)}">
    <template>
      <!-- <div v-if="!['map', 'home', 'cockpit'].includes($route.name)">
        <el-tabs class="relative" v-model="activeId" type="card" editable @tab-remove="tabRemove" @tab-click="tabClick">
          <el-tab-pane :key="index" v-for="(item, index) in editableTabs" :name="item.path">
            <template slot="label">
              {{ item.meta.title }}
            </template>
          </el-tab-pane>
        </el-tabs>
        <el-button class="other-delete" @click="removeOther" size="mini" v-if="editableTabs.length > 2"
          type="danger">一键关闭</el-button>
      </div> -->
      <div v-if="!['map', 'home', 'cockpit'].includes($route.name)">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item v-for="(item, idx) in breadcrumbList" :key="idx" :to="{ path: item.path }">
            {{ item.meta.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </template>
    <div class="main-wrap p-50">
      <jDialog />
      <router-view />
    </div>
  </div>
</template>

<script>
import jDialog from '@/components/jDialog';

export default {
  name: "childMenu",
  data() {
    return {
      activeId: "",
      editableTabs: [],
      breadcrumbList: []
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        this.breadcrumbList = to.matched.filter(item => item.meta.title)
        if (to.meta.isMenu && to.meta.title && !this.isRepeatMenu(this.editableTabs, to.path)) {
          this.editableTabs.push(to);
          this.activeId = to.path;
        } else if (!to.meta.isMenu) {
          let parent = this.findParent(to.matched)
          if (parent && parent.meta.title && !this.isRepeatMenu(this.editableTabs, parent.path)) {
            this.editableTabs.push(parent);
            this.activeId = parent.path;
          }
        }
      },
      immediate: true
    },
  },
  mounted() {
  },
  methods: {
    findParent(matched) {
      // if(!matched.length)
      let parent = matched[matched.length - 1].parent || {}
      if (parent && parent.meta && parent.meta.isMenu) {
        return parent
      } else {
        return false
      }
    },
    isRepeatMenu(editableTabs, path) {
      let paths = editableTabs.map(item => item.path);
      let idx = (paths || []).indexOf(path);
      if (idx > -1) {
        this.activeId = path;
        return true;
      }
      else {
        return false;
      }
    },
    tabRemove(path) {
      this.editableTabs = this.editableTabs.filter(item => item.path != path);
      if (this.editableTabs.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.replace({ name: this.editableTabs[0].name })
      }
    },
    removeOther() {
      let currentPage = this.editableTabs[this.editableTabs.length - 1]
      // console.log(currentPage)
      this.editableTabs = []
      this.editableTabs.push(currentPage)
      // this.$router.replace({ name: currentPage.name })
    },
    tabClick(path) {
      this.$router.push({ path: path.name });
    },
  },
  components: { jDialog }
};
</script>

<style scoped lang="scss">
.router-main {
  width: 100%;
  height: 100%;
}

.el-breadcrumb {
  margin-left: 20px;
  transform: translateY(15px);
  line-height: 25px;
}

.other-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  z-index: 10;
}

.main-wrap {
  // background-color: #fff;
  // min-width: 1300px;
}

::v-deep .el-tabs__header {
  margin: 0
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  background-color: #fff;
}
</style>