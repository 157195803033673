<template>
    <div class="aaa">
        <div v-if="type == 'title'" class="j-title" :style="styleProps">
            <span>{{ title }}</span>
        </div>
        <div v-else-if="type == 'pBtn'" class="j-btn">
            <span>{{ title }}</span>
        </div>
        <div v-else-if="type == 'btn'" class="el-btn">
            <el-button :type="btnType" :disabled="disabled" size="mini" @click="handleAdd" :loading="isLoading">{{ title }}</el-button>
        </div>
    </div>
</template>
<script>
export default {
    name: "jTitle",
    props: {
        isLoading: {
          type: Boolean,
          default: false
        },
        styleProps: {
          type: String,
        },
        title: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "title"
        },
        btnType: {
            type: String,
            default: "primary"
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        handleAdd() {
            this.$emit('click', this.title)
        }
    }
}
</script>
<style scoped lang="scss">
.j-title {
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
    position: relative;
    display: block;
    padding-left: 15px;
    font-size: 18px;
    font-weight: 500;
    margin: 25px 0;

    span {
        position: relative;
    }
}

.j-title>span::before {
    content: '';
    width: 3px;
    height: 100%;
    background: $primary;
    position: absolute;
    left: -10px;
    top: 0px;
}

.j-btn>span {
    padding: 1px 10px;
    border-radius: 10px;
    background: rgb(237 242 247);
    color: $primary;
    display: inline-block;
    font-size: 12px;
}

.el-btn {
    margin-bottom: 20px;
}
</style>