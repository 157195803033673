<template>
    <el-dialog title="选择人员" :visible.sync="show" width="80%">
        <template>
            <jSearch :baseForm="baseForm" @search="search" :span1="0" :span="24" />
            <jTable v-model="columnConfig" :tableData="tableData" :total="total" @search="search"
                @selection="selection" @selectAll="selectAll" :selection="true" controlName="" :checkList="checkList">
            </jTable>
        </template>
        <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="cancle">取 消</el-button>
            <el-button type="primary" size="mini" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    name: "jTableDialog",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        userInfos: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            baseForm: {
                name: { key: 'name', span: 6, label: "姓名", type: 'input', placeholder: "请输入" },
                telephone: { key: 'telephone', span: 6, label: "联系电话", type: 'input', placeholder: "请输入" },
                isEnable: { key: 'isEnable', span: 6, label: "用户状态", type: 'select', placeholder: "请输入" },
                idCardNum: { key: 'idCardNum', span: 6, label: "身份证号", type: 'input', placeholder: "请输入" },
            },
            columnConfig: [
                { label: "姓名", prop: "name", color: false },
                { label: "联系电话", prop: "telephone" },
                { label: "用户状态", prop: "isEnable", color: true },
                { label: "所属机构", prop: "orgName" },
            ],
            tableData: [],
            total: 50,
            show: false,
            checkList: []
        };
    },
    watch: {
        value() {
            this.show = this.value
        },
        userInfos() {
            this.checkList = this.userInfos
        },
    },
    mounted() {
        this.queryList({ pageNum: 1, pageSize: 10 })
    },
    methods: {
        cancle() {
            this.show = false
            this.$emit('input', false)
        },
        search(formData) {
            if (formData.bindRoleInfos) {
                formData.bindRoleInfos = [{ id: formData.orgNum }]
            } else {
                formData.bindRoleInfos = []
            }
            this.queryList(formData)
        },
        async queryList(formData) {
            let params = {
                entity: formData || {},
                pageSize: this.$store.state.pageSize,
                pageNum: formData.pageNum
            }
            delete params.entity.pageSize
            delete params.entity.pageNum
            let res = await this.$api.userQueryPage(params)
            if (res) {
                this.tableData = res.resultList.map(item => {
                    item.bindRoleInfos = (item.bindRoleInfos || []).map(cItem => {
                        cItem.label = cItem.name
                        return cItem.label
                    })
                    return item
                })
                this.total = res.totalNum
            }
        },
        selection(row) {
            let ids = this.checkList.map(item => item.id)
            if (ids.includes(row.id)) {
                this.checkList = this.checkList.filter(item => item.id != row.id)
            } else {
                this.checkList.push(row)
            }
        },
        selectAll(obj) {
            if (obj.check) {
                let ids = this.checkList.map(item => item.id)
                for (let item of obj.tableData) {
                    !ids.includes(item.id) && this.checkList.push(item)
                }
            } else {
                let ids = obj.tableData.map(item => item.id)
                this.checkList = this.checkList.filter(item => !ids.includes(item.id))
            }
        },
        submit() {
            this.$emit('submit', JSON.parse(JSON.stringify(this.checkList)))
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep .el-alert--info.is-light {
    background: rgba(244, 244, 245, .3);
}

.tips {
    font-family: "Microsoft YaHei", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: $error;
    line-height: 22px;
}

.el-input-number {
    position: relative;
    width: 100%;

    ::before {
        content: '人';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $border;
    }
}

.fileName {
    color: $primary;
    font-size: 12px;
    cursor: pointer;
}

.down-load {
    ::v-deep .el-form-item__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 0px !important;
    }
}
::v-deep .el-table__body-wrapper{
    overflow: auto;
    position: relative;
    max-height: 400px;
}
</style>