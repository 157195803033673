import { postUploadFile,postDownFile, postByBlob,post } from "@/request/http";

export default {
    uploadFile(param) { //文件上传
        return postUploadFile("/file/save", param, 'blob')
    },
    getFileById(param, msg) {
        return postByBlob("/file/get",param, msg) //批量导入-监测点信息
    },
    getFileByUrl() {
        return '/api/file/get' //批量导入-监测点信息
    },
    dictionaryQuery(param) { //字典树形查询
        return post("/rbac/dictionary/tree-query", param)
    },
    loginApi(param,msg) { //登录
        return post("/auth/getUserToken", param,msg)
    }, 
    loginUserInfo(param,msg){
        return post("/auth/user/login-user-info", param,msg)
    },
    treeQueryAll(param,msg){
        return post("/rbac/menu/treeQuery", param,msg)
    },
    regionName(param,msg){ //根据行政地区的编号，获取各级地区名
        return post("/rbac/region/regionName", param,msg)
    },
    regionParentInfo(param,msg){ //根据行政地区的编号，获取各级地区所有信息
        return post("/rbac/region/parent-info", param,msg)
    },
    provinceList(param,msg){ //获取省级行政地区信息
        return post("/rbac/region/provinceList", param,msg)
    },
    regionSubList(param,msg){ //获取某地区下一级行政地区信息
        return post("/rbac/region/subList", param,msg)
    },
    // regionSubList(param,msg){ //获取某地区下一级行政地区信息
    //     return post("/rbac/region/subList", param,msg)
    // },
    downloadProjectInfos(param,msg){ //获取某地区下一级行政地区信息
        return "/device/project/download-project-infos"
    },
    downloadMonitorInfoTemplate(param,msg){ //生成模块-监测点信息
        return post("/device/monitor/download-monitor-info/template",param, msg)
    },
    uploadMonitorInfoByExcel(param, msg) {
        return post("/device/monitor/upload-monitor-info",param, msg) //批量导入-监测点信息
    },
    exportMonitorInfo(param,msg){ //批量导出-监测点信息
        return post("/device/monitor/download-monitor-info",param, msg)
    },
    downloadProjectTemplate(param,msg){ //生成模块-项目管理
        return post("/device/project/download-project-model",param, msg)
    },
    uploadProjectTemplate(param,msg){ //批量导入-项目管理
        return post("/device/project/upload-project-info",param, msg)
    },
    downloadDeviceInfoTemplate(param,msg){ //生成模块-设备管理
        return post("/device/device/downloadDeviceInfoTemplate",param, msg)
    },
    uploadDeviceInfo(param,msg){ //批量导入-设备管理
        return post("/device/device/uploadDeviceInfo",param, msg)
    },
    downloadDeviceInfo(param,msg){ //批量导出设备管理列表
        return post("/device/device/downloadDeviceInfo",param, msg)
    },
    downloadEchartData(param,msg){ //批量导出设备管理列表
        return post("/device/device/download/device-properties-data",param, msg)
    },
    downloadDeviceDataInfo(param,msg){ //批量导出设备数据管理列表
        return post("/device/device/downloadDeviceDataInfo",param, msg)
    },

    fuzzy(param,msg){ //搜索条件支持：行政区划名、监测点名、设备名。返回树形结构。
        return post("/device/monitor/tree-query/fuzzy",param, msg)
    },
    monitorNum(param,msg){ //查询区县级监测点数量(地图使用)
        return post("/device/monitor/query/monitor-num/province",param, msg)
    },

    downloadUserInfoTemplate(param,msg){ //生成模块-用户
        return post("/rbac/user/download-user-info-template",param, msg)
    },
    downloadUserInfoAnalysis(param,msg){ //批量导入-用户
        return post("/rbac/user/upload-analysis-user-info",param, msg)
    },
    downloadUserInfo(param,msg){ //导出-用户
        return post("/rbac/user/download-user-infos",param, msg)
    },

    uploadManufacturerInfo(param,msg){ //批量导入-厂商
        return post("/device/manufacturer/upload-manufacturer-info",param, msg)
    },
    downloadManufacturerInfo(param,msg){ //导出-厂商
        return post("/device/manufacturer/download-manufacturer-infos",param, msg)
    },
    userInfoTemplate(param,msg){ //生成模块-厂商
        return post("/device/manufacturer/download-manufacturer-model",param, msg)
    },
    downloadProductInfo(param,msg){ //导出-产品
        return post("/device/product/downloadProductInfo",param, msg)
    },
    // byGeoRange(param,msg){ //可视化查询
    //     return post("/device/device/query/by-geo-range",param, msg)
    // },
    byGeoRange(param,msg){ //可视化查询
        return post("/device/device/query/by-geo-range/gcj02",param, msg,true)
    },
    byGeoRangeLatestData(param,msg){ //可视化查询
        return post("/device/device/query/by-geo-range/gcj02/latest",param, msg,true)
    },
    monitorByGeoRange(param,msg){ //可视化查询
        return post("/device/monitor/query/by-geo-range",param, msg,true)
    },
    initTableConfig(param,msg){
        return post("/rbac/user/page-config/clear-config/all",param, msg)
    },
    clearTableConfig(param,msg){ //重置列
        return post("/rbac/user/page-config/clear-config",param, msg)
    },
    setSystemConfig(param,msg){ //保存
        return post("/rbac/system/config/set",param, msg)
    },
    getSystemConfig(param,msg){ //保存
        return post("/rbac/system/config/get",param, msg)
    },
    setTableConfig(param,msg){ //自定义列
        return post("/rbac/user/page-config/set-config",param, msg,true)
    },
    getTableConfig(param,msg){ //查询
        return post("/rbac/user/page-config/get-config",param, msg,true)
    },
}

