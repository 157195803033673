<template>
    <div>
        <el-upload ref="upload" :on-remove="handleRemove" :style="{'width':width+'px','height':height+'px'}"
            :class="{ 'disabled': disabled, hideUpload: fileList.length == maxNum }" action="11111" :disabled="disabled"
            :before-upload="volidFile" :file-list="fileList" list-type="picture-card" :http-request="httpRequest"
            :limit="limit" :multiple="multiple" :on-exceed="onExceed" :accept="fileType">
            <!-- <i slot="default" class="el-icon-plus"></i> -->
            <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="$FileBaseURL + file.url + $setToken()" alt="">
                <span class="el-upload-list__item-actions">
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                    </span>
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <!-- <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                    <i class="el-icon-download"></i>
                </span> -->
                </span>
            </div>
            <template slot="trigger">
                <i class="el-icon-plus"></i>
                <span class="tip" v-if="tip">{{ tip }}</span>
            </template>
        </el-upload>
        <span v-if="tips" class="tip">{{ tips }}</span>
        <el-dialog :visible.sync="dialogVisible" :modal="false">
            <img width="100%" :src="$FileBaseURL + dialogImageUrl + setToken()" alt="">
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "jUploadImg2",
    props: {
        value: {
            type: Array,
            default: () => []
        },
        width: { //文件数量
            type: Number,
            default: 148
        },
        height: { //文件数量
            type: Number,
            default: 148
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        limit: { //文件数量
            type: Number,
            default: 10
        },
        maxSize: { //文件大小
            type: Number,
            default: 2
        },
        maxNum: { //文件数量
            type: Number,
            default: 2
        },
        tip: {
            type: String,
            default: ""
        },
        tips: {
            type: String,
            default: ""
        },
        fileType: {
            type: String,
            default: ".png,.jpeg,.jpg,.ico"
        },
    },
    data() {
        return {
            fileList: [],
            dialogImageUrl: '',
            dialogVisible: false,
            // tips: `支持jpg/jpeg/png格式，单张图片限制${this.maxSize}MB以内，最多上传${this.maxNum}张`
        };
    },
    watch: {
        value: {
            handler(val) {
                if (Array.isArray(val) && val.length) {
                    this.fileList = val
                } else {
                    this.fileList = []
                }
            },
            immediate: true,
        }
    },
    mounted() {
        if (Array.isArray(this.value) && this.value.length) {
            this.fileList = this.value
        }
    },
    methods: {
        setToken() {
            let access_token = sessionStorage.getItem('access_token').replace('bearer ', '')
            // console.log(access_token,'access_token')
            return '?access_token=' + access_token
        },
        handleRemove(file, fileList) {
            this.fileList = this.fileList.filter(item => item.url != file.url)
            this.$emit('input', this.fileList)
            this.$emit('remove',file)
        },
        httpRequest(data) {
            if (!this.volidFile(data.file)) return
            // 封装FormData对象
            var formData = new FormData();
            formData.append("file", data.file);
            formData.append("model", 'person');
            // formData.append("type", data.file.type);
            formData.append("type", "image");
            // 调用后端接口
            this.$api.uploadFile(formData).then(res => {
                this.fileList.push({
                    name: res.fileName,
                    url: res.filePath,
                })
                this.$emit('input', this.fileList)
                this.$emit('uploadSuccess', res)
            }).catch(err => { })
        },
        volidFile(file) {
            let FileTypes = {
                '.svg':"image/svg+xml",
                '.bmp':"image/bmp",
                '.ico':'image/x-icon',
                '.jpeg': 'image/jpeg',
                '.png': 'image/png',
                '.jpeg': 'image/jpeg',
                '.jpg': 'image/jpeg',
                '.pdf': 'application/pdf',
                '.pptx': "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                '.docx': "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                '.xlsx': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                '.txt': "text/plain",
                '.doc': "application/msword",
            }
            let limitTypes = {}
            for (let item of this.fileType.split(',')) {
                limitTypes[item] = FileTypes[item]
            }

            if (!Object.values(limitTypes).includes(file.type)) {
                // this.$refs.upload.clearFiles();
                this.$message.error('暂不支持该格式的文件，请重新上传！');
                return false
            }
            const maxSize = file.size / (1024 * 1024) < this.maxSize;
            if (!maxSize) {
                // this.$refs.upload.clearFiles();
                this.$message.error(`上传文件大小不能超过${this.maxSize}MB!`);
                return false
            }
            if (this.fileList.length >= this.maxNum) {
                // this.$refs.upload.clearFiles();
                this.$message.error(`最多可以上传${this.maxNum}个文件`);
                return false
            }
            return true
        },
        onExceed() {
            this.$message.error(`最多可以上传${this.limit}个文件`)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = '/' + file.url;
            this.dialogVisible = true;
        },
    }
}
</script>
<style scoped lang="scss">
::v-deep .el-upload--picture-card {
    position: relative;
    background-color: rgba(251, 253, 255, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item{
    width:100%;
    height: auto;
    max-height: 100%;
}
.hideUpload ::v-deep .el-upload--picture-card {
    display: none;
}
.tip {
    color: $error;
    line-height: 18px;
    display: block;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, 20px, 0);
    width: 100%;
}
</style>