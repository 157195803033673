<template>
  <div class="m-t-30">
    <!-- <span @click="setCollapse" :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></span> -->
    <el-menu :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
      background-color="transparent" text-color="#3e3e3e" active-text-color="#2859f3" :unique-opened="false">
      <div v-for="(item, i) in menu_list">
        <el-submenu v-if="item.children && item.children.length" :index="item.path">
          <template slot="title">
            <router-link :to="{ name: item.name }">
              <span :class="`iconfont icon-${item.name} ${$route.path.includes(item.path)?'active':''}`">{{ item.meta.title }}</span>
            </router-link>
          </template>
          <menu-tree class="aside-main" :dataList="item.children" :index="item.path"></menu-tree>
        </el-submenu>
        <router-link v-else :index="item.path" :to="{ name: item.name }">
          <el-menu-item>
            <span :class="`iconfont icon-${item.name} ${$route.path.includes(item.path)?'active':''}`">{{ item.meta.title }}</span>
          </el-menu-item>
        </router-link>
      </div>
    </el-menu>
  </div>
</template>

<script>
import menuTree from "./Menu-tree.vue";
export default {
  data() {
    return {
      isCollapse: true,
      menu_list: [],
    };
  },
  components: { menuTree },
  computed: {
    navActive() {
      const path = this.$route.path.split("/")
      return '/' + path[1]
    }
  },
  mounted() {
    let menu_list = []
    this.recursionRouter(this.$router.options.routes, menu_list)
    this.menu_list = menu_list
    console.log(menu_list,'menu_list')
    this.menu_list.unshift({
      name: "home",
      path: "/home",
      meta: {
        isMenu: true,
        title: "首页"
      }
    })
  },
  methods: {
    recursionRouter(routes, menu_list) {
      for (let route of routes) {
        if (!!route.meta.isMenu) {
          menu_list.push(JSON.parse(JSON.stringify(route)))
          if (route.children && route.children.length) {
            menu_list[menu_list.length - 1].children = []
            this.recursionRouter(route.children, menu_list[menu_list.length - 1].children)
          }
        }
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    setCollapse() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-width: 180px;
  min-height: 400px;
  border-right: none
}

::v-deep .el-menu--collapse {
  min-width: 180px;
  text-align: left;
}

::v-deep .el-menu-item,
::v-deep .el-submenu__title {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}

::v-deep .el-submenu__icon-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(50%);
  transition: transform .3s;
  font-size: 14px;
  margin-top: -10px;
}

::v-deep .el-icon-location {
  width: auto;
}

.m-t-30 {
  padding-top: 20px;
}
span{
  color:#3e3e3e;
  font-size: 14px;
}
.iconfont::before{
  color:#3e3e3e;
  margin-right: 5px;
  font-size: 14px;
}
.active,.active.iconfont::before{
  color:#2859f3;
}
</style>