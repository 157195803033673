import setMain from '@/util/init'
import Routers from '@/router'
import "@/icons"
import './util/draggable.js'
let routes = []
let setRouterByMenu = function (menulist) {  // name children
    let namelist = []
    getNameList(menulist, namelist)
    console.log(namelist,'namelist')
    let parentNames = []
    for(let menu of namelist){
        getNameParents(menu,Routers,parentNames)
    }
    namelist = ['base', 'home', 'login','cockpit','map','noAuthor'].concat(namelist).concat(parentNames)
    filterRouterTree(Routers, namelist, routes)
    setMain(routes)
    routes = []
}
let getNameParents = function (menuName, RouterTree,parentNames) {
    for (let menu of RouterTree) {
        if (menu.children && Array.isArray(menu.children) && menu.children.length) {
            let childNames=menu.children.map(item=>item.name)||[]
            if(childNames.includes(menuName)){
                parentNames.push(menu.name) //找到第一个父级
                getNameParents(menu.name,Routers,parentNames) //父级再找父级
                return
            }else{
                getNameParents(menuName,menu.children,parentNames)
            }
        }
    }
}
let getNameList = function (menulist, namelist) {
    for (let menu of menulist) {
        menu.check&&namelist.push(menu.name) //把启用的 放进菜单 名字过滤列表内
        // namelist.push(menu.name)
        if (menu.children && Array.isArray(menu.children) && menu.children.length) {
            getNameList(menu.children, namelist)
        }
    }
}
let filterRouterTree = function (routerList, namelist, routes) {
    for (let item of routerList) {
        if (item.meta.isMenu) {
            if (!!namelist.includes(item.name)) {
                let newItem = JSON.parse(JSON.stringify(item))
                if (item.component.includes('router-view.vue')) {
                    newItem.component = () => import(`@/Layout/${item.component}`)
                }else if(item.component.includes('App.vue')){
                    newItem.component = () => import('@/App.vue')
                } else {
                    newItem.component = () => import(`@/views/${item.component}`)
                }
                routes.push(newItem)
                if (item.children && Array.isArray(item.children) && item.children.length) {
                    routes[routes.length - 1].children = []
                    filterRouterTree(item.children, namelist, routes[routes.length - 1].children)
                }
            }
        } else if (!item.children || (Array.isArray(item.children) && !item.children.length)) {
            let newItem = JSON.parse(JSON.stringify(item))
            if (item.component.includes('router-view.vue')) {
                newItem.component = () => import(`@/Layout/${item.component}`)
            } else {
                newItem.component = () => import(`@/views/${item.component}`)
            }
            routes.push(newItem)
        }
    }
}
let menulist = sessionStorage.getItem('menulist') ? JSON.parse(sessionStorage.getItem('menulist')) : []
setRouterByMenu(menulist)
export default setRouterByMenu