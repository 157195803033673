import Vue from "vue";
import Vuex from "vuex";
import state from "./children/state";
import mutations from "./children/mutation";
import actions from "./children/actions";
import getters from "./children/getters";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
