<template>
  <div id="app">
    <Layout v-if="$store.state.access_token" />
    <login v-else />
  </div>
</template>
<script>
import Layout from '@/Layout/index'
import login from '@/views/login'
export default {
  name: 'App',
  components: {
    login,Layout
  },
  data() {
    return {
      
    }
  },
  mounted(){
    // this.$router.push('home')
  },
}
</script>
<style>
#app {
  font-size: 14rem;
}
</style>
