var rules = {
    alphanumber(rule, value, callback) {  // 判断输入是否是一个由 0-9 / A-Z / a-z 组成的字符串
        var result = (value + '').match(/^[a-zA-Z0-9]+$/);
        if (result == null) {
            callback(new Error('请输入数字、大小写字母'))
        } else {
            callback()
        }
    },
    number(rule, value, callback) { // 判断输入是否是一个数字--(数字包含小数)--
        var result = (value + '').match(/^[0-9]+$/);
        if (result == null) {
            callback(new Error('请输入数字'))
        } else {
            callback()
        }
    },
    number12(rule, value, callback) { // 数字或空
        var result = (value + '').match(/^[0-9]{12}$/);
        if (result == null) {
            callback(new Error('请输入12位数字'))
        } else {
            callback()
        }
    },
    numberOrEmpty12(rule, value, callback) { // 数字或空
        var result = (value + '').match(/^[0-9]{12}$/);
        if (!value) {
            callback()
            return
        }
        if (result == null) {
            callback(new Error('请输入12位数字'))
        } else {
            callback()
        }
    },
    money(rule, value, callback) { //验证金额
        var result = value.match(/((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/);
        if (result == null) {
            callback(new Error('输入的金额格式有误'))
        } else {
            callback()
        }
    },
    int(rule, value, callback) { // 判断输入是否是一个整数
        var result = value.match(/^(-|\+)?\d+$/);
        if (result == null) {
            callback(new Error('请输入整数'))
        } else {
            callback()
        }
    },
    int1(rule, value, callback) { //校验是否为(0-10000)的整数
        var result = value.match(/^[0-9]$|^([1-9])([0-9]){0,3}$|^10000$/);
        if (result == null) {
            callback(new Error('请输入0-10000的整数'))
        } else {
            callback()
        }
    },
    dateTime(rule, value, callback) { // 判断输入是否是有效的长日期格式 -
        var validTime = function (value) {
            var result = (value + '').match(/^(\d{4})(-|\/)(\d{1,2})\2(\d{1,2}) ((\d{1,2}):(\d{1,2}):(\d{1,2}))|(\d{1,2}):(\d{1,2})$/);
            if (result == null) {
                callback(new Error('请输入有效的长日期格式'))
            } else {
                callback()
            }
        }
        if (Object.prototype.toString.call(value) === '[object Array]') {
            for (let item of value) {
                return validTime(item)
            }
        } else {
            return validTime(value)
        }
    },
    time(rule, value, callback) { // 判断输入是否是有效的长日期格式 -
        var validTime = function (value) {
            var result = (value + '').match(/^((\d{1,2}):(\d{1,2}):(\d{1,2}))|(\d{1,2}):(\d{1,2})|(\d{1,2})$/);
            if (result == null) {
                callback(new Error('请输入有效的日期格式'))
            } else {
                callback()
            }
        }
        if (Object.prototype.toString.call(value) === '[object Array]') {
            for (let item of value) {
                return validTime(item)
            }
        } else {
            return validTime(value)
        }
    },
    date(rule, value, callback) { // 检查是否为 YYYY-MM-DD || YYYY/MM/DD 的日期格式
        let validTime = function (value) {
            var result = (value + '').match(/^(\d{4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
            if (result == null) {
                callback(new Error('请输入有效的日期格式'))
            } else {
                callback()
            }
        }
        if (Object.prototype.toString.call(value) === '[object Array]') {
            for (let item of value) {
                return validTime(item)
            }
        } else {
            return validTime(value)
        }
    },
    email(rule, value, callback) { // 判断输入是否是有效的电子邮件
        var result = value.match(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/);
        if (result == null) {
            callback(new Error('电子邮件格式不正确'))
        } else {
            callback()
        }
    },
    trim(value) { // 去除字符串的首尾的空格
        return (value + '').replace(/(^\s*)|(\s*$)/g, "");
    },
    strLen(value) { // 返回字符串的实际长度, 一个汉字算2个长度
        return (value + '').replace(/[^\x00-\xff]/g, "**").length;
    },
    postCode(rule, value, callback) //匹配中国邮政编码(6位)
    {
        var result = (value + '').match(/[1-9]\d{5}(?!\d)/);
        if (result == null) {
            callback(new Error('请输入中国邮政编码(6位)'))
        } else {
            callback()
        }
    },
    tell(rule, value, callback) { //匹配国内电话号码(0511-4405222 或 021-87888822)
        var result = (value + '').match(/\d{3}-\d{8}|\d{4}-\d{7}/);
        if (result == null) {
            callback(new Error('国内电话号码格式有误'))
        } else {
            callback()
        }
    },
    mobile(rule, value, callback) { //手机号码
        var result = (value + '').match(/^1(3|4|5|6|7|8|9)[0-9]{9}$/);
        if (result == null) {
            callback(new Error('手机号码格式有误'))
        } else {
            callback()
        }
    },
    mobileOrNo(rule, value, callback) { //手机号码
        if (!value) callback()
        var result = (value + '').match(/^1(3|4|5|6|7|8|9)[0-9]{9}$/);
        if (result == null) {
            callback(new Error('手机号码格式有误'))
        } else {
            callback()
        }
    },
    url(rule, value, callback) { //url域名
        var sRegex = '^((https|http|ftp|rtsp|mms)?://)' + '?(([0-9a-z_!~*\'().&=+$%-]+: )?[0-9a-z_!~*\'().&=+$%-]+@)?' //ftp的user@ 
            + '(([0-9]{1,3}.){3}[0-9]{1,3}' // IP形式的URL- 199.194.52.184 
            + '|' // 允许IP和DOMAIN（域名） 
            + '([0-9a-z_!~*\'()-]+.)*' // 域名- www. 
            + '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].' // 二级域名 
            + '[a-z]{2,6})' // first level domain- .com or .museum 
            + '(:[0-9]{1,4})?' // 端口- :80 
            + '((/?)|' // a slash isn't required if there is no file name 
            + '(/[0-9a-z_!~*\'().;?:@&=+$,%#-]+)+/?)$';
        var re = new RegExp(sRegex);
        if (re.test(sUrl)) {
            callback(new Error('域名格式有误'))
        } else {
            callback()
        }
    },
    qq(rule, value, callback) { //匹配腾讯QQ号
        var result = (value + '').match(/[1-9][0-9]{4,}/);
        if (result == null) {
            callback(new Error('QQ号码格式有误'))
        } else {
            callback()
        }
    },
    idCardOrNo(rule, value, callback) { //匹配身份证(15位或18位)
        if (!value) callback()
        var result = (value + '').match(/^([1-9]\d{5}\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[01])\d{2})|([1-9]\d{5}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[01])\d{3}([0-9]|X))$/);
        if (result == null) {
            callback(new Error('身份证号码格式有误'))
        } else {
            callback()
        }
    },
    idCard(rule, value, callback) { //匹配身份证(15位或18位)
        var result = (value + '').match(/^([1-9]\d{5}\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[01])\d{2})|([1-9]\d{5}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[01])\d{3}([0-9]|X))$/);
        if (result == null) {
            callback(new Error('身份证号码格式有误'))
        } else {
            callback()
        }
    },
    userName(rule, value, callback) { //验证用户名或昵称经常用到: 只能中英文，数字，下划线
        var result = (value + '').match(/^[\w\u4e00-\u9fa5]{2,16}$/g);
        if (result == null) {
            callback(new Error('只能输入2-16位中英文，数字，下划线'))
        } else {
            callback()
        }
    },
    realname(rule, value, callback) { //验证用户名或昵称经常用到: 只能中英文，数字，下划线
        var result = (value + '').match(/^[\w\u4e00-\u9fa5]{2,}$/g);
        if (result == null) {
            callback(new Error('只能输入中英文，数字，下划线'))
        } else {
            callback()
        }
    },
    password(rule, value, callback) { //验证用户密码: 正确格式为：以字母开头，长度在6-18之间，只能包含字符、数字和下划线
        // 检查是否以字母开头
        const startsWithLetter = /^[A-Za-z]/;
        // 检查是否包含大写字母、小写字母、数字和特殊符号，并满足长度要求
        const validContent = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])(?!.*\s).{8,18}$/;
        var result = (value + '').match(startsWithLetter) && (value + '').match(validContent);
        if (result == null) {
            callback(new Error('以字母开头，需包含大、小写字母、数字、特殊字符'))
        } else {
            callback()
        }
    },
    confirmPassword: (rule, value, callback, validate) => { //验证用户密码: 正确格式为：以字母开头，长度在6-18之间，只能包含字符、数字和下划线
        console.log(validate, rule, value, 'valuesss')
        // const startsWithLetter = /^[A-Za-z]/;
        // const validContent = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,18}$/;
        //
        // var result = (value + '').match(startsWithLetter) && (value + '').match(validContent);
        // if (result == null) {
        //     callback(new Error('由7-17位大、小写字母、数字、特殊字符组成'))
        // } else
        if (validate !== value) {
            callback(new Error('两次密码不一致'))
        } else {
            callback()
        }
    },
    name(rule, value, callback) { //验证真实姓名
        var regName = /^[\u4e00-\u9fa5]{2,4}$/;
        if (!regName.test(value + "")) {
            callback(new Error('请输入真实姓名'))
        } else {
            callback()
        }
    },
    deviceCode(rule, value, callback) { //验证设备编号
        var regName = /^[-_a-z0-9A-Z]+$/;
        if (!regName.test(value + "")) {
            callback(new Error('设备编号仅支持:大小写字母、数字、下划线和连字符'))
        } else {
            callback()
        }
    },
    isEmpty(rule, value, callback) { //验证用户名或昵称经常用到: 只能中英文，数字，下划线，减号
        var result = value.match(/\S/);
        if (result == null) {
            callback(new Error('不能为空'))
        } else {
            callback()
        }
    },
    isEmptyArray(rule, value, callback) { // 判断是不是空数组
        if (Object.prototype.toString.call(value) === '[object Array]') {
            for (let item of value) {
                if (!item) {
                    callback(new Error('不能为空'))
                } else {
                    callback()
                }
            }
        } else {
            callback(new Error('数据格式有误'))
        }
    },
}

export default {
    ...rules
}