import { post } from "@/request/http";

export default {
    userAdd(param,msg) {  //用户信息新增
        return post("/rbac/user/add", param,msg)
    },
    userDel(param,msg) {  //用户信息删除
        return post("/rbac/user/delete", param,msg)
    },
    userenable(param,msg) {  //启用
        return post("/rbac/user/enable", param,msg)
    },
    userUpdate(param,msg) {  //用户信息修改
        return post("/rbac/user/update", param,msg)
    },
    userQuery(param,msg) {  //用户信息条件查询
        return post("/rbac/user/query", param,msg)
    },
    userQueryPage(param,msg) {  //用户信息分页查询
        return post("/rbac/user/query/page", param,msg)
    },
    userQueryById(param,msg) {  //用户信息详情
        return post("/rbac/user/query/id", param,msg)
    },
    extendConfigUpdate(param,msg) {  //扩展字段配置修改
        return post("/rbac/user/extend-config/update", param,msg)
    },
    extendConfigAdd(param,msg) {  //扩展字段配置新增
        return post("/rbac/user/extend-config/add", param,msg)
    },
    extendConfigDelete(param,msg) {  //扩展字段配置删除
        return post("/rbac/user/extend-config/delete", param,msg)
    },
    extendConfigQuery(param,msg) {  //扩展字段配置条件查询
        return post("/rbac/user/extend-config/query-extend", param,msg)
    },
    extendConfigQueryPage(param,msg) {  //扩展字段配置分页条件查询
        return post("/rbac/user/extend-config/query-extend/page", param,msg)
    },
    // basicConfigQueryPage(param,msg) {  //扩展字段配置分页条件查询
    //     return post("/rbac/user/extend-config/query-basic/page", param,msg)
    // },
    basicConfigQueryPage(param,msg) {  //基本字段配置条件查询
        return post("/rbac/user/extend-config/query-basic", param,msg)
    },
    usersBindOrg(param,msg) {  //绑定组织
        return post("/rbac/user/bind-org-and-users", param,msg)
    },

    //角色
    roleDel(param,msg) {  //删除角色
        return post("/rbac/role/delete", param,msg)
    },
    roleQuery(param,msg) {  //条件查询角色
        return post("/rbac/role/query", param,msg)
    },
    roleQueryById(param,msg) {  //主键查询
        return post("/rbac/role/queryById", param,msg)
    },
    roleEnable(param,msg) {  //启停用角色
        return post("/rbac/role/enable", param,msg)
    },
    roleAdd(param,msg) {  //新增角色
        return post("/rbac/role/add", param,msg)
    },
    roleQueryPage(param,msg) {  //分页条件查询角色
        return post("/rbac/role/query/page", param,msg)
    },
    roleUpdate(param,msg) {  //更新角色
        return post("/rbac/role/update", param,msg)
    },
    queryUsersListById(param,msg) {  //更新角色
        return post("/rbac/role/queryUsers", param,msg)
    },
    // 组织
    orgAdd(param,msg) {  //新增组织
        return post("/rbac/org/add", param,msg)
    },
     // 修改组织
    orgEdit(param,msg) {  //条件查询角色
        return post("/rbac/org/edit", param,msg)
    },
    orgDel(param,msg) {  //条件查询角色
        return post("/rbac/org/del", param,msg)
    },
    orgTreeQuery(param,msg) {  //条件查询角色
        return post("/rbac/org/tree-query", param,msg)
    },
    manufacturerAdd(param,msg) {  //新增
        return post("/device/manufacturer/add", param,msg)
    },
    manufacturerEdit(param,msg) {  //修改
        return post("/device/manufacturer/edit", param,msg)
    },
    manufacturerDelete(param,msg) {  //删除
        return post("/device/manufacturer/delete", param,msg)
    },
    manufacturerQuery(param,msg) {  //条件查询
        return post("/device/manufacturer/query", param,msg)
    },
    manufacturerQueryPage(param,msg) {  //分页查询
        return post("/device/manufacturer/query/page", param,msg)
    },
    manufacturerQueryPageall(param,msg) {  //分页查询
        return post("/device/manufacturer/query/all", param,msg)
    },
    bindorgandmanufacturer(param,msg) {  //组织绑定厂商
        return post("/device/manufacturer/bind-org-and-manufacturer", param,msg)
    },
    manufacturerBindOrg(param,msg) {  //厂商绑定组织
        return post("/device/manufacturer/bind-manufacturer-and-org", param,msg)
    },
    initpwd(param,msg) {  //厂商绑定组织
        return post("/rbac/user/init-pwd", param,msg)
    },
    batchDeleteUser(param,msg) {  //批量删除-用户
        return post("/rbac/user/delete/batch", param,msg)
    },
    batchDeleteRole(param,msg) {  //批量删除-角色
        return post("/rbac/role/delete-batch", param,msg)
    },
    changePws(param,msg) {  //批量删除-角色
        return post("/rbac/user/change-pwd", param,msg)
    },
    changePwsByAdmin(param,msg) {  //批量删除-角色
        return post("/rbac/user/change-pwd/by-admin", param,msg)
    },
    postQueryOrgUserAll(param,msg) { // 查询机构本级及以下的用户信息
        return post("/rbac/user/query/org-num", param,msg)
    }
}