export default {
  path: '/warning',
  name: 'warning',
  component: 'router-view.vue',
  redirect: {name:'warning_device_pending'},
  meta: {
    isMenu: true,
    title: "预警管理",
    redirect:true,
  },
  children: [
    {
      path: 'warning-monitor',
      name: 'warning_monitor',
      component: 'router-view.vue',
      redirect: {name:'warning_monitor_pending'},
      meta: {
        isMenu: true,
        redirect:true,
        title: "监测点预警管理",
      },
      children: [
        {
          path: 'pending',
          name: 'warning_monitor_pending',
          component: 'warning/monitor/pending.vue',
          meta: {
            isMenu: false,
            title: "待处理",
          },
        },
        {
          path: 'processing',
          name: 'warning_monitor_processing',
          component: 'warning/monitor/processing.vue',
          meta: {
            isMenu: false,
            title: "处理中",
          },
        },
        {
          path: 'processed',
          name: 'warning_monitor_processed',
          component: 'warning/monitor/processed.vue',
          meta: {
            isMenu: false,
            title: "已处理",
          },
        },
      ]
    },
    {
      path: 'warning-device',
      name: 'warning_device',
      component: 'router-view.vue',
      redirect: {name:'warning_device_pending'},
      meta: {
        isMenu: true,
        redirect:true,
        title: "设备预警管理",
      },
      children: [
        {
          path: 'pending',
          name: 'warning_device_pending',
          component: 'warning/device/pending.vue',
          meta: {
            isMenu: false,
            title: "待处理",
          },
        },
        {
          path: 'processing',
          name: 'warning_device_processing',
          component: 'warning/device/processing.vue',
          meta: {
            isMenu: false,
            title: "处理中",
          },
        },
        {
          path: 'processed',
          name: 'warning_device_processed',
          component: 'warning/device/processed.vue',
          meta: {
            isMenu: false,
            title: "已处理",
          },
        },
      ]
    },
  ]
}