export default [
  {
    path: '/',
    name:'base',
    component: 'App.vue',
    redirect: {name:'home'},
    meta: {
      isMenu: false,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: 'home/home.vue',
    meta: {
      isMenu: false,
    },
  },
  {
    path: '/cockpit',
    name: 'cockpit',
    component: 'home/cockpit.vue',
    meta: {
      isMenu: false,
    },
  },
  {
    path: '/map',
    name: 'map',
    component: 'home/map.vue',
    meta: {
      isMenu: false,
    },
  },
  // {
  //   path: '*',
  //   name: '404',
  //   component: 'home/404.vue',
  //   meta: {
  //     isMenu: false,
  //   },
  // },
  {
    path: '*',
    name: 'noAuthor',
    component: 'home/noAuthor.vue',
    meta: {
      isMenu: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: 'App.vue',
    meta: {
      isMenu: false,
    },
  },
]