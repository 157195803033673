<template>
    <div>
        <el-input placeholder="输入关键字进行过滤" v-model.trim="filterText" :disabled="disabled" clearable>
        </el-input>
        <el-tree v-loading="loading"  class="filter-tree" :node-key="propsName" :current-node-key="orgNum" :disabled="disabled"
            :expand-on-click-node="false" :data="treeData" :props="defaultProps" :filter-node-method="filterNode" ref="tree"
            @node-click="nodeClick" @node-expand="nodeExpand" >
            <span class="custom-tree-node" @dblclick="dblclickNode(data)" :class="{ 'active': orgNum == data[propsName] }" slot-scope="{ node, data }">
                <span class="el-icon-share"></span>{{ data[defaultProps.label] }}<el-tag v-if="!!showfacturer&&data[showfacturer]" size="mini" style="margin-left: 5px;">厂商</el-tag>
            </span>
        </el-tree>
    </div>
</template>

<script>
export default {
    name: "jTree",
    props: {
        value: {
            type: String,
            default: ""
        },
        showfacturer: {
            type: String,
            default: ''
        },
        defaultProps: {
            type: Object,
            default: () => {
                return {
                    children: 'childOrgVos',
                    label: 'orgName'
                }
            }
        },
        propsName: {
            type: String,
            default: "orgNum"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        noSearch: {
            type: Boolean,
            default: false
        },

    },
    watch: {
        filterText(val) {
            console.log(val,'val')
            this.$refs.tree.filter(val);
        },
        value(val) {
            this.orgNum = val
        },
    },
    data() {
        return {
          filterText: '',
          orgNum: '',
          treeData: [],
          loading: true,
        }
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data[this.defaultProps.label].indexOf(value) !== -1;
        },
        async getDataFromApi() {
          this.loading = true;
          const result = await this.$api.orgTreeQuery({ entity: {} });
          this.treeData = [result];
          this.orgNum = this.value;

          // Automatically expand the first level of nodes
          this.$nextTick(() => {
            this.treeData.forEach(nodeData => {
              const node = this.$refs.tree.getNode(nodeData[this.propsName]);
              // console.log('node: ', node);
              if (node) {
                // this.$refs.tree.store.expandNode(node, true);
                this.$refs.tree.store.nodesMap[nodeData[this.propsName]].expanded=true;
              }
            });
          });

          this.loading = false;
        },
        nodeClick(data) {
            if (!this.noSearch) {
                let obj = this.$store.state.searchable
                obj[this.propsName] = data[this.propsName]
                this.orgNum = data[this.propsName]
                this.$store.commit('setSearchable', obj)
                this.$emit('search', obj)
            }
            this.$emit('input', data[this.propsName])
            this.$emit('getRowInfo', data)
        },
        nodeExpand(node){
            this.$emit('nodeExpand', node)
        },
        dblclickNode(node){
            this.$emit('dblclickNode', node)
        }
    },
    mounted() {
        this.getDataFromApi()
    },
};
</script>
<style scoped lang="scss">
.el-icon-share {
    color: $primary;
    margin: 0 5px;
}

::v-deep .is-current {
    span {}
}

.active {
    color: $primary;
}

.filter-tree {
    max-height: 65vh;
    overflow-y: auto;
}
.el-tree{
    width: 100%;
}
</style>