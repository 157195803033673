<template>
  <div class="j-table" style="margin-top: 20px;">

    <el-button v-if="configKey" class="table-setting el-icon-s-operation" type="text"
               @click="drawer = true">列表配置
    </el-button>
    <el-table ref="jTable" :border="config.border" :data="tableData"
              :header-cell-class-name="handleHeadAddClass" :header-cell-style="{ 'text-align': 'left', 'background': '#ededed' }"
              :highlight-current-row="showClick && !selection" :max-height="height" :row-key="row => row.id" class="set-table-w"
              header-align="center" style="width:100%;min-width: 100%!important;" @select="toggleRowSelection"
              @row-click="goDetail" @sort-change="hanlderSortChange"
              @select-all="selectAll" @row-dblclick="onRowDbClick"
              @header-dragend="headerDragend">
      <el-table-column v-if="!!hasNo" :resizable="resizable" align="center" label="序号" width="60">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="!!selection" :resizable="resizable" row-id="id" type="selection" width="55">
      </el-table-column>
      <el-table-column v-if="!!radioction" :resizable="resizable" row-id="id" type="radio" width="55">
        <template slot="header">
          <i class="el-icon-refresh" @click="refreshRadio"></i>
        </template>
        <template slot-scope="{row}">
          <el-radio v-model="radioCheck" :label="row.id" @input="selectRadio(row)"></el-radio>
        </template>
      </el-table-column>
      <el-table-column v-for="(item, i) in columnConfig" v-if="item.show"
                       :key="Math.random() + i" :fixed="item.fixed || false" :label="item.label" :min-width="item.minWidth || '-'"
                       :prop="item.prop"
                       :resizable="resizable" :show-overflow-tooltip="item.hideTool ? false : true" :sort-orders="['descending', 'ascending', null]"
                       :sortable="item.sortable || false" :width="item.width" align="center">
        <template slot-scope="scope">
          <div @click="clickNode(scope.row, item)">
            <el-image v-if="item.type == 'image'" :preview-src-list="[scope.row[item.prop]]"
                      :src="scope.row[item.prop]"></el-image>
            <span v-else-if="item.type === 'password'">********</span>
            <!-- 数组 + color = 统一色带背景 -->
            <div v-else-if="Array.isArray(scope.row[item.prop]) && !item.join">
              <!-- <el-tag class="m-r-5" :type="item.tagType || ''" v-for="(cItem, i) in scope.row[item.prop]"
                  :key="i">{{ cItem }}</el-tag> -->
              <span class="m-r-5">{{ scope.row[item.prop].join(",") }}</span>
            </div>
            <!-- 数组切割 + join = 数组转字符串展示 -->
            <div v-else-if="Array.isArray(scope.row[item.prop]) && item.join">
                            <span class="control flex-wrap-start" @click="handleControl(item.clickName, scope.row)">
                                <i v-if="scope.row[item.prop] && scope.row[item.prop].length" class="el-icon-link"></i>
                                <span class="ellipsis w-200"> {{ scope.row[item.prop].join(item.join) }}</span>
                            </span>
            </div>
            <div v-else-if="item.type == 'switch'">
              <el-switch v-model="scope.row[item.prop]" :active-text="scope.row[item.prop] ? item.activeText[0] : item.activeText[1]"
                         :active-value="true" style="justify-content: center"
                         @change="handerEnable(scope.row)">
              </el-switch>
            </div>
            <div v-else-if="item.type === 'link'">
              <span class="control flex-wrap-start" @click="handlerLink(item.clickName, scope.row)">
                  <i v-if="scope.row[item.prop]" :class="item.icon"></i>
                  <span class="ellipsis w-200"> {{ scope.row[item.prop] }}</span>
              </span>
            </div>
            <div v-else-if="item.type === 'copy'">
              <copy-text>{{ scope.row[item.prop] }}</copy-text>
            </div>
            <!-- icon -->
            <div v-else-if="item.icon">
              <span class="control flex-wrap-start" @click="handleControl(item.clickName, scope.row)">
                  <i v-if="scope.row[item.prop]" :class="item.icon"></i>
                  <span class="ellipsis w-200"> {{ scope.row[item.prop] }}</span>
              </span>
            </div>
            <!-- color=true  = 状态展示 需要配置options -->
            <span v-else-if="item.color === true"
                  :class="'status status-' + getStatesLabel(item.prop, scope.row[item.prop]).color">{{
                getStatesLabel(item.prop, scope.row[item.prop]).label
              }}</span>
            <span v-else-if="item.color1 === true"
                  :class="'status status1-' + getStatesLabel(item.prop, scope.row[item.prop]).color">
                            <span v-if="getStatesLabel(item.prop, scope.row[item.prop]).label"
                                  class="iconfont icon-weiwangguanicon-defuben-"></span>
                            {{ getStatesLabel(item.prop, scope.row[item.prop]).label }}</span>
<!--            <span v-else :class="{ 'hover-class': item.hover }">{{ labelItem(item, scope.row) }}</span>-->
            <span v-else :class="{ 'hover-class': item.hover }">{{ labelItem(item, scope.row) }}</span>
            <!-- <span v-else>{{ scope.row[item.prop] }}</span> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="!!config.other" :label="config.otherLabel" :resizable="false" :sort-orders="['ascending', 'descending', null]"
                       align="center" width="120">
        <template slot-scope="scope">
          <slot :name="config.other" :row="scope.row"></slot>
        </template>
      </el-table-column>
      <el-table-column v-if="!!controlName" :fixed="fixed == '-' ? false : fixed" :label="controlName" :max-width="controlWidth"
                       :resizable="false" :sort-orders="['ascending', 'descending', null]"
                       :width="controlWidth" align="center">
        <template slot-scope="scope">
          <slot :row="scope.row" name="control"></slot>
        </template>
        <!-- <template v-slot:control="scope">
            <span class="control" @click="hander">编辑</span>
        </template> -->
      </el-table-column>
    </el-table>
    <div v-if="showPage" class="right">
      <el-pagination :current-page.sync="page.pageNum" :page-size="page.pageSize" :page-sizes="[30, 50, 70, 100]"
                     :total="total" background layout="total,sizes, prev, pager, next"
                     @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <el-drawer :modal="false" :visible.sync="drawer" size="240px" @closed="onDrawerClose">
      <template name="title">
        <div class="drawer-title">
          <span>表格配置</span>
          <el-button type="text" @click="resetTable">重置</el-button>
        </div>
      </template>
      <div>
        <div v-for="(item, index) in (columnConfig.filter(item => item.fixed) || [])" :key="index"
             :class="{ 'draggable-hide': !item.show }" :command="item.prop" class="draggable-box">
                    <span>
                        <el-badge :value="index + 1" class="item" style="margin-right: 3px;" type="warning"></el-badge>
                        {{ item.label }}</span>
          <el-switch v-model="item.show" :disabled="item.disabled" active-color="#13ce66" inactive-color="#bfbfbf"
                     size="mini" @change="changeSwith">
          </el-switch>
        </div>
        <draggable v-model="columnConfig" @end="onDragEnd">
          <div v-for="(item, index) in columnConfig.filter(item => !item.fixed)" :key="index"
               :class="{ 'draggable-hide': !item.show }" :command="item.prop" class="draggable-box">
                        <span>
                            <el-badge
                                :value="(columnConfig.filter(item => item.fixed) || []).length ? (columnConfig.filter(item => item.fixed) || []).length + index + 1: index + 1"
                                class="item" style="margin-right: 3px;" type="primary"></el-badge>
                            {{ item.label }}</span>
            <el-switch v-model="item.show" :disabled="item.disabled" active-color="#13ce66" inactive-color="#bfbfbf"
                       size="mini" @change="changeSwith">
            </el-switch>
          </div>
        </draggable>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import CopyText from "@/components/jCopyText/index.vue";

export default {
  name: "jTable",
  components: {
    CopyText,
    draggable
  },
  props: {
    value: [Array, Object],
    tableData: {
      type: Array,
      default: () => []
    },
    checkList: [Array, Object],
    showPage: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: '-'
    },
    hasNo: {
      type: Boolean,
      default: false
    },
    controlName: {
      type: String,
      default: '操作'
    },
    controlWidth: {
      type: String,
      default: '200'
    },
    tableConfig: {
      type: Object,
      default: () => {
      }
    },
    selection: {
      type: Boolean,
      default: false
    },
    radioction: {
      type: Boolean,
      default: false
    },
    hasHover: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 40
    },
    configKey: {
      type: String,
      default: ""
    },
    fixed: {
      type: String,
      default: '-'
    },
    resizable: {
      type: Boolean,
      default: false
    },
    sortOnlyOne: {
      type: Boolean,
      default: false
    },
    showClick: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      config: {},
      page: {
        pageNum: 1,
        pageSize: 50
      },
      selected: '',
      options: [
        {value: 'option1', label: '选项1'},
        {value: 'option2', label: '选项2'},
        {value: 'option3', label: '选项3'},
        // ...更多选项
      ],
      columnConfig: [],
      radioCheck: false,
      sortField: {},
      drawer: false,
      times: 1,
    }
  },
  watch: {
    tableConfig: {
      handler(val, oVal) {
        this.config = this.setConfig(val)
      },
      // immediate: true
    },
    drawer: {
      handler(val) {
        this.initDom(this.columnConfig, 1)
      },
    },
    value: {
      handler(val) {
        this.initDom(val)
      },
      immediate: true
    },
    tableData: {
      handler(val, oVal) {
        this.$nextTick(() => {
          if (this.selection) {
            let ids = (this.checkList || []).map(item => item.id) || []
            for (let item of val) {
              ids.includes(item.id) && this.$refs.jTable.toggleRowSelection(item, true);
            }
          }
          if (this.radioction) {
            this.radioCheck = (this.checkList || {}).id
          }
          this.updateTable()
        })
      },
      immediate: true
    },
    // drawer: {
    //     handler(val) {
    //         if(!val){
    //             this.saveConfigTable()
    //         }
    //     },
    // },
  },
  mounted() {
    this.config = this.setConfig(this.tableConfig)
    // 添加事件监听器
    window.addEventListener('resize', this.handleResize);
    this.$bus.$on('changePageNum', num => {
      this.page.pageNum = num
    })
  },
  beforeDestroy() {
    // this.$store.commit('setPageSize',50)
    window.removeEventListener('resize', this.handleResize);
    this.saveConfigTable()
    this.$store.commit('setPageSize', 50)
    this.$bus.$off('changePageNum')
  },
  updated() {

  },
  methods: {
    onDrawerClose() {
      this.saveConfigTable()
    },
    initDom(val, times) {
      let element = document.getElementsByClassName('main-wrap')[0]
      let screenWidth = window.getComputedStyle(element).width.replace('px', '')
      let width = 1296
      this.times = screenWidth * 1 / width
      let widx = 0, widTotal = 0, len = 0
      this.columnConfig = val.map(item => {
        this.$set(item, 'show', typeof item.show === 'boolean' ? item.show : true)
        if (item.width && item.show) {
          this.$set(item, 'width', item.width * (times ? times : this.times))
          widTotal += item.width * (times ? times : this.times)
          widx += 1
        }
        if (item.show) {
          len += 1
        }
        return item
      })
      if (document.getElementsByClassName('j-table') && document.getElementsByClassName('j-table')[0]) {
        let tableW = document.getElementsByClassName('j-table')[0]
        tableW.style.maxWidth = "100%"
        tableW.style.width = "100%"

        if (widx == len) {
          tableW.style.width = this.controlWidth * 1 + widTotal + len * 1 + 'px'
          // let emptyWid = screenWidth * 1 - this.controlWidth * 1 - widTotal
          // tableW.style.width = this.controlWidth * 1 + widTotal + len * 1 + 'px'
          // if (emptyWid > 20) {
          //     this.columnConfig = this.columnConfig.map(item => {
          //         this.$set(item, 'width', item.width + emptyWid / len)
          //         return item
          //     })
          // }
        }
      }
      this.updateTable()
    },
    handerEnable(rowData) {
      this.$emit('swichChange', rowData)
    },
    handleResize() {
      this.initDom(this.columnConfig, 1)
    },
    onRowDbClick(row) {
      this.$emit('onRowDbClick', row)
    },
    refreshRadio() {
      this.radioCheck = false
      this.selectRadio({})
    },
    onDragEnd(event) {
      this.initDom(this.columnConfig, 1)
      // 拖拽结束后的处理逻辑
    },
    changeSwith() {
      let len = this.columnConfig.filter(item => item.show).length
      if (len == 2) {
        this.columnConfig = this.columnConfig.map(item => {
          if (item.show) {
            item.disabled = true
          }
          return item
        })
      } else {
        this.columnConfig = this.columnConfig.map(item => {
          if (item.show) {
            item.disabled = false
          }
          return item
        })
      }
      this.initDom(this.columnConfig, 1)
    },
    updateTable() {
      if (this.$refs.jTable && this.$refs.jTable.doLayout) {
        this.$nextTick(() => {
          this.$refs.jTable.doLayout();
        })
      }
    },
    headerDragend(newWidth, oldWidth, column, event) {
      this.columnConfig = this.columnConfig.map(item => {
        if (column.property == item.prop) {
          item.width = parseInt(newWidth)
        }
        return item
      })
      this.initDom(this.columnConfig, 1)
    },
    clearSelection() {
      this.initDom(this.columnConfig, 1)
      this.$nextTick(() => {
        this.$refs.jTable.clearSelection()
      })
    },
    saveConfigTable() {
      if (!this.configKey) return
      // let widx=0
      let columnConfig = this.columnConfig.map(item => {
        if (item.width) {
          item.width = item.width * 1 / this.times
        }
        return item
      })
      this.$api.setTableConfig({entity: {configKey: this.configKey, configValue: columnConfig}}).then(res => {
        if (res) {
          // this.getTableConfig()
        }
      })
    },
    resetTable() {
      this.$api.clearTableConfig({entity: {configKey: this.configKey}}, '重置成功').then(res => {
        if (res) {
          this.$emit('resetColumnConfig')
          this.getTableConfig()
        }
      })
    },
    getTableConfig() {
      this.$api.getTableConfig({entity: {configKey: this.configKey}}).then(res => {
        res && res.configValue && res.configValue.length && this.$emit('input', res.configValue)
      })
    },
    handlerLink(name, row) {
      this.$emit('link', {name, row})
    },
    handleControl(name, row) {
      this.$emit('clickRow', {name, row})
    },
    goDetail(item) {
      this.$emit('go-detail', item)
    },
    clickNode(row, props) {
      this.$emit('clickNode', {row, props})
    },
    hanlderSortChange({column, prop, order}) {
      //触发的排序和缓存的排序相同时，取消该字段的排序
      if (this.sortOnlyOne) {
        this.sortField = {}
      }
      if (!order || this.sortField[prop] === order) {
        this.sortField[prop] = null;
      } else {
        this.sortField[prop] = order;
      }
      // console.
      this.setSortProp()
    },
    setSortProp() {
      let obj = this.$store.state.searchable
      obj = Object.assign(obj, this.page)
      if (this.sortField && Object.keys(this.sortField).length) {
        obj.orders = []
        for (let key of Object.keys(this.sortField)) {
          if (this.sortField[key]) {
            obj.orders.push({
              sort: this.sortField[key] == 'descending' ? 'desc' : 'asc',
              field: key
            })
          }
        }
      }
      if (obj.orders && !obj.orders.length) {
        delete obj.orders
      }
      this.$emit('search', obj)
    },
    handleHeadAddClass({column}) {
      if (this.sortField[column.property]) {
        column.order = this.sortField[column.property];
      }
    },
    getList() {
      let obj = this.$store.state.searchable
      obj = Object.assign(obj, this.page)
      this.$emit('search', obj)
    },
    setConfig(param) {
      let config = {
        border: true,
        align: 'left',
        stripe: false
      }
      config = Object.assign(config, param)
      return config
    },
    labelItem(item, row) {
      if (item.notValue && row.deviceState != 'offline') {
        return row[item.prop] || item.notValue
      } else {
        return !item.filter ? (row[item.prop] == -1 ? '-' : (row[item.prop])) : (row[item.prop] || item.filter).split(item.filter)[0]
      }
    },
    getStatesLabel(key, value) {
      if (!value && value != 0) {
        if (key == 'top' || key == 'isTop') {
          value = "0"
        } else {
          return '-'
        }
      }
      let states = {
        personStatus: [
          {value: '00', label: '正常', color: '2'},
          {value: '01', label: '失败', color: '3'},
        ],
        isEnable: [
          {value: true, label: '正常', color: '2'},
          {value: false, label: '停用', color: '3'},
        ],
        productState: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.PRODUCT_STATE.map(item => {
          return {label: item.codeDesc, value: item.codeValue, color: item.codeValue == 1 ? '2' : '3'}
        }),
        deviceState: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_STATE.map(item => {
          return {label: item.codeDesc, value: item.codeValue, color: item.codeValue == 'online' ? '2' : '3'}
        }),
        level: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.EVENT_LEVEL.map(item => {
          return {label: item.codeDesc, value: item.codeValue, color: '4'}
        }),
        warningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return {label: item.codeDesc, value: item.codeValue, color: item.codeValue}
        }),
        monitorWarningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return {label: item.codeDesc, value: item.codeValue, color: item.codeValue}
        }),
        deviceWarningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return {label: item.codeDesc, value: item.codeValue, color: item.codeValue}
        }),
        valueTypeContent: [
          {label: "整数型", value: 'int', color: '4'},
          {label: "数组", value: 'array', color: '4'},
          {label: "布尔型", value: 'boolean', color: '4'},
          {label: "时间型", value: 'date', color: '4'},
          {label: "双精度浮点型", value: 'double', color: '4'},
          {label: "枚举", value: 'enum', color: '4'},
          {label: "文件", value: 'file', color: '4'},
          {label: "单精度浮点型", value: 'float', color: '4'},
          {label: "地理位置", value: 'geoPoint', color: '4'},
          {label: "长整数型", value: 'long', color: '4'},
          {label: "结构体", value: 'object', color: '4'},
          {label: "密码", value: 'password', color: '4'},
          {label: "字符串", value: 'text', color: '4'},
          {label: "字符串", value: 'string', color: '4'},
        ],
        source: [
          {label: "设备", value: 'device', color: '4'},
          {label: "规则", value: 'rule', color: '4'},
          {label: "手动", value: 'manual', color: '4'},
        ],
        isSync: [
          {label: "是", value: true, color: '2'},
          {label: "否", value: false, color: '3'},
        ],
        async: [
          {label: "是", value: true, color: '2'},
          {label: "否", value: false, color: '3'},
        ],
        propertyType: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.PRODUCT_PROPERTY_TYPE.map(item => {
          return {label: item.codeDesc, value: item.codeValue, color: '4'}
        }),
        productType: [
          {label: "-", value: ''},
          {label: "深部位移采集器产品", value: 'collector', color: '4'},
          {label: "深部位移传感器产品", value: 'sensor', color: '4'},
          {label: "其他产品", value: 'other', color: '4'},
        ]
      }
      let labels = []
      labels = states[key].filter(item => {
        if (typeof (value) == 'number' || typeof (value) == 'boolean') {
          return value + "" == item.value + ''
        } else {
          return value == item.value + ''
        }
      })
      let labelStr = [], color = ''
      for (let item of labels) {
        labelStr.push(item.label)
        if (item.color) {
          color = item.color
        }
      }
      labelStr = labelStr.join(',')
      return {label: labelStr.length ? labelStr : '', color: color}
    },
    handleSizeChange(val) {
      let obj = this.$store.state.searchable
      this.page.pageSize = val
      obj = Object.assign(obj, this.page)
      this.$store.commit('setPageSize', val)
      this.$emit('search', obj)
    },
    handleCurrentChange(val) {
      let obj = this.$store.state.searchable
      obj = Object.assign(obj, this.page)
      this.$emit('search', obj)
    },
    selectRadio(rowData) {
      this.$emit('selection', rowData)
    },
    // handleSelectionChange(val) {
    //     this.$emit('selection', val)
    // },
    toggleRowSelection(selection, row) {
      this.$emit('selection', row)
      this.$emit('selectData', selection)
    },
    selectAll(selection) {
      let obj = {}
      if (selection.length) {
        obj = {tableData: selection, check: true}
      } else {
        obj = {tableData: this.tableData, check: false}
      }
      this.$emit('selectData', selection)
      this.$emit('selectAll', obj)
    }
  }
}
</script>
<style lang="scss" scoped>
.right {
  text-align: right;
  width: 100%;
  margin-top: 20px;
}

.el-icon-refresh {
  font-size: 16px;
  cursor: pointer;
}

.el-table span {
  user-select: initial;
}

.drawer-title {
  position: absolute;
  top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 50px 0 15px;

  span {
    line-height: normal;
    font-family: Alibaba PuHuiTi;
    font-weight: 700;
    color: #3e3e3e;
    font-size: 14px;
  }

  .el-button {
    line-height: normal;
    font-family: Alibaba PuHuiTi;
    color: #3e3e3e;
    font-size: 12px;
  }

}

.j-table {
  position: relative;
}

.table-setting {
  // border: 1px dashed;
  display: block;
  // padding: 3px 5px;
  position: absolute;
  top: -26px;
  right: 0;
  z-index: 10;
  cursor: pointer;
  // color: #2859f3;
  border: 1px solid;
  border-color: #dcdfe6;
  border-radius: 2px;
  padding: 4px 5px;
  font-family: Alibaba PuHuiTi;
  color: #606266;
  font-size: 13px;
  text-align: center;
}


.w-200 {
  width: auto;

}

.control {
  justify-content: center;
}

.hover-class {
  cursor: pointer;
  color: $primary;
}

.status {
  font-size: 12px;
  letter-spacing: 0;
  font-weight: 400;
  display: inline-block;
  padding: 0px 5px;
}

.status-0 {
  color: rgb(1, 92, 240);
  background-color: rgba(1, 92, 240, 0.2);
}

.status-1 {
  color: #faad15;
  background-color: rgba(250, 173, 21, 0.2);
}

.status-2 {
  color: #52c41a;
  background-color: rgba(82, 196, 26, 0.2);
}

.status-3 {
  color: #f95e5e;
  background-color: rgba(249, 94, 94, 0.2);
}

.status-4 {
  // color: #595959;
  // background-color: #fdfdfd !important;
  // border:1px solid #e9e9eb;
}

.status-5 {
  color: #fff;
  background-color: #80ffff;
}

.status1-0 {
  color: #33bf09
}

.status1-1 {
  //font-weight: bold;
  color: rgb(18, 150, 219)
}

.status1-2 {
  //font-weight: bold;
  color: #ffbb36
}

.status1-3 {
  //font-weight: bold;
  color: #ff8000
}

.status1-4 {
  //font-weight: bold;
  color: rgb(216, 30, 6)
}

.status1-5 {
  //font-weight: bold;
  color: rgb(191, 191, 191)
}

::v-deep .el-table td.el-table__cell {
  padding: 5px 0;
}

::v-deep .el-drawer__body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

::v-deep .el-table td.el-table__cell div {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .el-tag,
.el-table ::v-deep .el-table__cell {
  height: auto;
  padding: 3px 5px;
  line-height: 1;
  text-align: center !important;
}

.el-icon-link {
  margin-right: 5px;
}

.el-table {
  font-size: 13px;

  ::v-deep span {
    font-size: 13px;
  }
}

::v-deep .el-radio__label {
  display: none
}

::v-deep .el-drawer__close-btn {
  position: relative;
  z-index: 10000;
}

.draggable-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  // border: 1px dashed #ccc;
  margin-bottom: 8px;
  padding: 7px 5px;
  background: #f5f9ff;
  border: 1px dashed;
  border-color: rgba(2, 87, 222, 0.32);
  border-radius: 4px;
  cursor: move;

  span {
    font-size: 13px;
  }
}

.draggable-hide {
  background: #ffffff;
  border: 1px solid;
  border-color: #c1c1c1;
}

// ::v-deep .el-table__header,
// ::v-deep .el-table__header {
//     width: 100% !important;
// }

::v-deep .el-drawer__header > :first-child {
  font-size: 18px;
}

::v-deep .el-table__body tr.current-row > td {
  background: #d2e3f0 !important;
}
</style>