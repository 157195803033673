<template>
    <div>
        <div v-for="(item, i) in dlList" :class="'echartWarp' + i + (!item.isFullDl?' echart-warp':' echart-warp-full')" :key="i"
            @click="setzIndex(i)" v-if="item.show">
            <template>
                <echartline class="echartline" :index="i" :lineInfo="item" :class="' echartWarp' + i + (item.isFullDl?' e-w-100':'')"
                    @close="closeDialog($event, item)" @handleFull="handleFull(item)"></echartline>
            </template>
        </div>
    </div>
</template>
<script>
import echartline from "@/components/lineMap.vue";
export default {
    name: 'App',
    components: {
        echartline
    },
    data() {
        return {
            dlList: [],
            showIds: [],
            maxIndex: 2000,
            deviceName: {},
            isFullDl:true,
            // showEchart:true
        }
    },
    methods: {
        handleFull(item){
            item.isFullDl=!item.isFullDl
            // this.showEchart=false
            // this.$nextTick(()=>{
            //     this.showEchart=true
            // })
        },
        setzIndex(i) {
            let dom = document.getElementsByClassName('echartWarp' + i)[0]
            dom.style.zIndex = this.maxIndex
            this.maxIndex += 1
        },
        closeDialog(item) {
            this.$set(item, 'show', false)
            this.$set(item, 'isFullDl', false)
            this.showIds = this.showIds.filter(id => id != item.deviceId + '' + item.propertyType)
        },
        async showEchartDialog(data) {
            if (this.showIds.length == 5) {
                this.$message.error('最多添加五个弹窗')
                return
            }
            if (this.showIds.includes(data.deviceId + '' + data.propertyType)) {
                this.$message.error('已展示图标')
                return
            } else {
                this.showIds.push(data.deviceId + '' + data.propertyType)
            }
            // let params = {
            //     entity: {
            //         id: data.productId,
            //     },
            //     pageNum: 1,
            //     pageSize: 10000
            // }
            // { productId, deviceId, propertyType: 设备状态 'STATE' }
            // let res = await this.$api.queryProductPropertyListPage(params)
            // if (res) {
            //     let propertyIds = res.resultList.filter(item => {
            //         if (data.propertyType == 'STATE') {
            //             return !item.propertyType || item.propertyType == 'STATE'
            //         } else {
            //             return item.propertyType == data.propertyType
            //         }
            //     }).map(item => item.jetlinksPropertyId)
            //     this.dlList.push({
            //         show: true,
            //         isFullDl:false,
            //         monitorName:data.monitorName,
            //         propertyType: data.propertyType,
            //         // propertys: propertyIds.join(','),
            //         deviceId: data.deviceId,
            //         deviceName:data.deviceName
            //     })
            // }
            this.dlList.push({
                    show: true,
                    isFullDl:false,
                    monitorName:data.monitorName,
                    propertyType: data.propertyType,
                    // propertys: propertyIds.join(','),
                    deviceId: data.deviceId,
                    deviceName:data.deviceName,
                    deviceCode: data.deviceCode,
                    deviceTypeValue:data.deviceTypeValue,
                    warningStatus: data.warningStatus,
                })
          console.log('this.dlList', this.dlList)
          this.$nextTick(() => {
            this.setzIndex(this.dlList.length - 1);
          })
        },
    },
    mounted() {
       
    },
}
</script>
<style>
.echart-warp {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 600px;
    height: auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    z-index:100
}
.e-w-100{
    width:100%;
}
.echart-warp-full{
    position: fixed;
    top: 0!important;
    left: 0!important;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    z-index:100
}
</style>