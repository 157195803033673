<template>
    <el-dialog :title="title" :visible.sync="show" :width="width">
        <template>
            <!-- <jSearch :baseForm="baseForm" @search="search" :span="24" :labelWidth="'85px'" /> -->
            <jTable v-model="columnConfig" :tableData="tableData" :total="total" @search="search" height="350px"
                :radioction="true" @selection="selection" controlName="" :checkList="checkInfo">
            </jTable>
        </template>
        <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="cancle">取 消</el-button>
            <el-button type="primary" size="mini" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    name: "jRadioTable",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: "70%"
        },
        title:{
            type:[String],
            default:"确认主监测点"
        },
        mainInfo: {
            type: Object,
            default: () => { }
        },
        tableList: {
            type: Array,
            default: () => []
        },
        baseForm: {
            type: Object,
            default: () => {
                return {
                    monitorPointNum: { key: 'monitorPointNum', span: 5, label: "监测点编号", labelWidth: "85px", type: 'input', placeholder: "请输入" },
                    monitorPointName: { key: 'monitorPointName', span: 6, label: "监测点", type: 'input', placeholder: "请输入" },
                    monitorProjectName: { key: 'monitorProjectName', span: 5, label: "项目", type: 'input', placeholder: "请输入" },
                    countyCode: { key: 'countyCode', span: 8, label: "行政区划", type: 'area', placeholder: "请选择" },
                }
            }
        },
        columnConfig: {
            type: Array,
            default: () => {
                return [
                    { label: "监测点编号", prop: "monitorPointNum", width: 120 },
                    { label: "监测点", prop: "monitorPointName", width: 100 },
                    { label: "项目", prop: "monitorProjectName", width: 100 },
                    { label: "行政区划", prop: "regionName", width: 100 },
                    { label: "详细地址", prop: "detailAddress", width: 150 },
                    { label: "建设单位", prop: "construction"  },
                ]
            }
        },
    },
    data() {
        return {
            tableData: [],
            total: 0,
            show: false,
            checkInfo: {}
        };
    },
    watch: {
        value: {
            handler(val) {
                this.show = val
                val && this.queryList({ pageNum: 1, pageSize: 10 })
            },
            immediate: true,
        },
        mainInfo() {
            this.checkInfo = JSON.parse(JSON.stringify(this.mainInfo))
        },
    },
    methods: {
        cancle() {
            this.show = false
            this.checkInfo = JSON.parse(JSON.stringify(this.mainInfo))
            this.$emit('input', false)
        },
        search(formData) {
            this.queryList(formData)
        },
        queryList(formData) {
            let tableList = this.tableList
                .map(item => {
                    let regionName = []
                    item.provinceName && regionName.push(item.provinceName)
                    item.cityName && regionName.push(item.cityName)
                    item.countyName && regionName.push(item.countyName)
                    item.regionName = regionName.join('-')
                    return item
                })
            this.total = tableList.length || 0
            this.tableData = tableList.slice(this.$store.state.pageSize * (formData.pageNum * 1 - 1), this.$store.state.pageSize * formData.pageNum)
        },
        selection(checkInfo) {
            this.checkInfo = checkInfo
        },
        submit() {
            this.$emit('submit', JSON.parse(JSON.stringify(this.checkInfo)))
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep .el-alert--info.is-light {
    background: rgba(244, 244, 245, .3);
}

.tips {
    font-family: "Microsoft YaHei", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: $error;
    line-height: 22px;
}

.el-input-number {
    position: relative;
    width: 100%;

    ::before {
        content: '人';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $border;
    }
}

.fileName {
    color: $primary;
    font-size: 12px;
    cursor: pointer;
}

.down-load {
    ::v-deep .el-form-item__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 0px !important;
    }
}

::v-deep .el-table__body-wrapper {
    overflow: auto;
    position: relative;
    max-height: 400px;
}

::v-deep .el-dialog {
    margin-top: 5vh !important;
}
</style>