import router from "../router";
import axios from 'axios';
import store from "../store";
import { Message } from "element-ui";
import { Loading } from 'element-ui';
// 环境的切换
if (process.env.NODE_ENV == 'development') {
    // axios.defaults.baseURL = 'http://112.54.33.16:29800';
    axios.defaults.baseURL = '/api';
}
else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = '#';
}
else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = '/api';
}

//设置请求超时，单位ms
axios.defaults.timeout = 60000;

//post请求头的设置
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 请求拦截器
axios.interceptors.request.use(
    config => {
        // console.log(response,'response')
        // 每次发送请求之前判断vuex中是否存在token        
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
        const token = store.state.access_token;
        config.headers['bg-debug'] = 1
        if (config.data && config.data.entity) {
            delete config.data.entity['undefined']
        }
        if (token) {
            config.headers.Authorization = token.includes('bearer') ? token : 'bearer ' + token
        }
        if (!config.url) {
            return false
        } else {
            return config;
        }
    },
    error => {
        return Promise.error(error);
    })

function isJSON(str) {
    if (typeof str == "string") {
        try {
            JSON.parse(str)
            return true
        } catch (e) {
            return false
        }
    }
}
// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
        // 否则的话抛出错误
        if (response.status === 200) {
            // console.log(response,'responseresponseresponse')
            if (response.config.headers.responseType == 'blob') {
                return Promise.resolve(response);
            } else if (response.data.code === 100000) {
                if (isJSON(response)) { //json 字符串转化
                    response = JSON.parse(response)
                }
                return Promise.resolve(response.data);
            } else if (response.data.code === 100012) {
                if (isJSON(response)) { //json 字符串转化
                    response = JSON.parse(response)
                }
                return Promise.resolve(response.data);
            }
            else if (response.data.code == 100010) {
                Message.error('长时间未操作，即将退出登录')
                sessionStorage.setItem('access_token', '')
                store.commit('setAccessToken', '')
                window.location.reload();
                router.replace({
                    path: '/home',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                });
                return Promise.resolve(false);
            } else {
                response.data && response.data.msg && Message.error(response.data.msg)
                return Promise.resolve(false);
            }
        } else {
            Message.error('服务器感冒了')
            return Promise.reject(response);
        }
    },
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
var closeLoading=function(loading){
    let clearTime = setTimeout(()=>{
        loading.close()
        clearTimeout(clearTime)
    },300)
}
export function post(url, params, msg, unloading) {
    let loading = ""
    if(!unloading){
        // loading=Loading.service({
        //     lock: true,
        //     text: '拼命加载中...',//可以自定义文字
        //     spinner: 'el-icon-loading',//自定义加载图标类名
        //     background: 'rgba(0, 0, 0, 0.7)'//遮罩层背景色
        // })
    }
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                // !unloading&&closeLoading(loading)
                if (res.code == 100000) {
                    if (msg) {
                        Message.success(msg)
                    }
                    resolve(res.data || {});
                } else if (res.code == 100012) {
                    resolve(res || {});
                } else {
                    resolve(false);
                }
            })
            .catch(err => {
                // !unloading&&closeLoading(loading)
                reject(err.data)
            })
    });
}
export function postByBlob(url, params, msg) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, {
            headers: {
                responseType: "blob",
                responseEncoding: 'utf-8'
            }
        })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
export function postUploadFile(url, params, msg) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                console.log(res, 'ddddddddddd')
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
export function postDownFile(url, params, msg, contentType = 'application/x-www-form-urlencoded') {
    const newParams = new URLSearchParams();
    newParams.append('entity', "{}")
    return new Promise((resolve, reject) => {
        axios.post(url, newParams, {
            headers: {
                'Content-Type': contentType
            }
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
