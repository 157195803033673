<template>
    <div>
        <el-upload ref="upload" class="upload-demo" action="''" :disabled="disabled" :before-upload="volidFile"
            :on-remove="handleRemove" :file-list="fileList" :http-request="httpRequest" :limit="limit" :multiple="multiple"
            :on-exceed="onExceed" :accept="fileType" :on-preview="preview">
            <el-button size="small" :class="{'disabled':disabled}" :type="disabled?'info':'primary'" :plain="disabled">上传附件</el-button>
            <div slot="tip" class="el-upload__tip">{{ tip }}</div>
            <!-- <template slot="tips">
                <span>{{ tips }}</span>
            </template> -->
        </el-upload>
    </div>
</template>
<script>
export default {
    name: "jUploadFile",
    props: {
        value: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        limit: { //文件数量
            type: Number,
            default: 10
        },
        maxSize: { //文件大小
            type: Number,
            default: 2
        },
        maxNum: { //文件数量
            type: Number,
            default: 2
        },
        tip: {
            type: String,
            default: ""
        },
        fileType: {
            type: String,
            default:".pdf,.doc,.docx,.xlsx,.xls"
        },
    },
    data() {
        return {
            fileList: [],
            dialogImageUrl: '',
            dialogVisible: false,
            tips:`支持pdf/doc/docx/.xlsx/.xls格式，单个文件限制${this.maxSize}MB以内，最多上传${this.maxNum}个`
        };
    },
    watch: {
        value: {
            handler(val){
                if (Array.isArray(val) && val.length) {
                    this.fileList = val
                } else {
                    this.fileList = []
                }
            },
            immediate: true,
        }
    },
    mounted() {
      
    },
    methods: {
        handleRemove(file) {
            this.fileList = this.fileList.filter(item => item.url != file.url)
            this.$emit('input', this.fileList)
        },
        httpRequest(data) {
            if (!this.volidFile(data.file)) return
            // 封装FormData对象
            var formData = new FormData();
            formData.append("file", data.file);
            formData.append("model", 'person');
            // formData.append("type", data.file.type);
            formData.append("type", 'annex');
            // 调用后端接口
            this.$api.uploadFile(formData).then(res => {
                this.fileList.push({
                    name: res.fileName,
                    url: res.filePath,
                })
                this.$emit('input', this.fileList)
            }).catch(err => { })
        },
        volidFile(file) {
            let FileTypes = {
                '.jpeg': 'image/jpeg',
                '.png': 'image/png',
                '.pdf': 'application/pdf',
                '.pptx': "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                '.docx': "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                '.xlsx': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                '.txt': "text/plain",
                '.doc': "application/msword",
            }
            let limitTypes = {}
            for (let item of this.fileType.split(',')) {
                limitTypes[item] = FileTypes[item]
            }
           
            if (!Object.values(limitTypes).includes(file.type)) {
                this.$message.error('暂不支持该格式的文件，请重新上传！');
                return false
            }
             const maxSize = file.size / (1024 * 1024) < this.maxSize;
            if (!maxSize) {
                // this.$refs.upload.clearFiles();
                this.$message.error(`上传文件大小不能超过${this.maxSize}MB!`);
                return false
            }
            if (this.fileList.length >= this.maxNum) {
                // this.$refs.upload.clearFiles();
                this.$message.error(`最多可以上传${this.maxNum}个文件`);
                return false
            }
            return true
        },
        preview(file){
            console.log(file,'file')
            let fileId=file.url.split('/')[file.url.split('/').length-1]
            this.$tool.downFileByUrl(fileId,file.name,'下载成功')
        },
        onExceed() {
            this.$message.error(`最多可以上传${this.limit}个文件`)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        uploadFile() {
            this.$refs.upload.submit()
        },
    }
}
</script>
<style scoped lang="scss">
.el-upload__tip {
    color: #F56C6C;
    line-height: 18px;
    font-size: 10px;
}
::v-deep .el-upload-list__item {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>