<template>
    <el-form class="app-search-form">
        <el-row class="flex-start-row" :gutter="24" style="width:calc(100% + 24px)">
            <el-col v-for="(key, i) in sliceBaseForm()" :span="baseForm[key].span" :key="i">
                <el-form-item :class="{ 'h-2': baseForm[key].h2 }" v-if="baseForm[key].type === 'input'"
                    :label-width="baseForm[key].labelWidth || labelWidth" class="app-search-item"
                    :label="baseForm[key].label">
                    <el-input style="width:100%" v-model.trim="searchable[key]" clearable
                        :placeholder="baseForm[key].placeholder" />
                </el-form-item>
                <el-form-item :class="{ 'h-2': baseForm[key].h2 }" :label-width="baseForm[key].labelWidth || labelWidth"
                    v-else-if="baseForm[key].type === 'time' || baseForm[key].type === 'date'" class="app-search-item"
                    :label="baseForm[key].label">
                    <el-date-picker v-model="searchable[key]"
                        :type="baseForm[key].type === 'time' ? 'datetimerange' : 'daterange'" range-separator="-"
                        start-placeholder="开始日期" end-placeholder="结束日期"
                        :value-format="baseForm[key].type == 'time' ? `yyyy-MM-dd${baseForm[key].T ? 'T' : ' '}HH:mm:ss` : 'HH:mm:ss'"
                        :format="baseForm[key].type == 'time' ? `yyyy-MM-dd HH:mm:ss` : 'HH:mm:ss'">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :class="{ 'h-2': baseForm[key].h2 }" v-else-if="baseForm[key].type === 'jSelectTree'"
                    :label-width="baseForm[key].labelWidth || labelWidth" class="app-search-item"
                    :label="baseForm[key].label">
                    <template>
                        <jSelectTree :dataList="baseOption[key]" v-model="searchable[key]"
                            :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }" />
                    </template>
                </el-form-item>
                <el-form-item :class="{ 'h-2': baseForm[key].h2 }" v-else-if="baseForm[key].type === 'select'"
                    :label-width="baseForm[key].labelWidth || labelWidth" class="app-search-item"
                    :label="baseForm[key].label">
                    <el-select clearable filterable v-model="searchable[key]" :placeholder="baseForm[key].placeholder"
                        :multiple="Array.isArray(searchable[key]) || false">
                        <el-option v-for="item in baseOption[key]" :key="item.status" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- 行政区划 -->
                <el-form-item :class="{ 'h-2': baseForm[key].h2 }" v-else-if="baseForm[key].type === 'area'"
                    :label-width="baseForm[key].labelWidth || labelWidth" class="app-search-item"
                    :label="baseForm[key].label">
                    <div class="flex-row">
                        <el-select clearable filterable v-model="searchable.provinceCode" placeholder="省份" @change="($event) => {
            getSubOption($event, 'cityCode')
            searchable.provinceName = setAddressLabel($event, baseOption.provinceCode)
        }">
                            <el-option v-for="item in baseOption.provinceCode" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select clearable filterable class="mg-5" v-model="searchable.cityCode" placeholder="市"
                            @change="($event) => {
            getSubOption($event, 'countyCode')
            searchable.cityName = setAddressLabel($event, baseOption.cityCode)
        }">
                            <el-option v-for="item in baseOption.cityCode" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select clearable filterable v-model="searchable.countyCode" placeholder="区"
                            @change="searchable.countyName = setAddressLabel($event, baseOption.countyCode)">
                            <el-option v-for="item in baseOption.countyCode" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
            </el-col>
            <el-col :span="span1">
                <el-form-item label-width="0" class="app-search-item text-r search-item-control">
                    <template slot-scope="scope">
                        <slot name="control"></slot>
                    </template>
                </el-form-item>
            </el-col>
            <el-col :span="span">
                <el-form-item label-width="0" class="app-search-item text-r search-control" style="max-width: 100%">
                    <el-button type="text" size="mini" class="show-more" v-if="Object.keys(baseForm).length > sliceIdx"
                        @click="showMore = !showMore">{{ !showMore ? '展开' : '收起' }}
                        <i :class="{ 'el-icon-arrow-down': !showMore, 'el-icon-arrow-up': showMore }"></i></el-button>
                    <el-button type="primary" size="mini" @click="handleQuery">查询</el-button>
                    <el-button size="mini" @click="handleReset">重置</el-button>
                    <slot name="right"></slot>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<script>
export default {
    name: "jSearch",
    props: {
        baseForm: [Object, Array],
        span: {
            type: [Number, String],
            default: 6
        },
        span1: {
            type: [Number, String],
            default: 0
        },
        labelWidth: {
            type: String,
            default: "100px"
        },
      sliceIdx: {
          type: Number,
          default: 5
      }
    },
    data() {
        return {
            searchable: (() => {
                return { ...this.setSearchable(), pageSize: 10, pageNum: 1 }
            })(),
            baseOption: {

            },
            showMore: false,
        }
    },
    mounted() {
        this.init()
    },
    destroyed() {
        this.searchable = {}
        this.$store.commit('setSearchable', {})
        this.$bus.$emit('changePageNum', 1)
        document.removeEventListener('keyup', this.setKeyUp);
    },
    methods: {
        async init() {
            for (let item of Object.values(this.baseForm)) {
                if (['select', 'jSelectTree', 'area'].includes(item.type)) {
                    switch (item.label) {
                        case "用户状态":
                            this.$set(this.baseOption, item.key, [
                                { value: '', label: '全部' },
                                { value: true, label: '正常' },
                                { value: false, label: '停用' },
                            ])
                            break;
                        case "角色状态":
                            this.$set(this.baseOption, item.key, [
                                { value: '', label: '全部' },
                                { value: true, label: '启用' },
                                { value: false, label: '禁用' },
                            ])
                            break;
                        case "设备状态":
                            this.$set(this.baseOption, item.key, JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_STATE.map(item => {
                                return { label: item.codeDesc, value: item.codeValue }
                            }))
                            break;
                        case "所属机构":
                            this.$set(this.baseOption, item.key, [await this.$api.orgTreeQuery({ entity: {} })])
                            break;
                        case "项目":
                          let projectRes = await this.$api.projectQueryOptions({ entity: {} })
                          this.$set(this.baseOption, item.key, projectRes.map(item => {
                            return { label: item.name, value: item.id }
                          }))
                          break;
                        case "预警状态":
                            this.$set(this.baseOption, item.key, JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
                                return { label: item.codeDesc, value: item.codeValue }
                            }))
                            break;
                        case "产品状态":
                            this.$set(this.baseOption, item.key, JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.PRODUCT_STATE.map(item => {
                                return { label: item.codeDesc, value: item.codeValue }
                            }))
                            break;
                        case "设备类型":
                            this.$set(this.baseOption, item.key, JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_TYPE.map(item => {
                                return { label: item.codeDesc, value: item.codeValue }
                            }))
                            break;
                        case "接入方式":
                            let importType = await this.$api.queryAccessProviderList({ entity: {} })
                            this.$set(this.baseOption, item.key, importType.map(item => {
                                // return { label: item.accessName, value: item.accessProvider }
                                return { label: item.accessName, value: item.accessName }
                            }))
                            break;
                        case "所属产品":
                            let res1 = await this.$api.queryProductList({ entity: { } })
                            this.$set(this.baseOption, item.key, (res1 || []).filter(item => item.productState).map(item => {
                                return { label: item.name, value: item.id }
                            }))
                            break;
                        // case "异常类型":
                        //     let errorType = await this.$api.deviceErrorType({ entity: {} })
                        //     this.$set(this.baseOption, item.key, (errorType || []).map(item => {
                        //         return { label: item.errorType, value: item.errorType }
                        //     }))
                        //     break;
                        case "行政区划":
                            let res5 = await this.$api.provinceList({ entity: {} })
                            this.$set(this.baseOption, 'provinceCode', res5 || [])
                            break;
                        case "角色名称":
                            let params = {
                                entity: {},
                                pageSize: 100000,
                                pageNum: 1
                            }
                            let res = await this.$api.roleQueryPage(params)
                            if (res) {
                                let tableData = res.resultList.map(item => {
                                    return { label: item.name, value: item.id }
                                })
                                this.$set(this.baseOption, item.key, tableData)
                            }

                            break;
                    }
                }
            }
            document.addEventListener('keyup', this.setKeyUp);
        },
        setKeyUp(event) {
            if (event.key == "Enter") {
                this.handleQuery()
            } // 打印按键名称
        },
        sliceBaseForm() {
            return !this.showMore ? Object.keys(this.baseForm).slice(0, this.sliceIdx) : Object.keys(this.baseForm)
        },
        setSearchable() {
            let result = {}
            for (let key of Object.keys(this.baseForm)) {
                if (key != "search") {
                    result[key] = this.baseForm[key].value || ""
                }
            }
            result.cityCode = ''
            result.cityName = ''
            result.provinceName = ''
            result.provinceCode = ''
            result.countyName = ''
            result.countyCode = ''
            return result
        },
        getSubOption(parentId, key) {
            this.$api.regionSubList({ entity: { id: parentId } }).then(res => {
                if (res) {
                    this.$set(this.baseOption, key, res)
                }
            })
        },
        setAddressLabel(value, list, label) {
            let labels = (list || []).find(item => item.id == value)
            return labels.name || ''
        },
        handleQuery() {
            let obj = this.$store.state.searchable
            obj = Object.assign(obj, this.searchable)
            this.$store.commit('setSearchable', obj)
            this.$emit('search', obj)
            this.$bus.$emit('changePageNum', 1)
        },
        handleReset() {
            let obj = this.$store.state.searchable
            this.searchable = { ...this.setSearchable(), pageSize: 10, pageNum: 1 }
            obj = Object.assign(obj, this.searchable)
            this.$store.commit('setSearchable', obj)
            // this.$emit('resSet', obj)
            this.$emit('search', obj)
            this.$bus.$emit('changePageNum', 1)
        },
    },
}
</script>
<style scoped lang="scss">
::v-deep .el-select {
    width: 100%;
}

.el-icon-arrow-down::before,
.el-icon-arrow-up::before {
    font-size: 14px;
    margin-right: 5px;
}

::v-deep .text-r .el-form-item__content {
    text-align: right;
    line-height: 28px;
}

::v-deep .h-2 .el-form-item__label {
    line-height: 16px;
}

::v-deep .el-date-editor--datetimerange.el-input,
::v-deep .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
    max-width: 250px;
}

::v-deep .el-input__inner {
    max-width: 250px;
}

::v-deep .search-control .el-form-item__content {
    max-width: 100%;
}
</style>