
export default  [
    {
        "code": "%",
        "name": "百分比",
        "codeDesc": "百分比(%)",
        "id": "percent",
        "Desc": "百分比(%)",
        "type": "常用单位",
        "codeValue": "percent"
    },
    {
        "code": "count",
        "name": "次",
        "codeDesc": "次",
        "id": "count",
        "Desc": "次(count)",
        "type": "常用单位",
        "codeValue": "count"
    },
    {
        "code": "r/min",
        "name": "转每分钟",
        "codeDesc": "转每分钟",
        "id": "turnPerSeconds",
        "Desc": "转每分钟(r/min)",
        "type": "常用单位",
        "codeValue": "turnPerSeconds"
    },
    {
        "code": "nm",
        "name": "纳米",
        "codeDesc": "长度单位:纳米(nm)",
        "id": "nanometer",
        "Desc": "纳米(nm)",
        "type": "长度单位",
        "codeValue": "nanometer"
    },
    {
        "code": "μm",
        "name": "微米",
        "codeDesc": "长度单位:微米(μm)",
        "id": "micron",
        "Desc": "微米(μm)",
        "type": "长度单位",
        "codeValue": "micron"
    },
    {
        "code": "mm",
        "name": "毫米",
        "codeDesc": "长度单位:毫米(mm)",
        "id": "millimeter",
        "Desc": "毫米(mm)",
        "type": "长度单位",
        "codeValue": "millimeter"
    },
    {
        "code": "cm",
        "name": "厘米",
        "codeDesc": "长度单位:厘米(cm)",
        "id": "centimeter",
        "Desc": "厘米(cm)",
        "type": "长度单位",
        "codeValue": "centimeter"
    },
    {
        "code": "m",
        "name": "米",
        "codeDesc": "长度单位:米(m)",
        "id": "meter",
        "Desc": "米(m)",
        "type": "长度单位",
        "codeValue": "meter"
    },
    {
        "code": "km",
        "name": "千米",
        "codeDesc": "长度单位:千米(km)",
        "id": "kilometer",
        "Desc": "千米(km)",
        "type": "长度单位",
        "codeValue": "kilometer"
    },
    {
        "code": "mm²",
        "name": "平方毫米",
        "codeDesc": "面积单位:平方毫米(mm²)",
        "id": "squareMillimeter",
        "Desc": "平方毫米(mm²)",
        "type": "面积单位",
        "codeValue": "squareMillimeter"
    },
    {
        "code": "cm²",
        "name": "平方厘米",
        "codeDesc": "面积单位:平方厘米(cm²)",
        "id": "squareCentimeter",
        "Desc": "平方厘米(cm²)",
        "type": "面积单位",
        "codeValue": "squareCentimeter"
    },
    {
        "code": "m²",
        "name": "平方米",
        "codeDesc": "面积单位:平方米(m²)",
        "id": "squareMeter",
        "Desc": "平方米(m²)",
        "type": "面积单位",
        "codeValue": "squareMeter"
    },
    {
        "code": "km²",
        "name": "平方千米",
        "codeDesc": "面积单位:平方千米(km²)",
        "id": "squareKilometer",
        "Desc": "平方千米(km²)",
        "type": "面积单位",
        "codeValue": "squareKilometer"
    },
    {
        "code": "hm²",
        "name": "公顷",
        "codeDesc": "面积单位:公顷(hm²)",
        "id": "hectare",
        "Desc": "公顷(hm²)",
        "type": "面积单位",
        "codeValue": "hectare"
    },
    {
        "code": "d",
        "name": "天",
        "codeDesc": "时间单位:天(d)",
        "id": "days",
        "Desc": "天(d)",
        "type": "时间单位",
        "codeValue": "days"
    },
    {
        "code": "h",
        "name": "小时",
        "codeDesc": "时间单位:小时(h)",
        "id": "hour",
        "Desc": "小时(h)",
        "type": "时间单位",
        "codeValue": "hour"
    },
    {
        "code": "min",
        "name": "分钟",
        "codeDesc": "时间单位:分钟(m)",
        "id": "minutes",
        "Desc": "分钟(min)",
        "type": "时间单位",
        "codeValue": "minutes"
    },
    {
        "code": "s",
        "name": "秒",
        "codeDesc": "时间单位:秒(s)",
        "id": "seconds",
        "Desc": "秒(s)",
        "type": "时间单位",
        "codeValue": "seconds"
    },
    {
        "code": "ms",
        "name": "毫秒",
        "codeDesc": "时间单位:毫秒(ms)",
        "id": "milliseconds",
        "Desc": "毫秒(ms)",
        "type": "时间单位",
        "codeValue": "milliseconds"
    },
    {
        "code": "μs",
        "name": "微秒",
        "codeDesc": "时间单位:微秒(μs)",
        "id": "microseconds",
        "Desc": "微秒(μs)",
        "type": "时间单位",
        "codeValue": "microseconds"
    },
    {
        "code": "ns",
        "name": "纳秒",
        "codeDesc": "时间单位:纳秒(ns)",
        "id": "nanoseconds",
        "Desc": "纳秒(ns)",
        "type": "时间单位",
        "codeValue": "nanoseconds"
    },
    {
        "code": "mm³",
        "name": "立方毫米",
        "codeDesc": "体积单位:立方毫米(mm³)",
        "id": "cubicMillimeter",
        "Desc": "立方毫米(mm³)",
        "type": "体积单位",
        "codeValue": "cubicMillimeter"
    },
    {
        "code": "cm³",
        "name": "立方厘米",
        "codeDesc": "体积单位:立方厘米(cm³)",
        "id": "cubicCentimeter",
        "Desc": "立方厘米(cm³)",
        "type": "体积单位",
        "codeValue": "cubicCentimeter"
    },
    {
        "code": "m³",
        "name": "立方米",
        "codeDesc": "体积单位:立方米(m³)",
        "id": "cubicMeter",
        "Desc": "立方米(m³)",
        "type": "体积单位",
        "codeValue": "cubicMeter"
    },
    {
        "code": "km³",
        "name": "立方千米",
        "codeDesc": "体积单位:立方千米(km³)",
        "id": "cubicKilometer",
        "Desc": "立方千米(km³)",
        "type": "体积单位",
        "codeValue": "cubicKilometer"
    },
    {
        "code": "m³/s",
        "name": "立方米每秒",
        "codeDesc": "流量单位:立方米每秒(m³/s)",
        "id": "cubicMeterPerSec",
        "Desc": "立方米每秒(m³/s)",
        "type": "流量单位",
        "codeValue": "cubicMeterPerSec"
    },
    {
        "code": "km³/s",
        "name": "立方千米每秒",
        "codeDesc": "流量单位:立方千米每秒(km³/s)",
        "id": "cubicKilometerPerSec",
        "Desc": "立方千米每秒(km³/s)",
        "type": "流量单位",
        "codeValue": "cubicKilometerPerSec"
    },
    {
        "code": "cm³/s",
        "name": "立方厘米每秒",
        "codeDesc": "流量单位:立方厘米每秒(cm³/s)",
        "id": "cubicCentimeterPerSec",
        "Desc": "立方厘米每秒(cm³/s)",
        "type": "流量单位",
        "codeValue": "cubicCentimeterPerSec"
    },
    {
        "code": "l/s",
        "name": "升每秒",
        "codeDesc": "流量单位:升每秒(l/s)",
        "id": "litrePerSec",
        "Desc": "升每秒(l/s)",
        "type": "流量单位",
        "codeValue": "litrePerSec"
    },
    {
        "code": "m³/h",
        "name": "立方米每小时",
        "codeDesc": "流量单位:立方米每小时(m³/h)",
        "id": "cubicMeterPerHour",
        "Desc": "立方米每小时(m³/h)",
        "type": "流量单位",
        "codeValue": "cubicMeterPerHour"
    },
    {
        "code": "km³/h",
        "name": "立方千米每小时",
        "codeDesc": "流量单位:立方千米每小时(km³/h)",
        "id": "cubicKilometerPerHour",
        "Desc": "立方千米每小时(km³/h)",
        "type": "流量单位",
        "codeValue": "cubicKilometerPerHour"
    },
    {
        "code": "cm³/h",
        "name": "立方厘米每小时",
        "codeDesc": "流量单位:立方厘米每小时(cm³/h)",
        "id": "cubicCentimeterPerHour",
        "Desc": "立方厘米每小时(cm³/h)",
        "type": "流量单位",
        "codeValue": "cubicCentimeterPerHour"
    },
    {
        "code": "l/h",
        "name": "升每小时",
        "codeDesc": "流量单位:升每小时(l/h)",
        "id": "litrePerHour",
        "Desc": "升每小时(l/h)",
        "type": "流量单位",
        "codeValue": "litrePerHour"
    },
    {
        "code": "mL",
        "name": "毫升",
        "codeDesc": "容积单位:毫升(mL)",
        "id": "milliliter",
        "Desc": "毫升(mL)",
        "type": "容积单位",
        "codeValue": "milliliter"
    },
    {
        "code": "L",
        "name": "升",
        "codeDesc": "容积单位:升(L)",
        "id": "litre",
        "Desc": "升(L)",
        "type": "容积单位",
        "codeValue": "litre"
    },
    {
        "code": "mg",
        "name": "毫克",
        "codeDesc": "重量单位:毫克(mg)",
        "id": "milligram",
        "Desc": "毫克(mg)",
        "type": "质量单位",
        "codeValue": "milligram"
    },
    {
        "code": "g",
        "name": "克",
        "codeDesc": "重量单位:克(g)",
        "id": "gramme",
        "Desc": "克(g)",
        "type": "质量单位",
        "codeValue": "gramme"
    },
    {
        "code": "kg",
        "name": "千克",
        "codeDesc": "重量单位:千克(kg)",
        "id": "kilogram",
        "Desc": "千克(kg)",
        "type": "质量单位",
        "codeValue": "kilogram"
    },
    {
        "code": "t",
        "name": "吨",
        "codeDesc": "重量单位:吨(t)",
        "id": "ton",
        "Desc": "吨(t)",
        "type": "质量单位",
        "codeValue": "ton"
    },
    {
        "code": "Pa",
        "name": "帕斯卡",
        "codeDesc": "压力单位:帕斯卡(Pa)",
        "id": "pascal",
        "Desc": "帕斯卡(Pa)",
        "type": "压力单位",
        "codeValue": "pascal"
    },
    {
        "code": "kPa",
        "name": "千帕斯卡",
        "codeDesc": "压力单位:千帕斯卡(kPa)",
        "id": "kiloPascal",
        "Desc": "千帕斯卡(kPa)",
        "type": "压力单位",
        "codeValue": "kiloPascal"
    },
    {
        "code": "N",
        "name": "牛顿",
        "codeDesc": "力单位:牛顿(N)",
        "id": "newton",
        "Desc": "牛顿(N)",
        "type": "力单位",
        "codeValue": "newton"
    },
    {
        "code": "N.m",
        "name": "牛·米",
        "codeDesc": "力单位:牛·米(N.m)",
        "id": "newtonMeter",
        "Desc": "牛·米(N.m)",
        "type": "力单位",
        "codeValue": "newtonMeter"
    },
    {
        "code": "K",
        "name": "开尔文",
        "codeDesc": "温度单位:开尔文(K)",
        "id": "kelvin",
        "Desc": "开尔文(K)",
        "type": "温度单位",
        "codeValue": "kelvin"
    },
    {
        "code": "℃",
        "name": "摄氏度",
        "codeDesc": "温度单位:摄氏度(℃)",
        "id": "celsiusDegrees",
        "Desc": "摄氏度(℃)",
        "type": "温度单位",
        "codeValue": "celsiusDegrees"
    },
    {
        "code": "℉",
        "name": "华氏度",
        "codeDesc": "温度单位:华氏度(℉)",
        "id": "fahrenheit",
        "Desc": "华氏度(℉)",
        "type": "温度单位",
        "codeValue": "fahrenheit"
    },
    {
        "code": "J",
        "name": "焦耳",
        "codeDesc": "能单位:焦耳(J)",
        "id": "joule",
        "Desc": "焦耳(J)",
        "type": "能量单位",
        "codeValue": "joule"
    },
    {
        "code": "cal",
        "name": "卡",
        "codeDesc": "能单位:卡(cal)",
        "id": "cal",
        "Desc": "卡(cal)",
        "type": "能量单位",
        "codeValue": "cal"
    },
    {
        "code": "W",
        "name": "瓦特",
        "codeDesc": "功率单位:瓦特(W)",
        "id": "watt",
        "Desc": "瓦特(W)",
        "type": "功率单位",
        "codeValue": "watt"
    },
    {
        "code": "kW",
        "name": "千瓦特",
        "codeDesc": "功率单位:千瓦特(kW)",
        "id": "kilowatt",
        "Desc": "千瓦特(kW)",
        "type": "功率单位",
        "codeValue": "kilowatt"
    },
    {
        "code": "rad",
        "name": "弧度",
        "codeDesc": "角度单位:弧度(rad)",
        "id": "radian",
        "Desc": "弧度(rad)",
        "type": "角度单位",
        "codeValue": "radian"
    },
    {
        "code": "°",
        "name": "度",
        "codeDesc": "角度单位:度(°)",
        "id": "degrees",
        "Desc": "度(°)",
        "type": "角度单位",
        "codeValue": "degrees"
    },
    {
        "code": "′",
        "name": "[角]分",
        "codeDesc": "角度单位:分(′)",
        "id": "fen",
        "Desc": "[角]分(′)",
        "type": "角度单位",
        "codeValue": "fen"
    },
    {
        "code": "″",
        "name": "[角]秒",
        "codeDesc": "角度单位:度(″)",
        "id": "angleSeconds",
        "Desc": "[角]秒(″)",
        "type": "角度单位",
        "codeValue": "angleSeconds"
    },
    {
        "code": "Hz",
        "name": "赫兹",
        "codeDesc": "频率单位:赫兹(Hz)",
        "id": "hertz",
        "Desc": "赫兹(Hz)",
        "type": "频率单位",
        "codeValue": "hertz"
    },
    {
        "code": "MHz",
        "name": "兆赫兹",
        "codeDesc": "频率单位:兆赫兹(MHz)",
        "id": "megahertz",
        "Desc": "兆赫兹(MHz)",
        "type": "频率单位",
        "codeValue": "megahertz"
    },
    {
        "code": "GHz",
        "name": "G赫兹",
        "codeDesc": "频率单位:G赫兹(GHz)",
        "id": "ghertz",
        "Desc": "G赫兹(GHz)",
        "type": "频率单位",
        "codeValue": "ghertz"
    },
    {
        "code": "m/s",
        "name": "米每秒",
        "codeDesc": "速度单位:米每秒(m/s)",
        "id": "mPerSec",
        "Desc": "米每秒(m/s)",
        "type": "速度单位",
        "codeValue": "mPerSec"
    },
    {
        "code": "km/h",
        "name": "千米每小时",
        "codeDesc": "速度单位:千米每小时(km/h)",
        "id": "kmPerHr",
        "Desc": "千米每小时(km/h)",
        "type": "速度单位",
        "codeValue": "kmPerHr"
    },
    {
        "code": "kn",
        "name": "节",
        "codeDesc": "速度单位:节(kn)",
        "id": "knots",
        "Desc": "节(kn)",
        "type": "速度单位",
        "codeValue": "knots"
    },
    {
        "code": "V",
        "name": "伏特",
        "codeDesc": "电压:伏特(V)",
        "id": "volt",
        "Desc": "伏特(V)",
        "type": "电力单位",
        "codeValue": "volt"
    },
    {
        "code": "kV",
        "name": "千伏",
        "codeDesc": "电压:千伏(kV)",
        "id": "kiloVolt",
        "Desc": "千伏(kV)",
        "type": "电力单位",
        "codeValue": "kiloVolt"
    },
    {
        "code": "mV",
        "name": "毫伏",
        "codeDesc": "电压:毫伏(mV)",
        "id": "milliVolt",
        "Desc": "毫伏(mV)",
        "type": "电力单位",
        "codeValue": "milliVolt"
    },
    {
        "code": "μV",
        "name": "微伏",
        "codeDesc": "电压:微伏(μV)",
        "id": "microVolt",
        "Desc": "微伏(μV)",
        "type": "电力单位",
        "codeValue": "microVolt"
    },
    {
        "code": "A",
        "name": "安培",
        "codeDesc": "电流:安培(A)",
        "id": "ampere",
        "Desc": "安培(A)",
        "type": "电力单位",
        "codeValue": "ampere"
    },
    {
        "code": "mA",
        "name": "毫安",
        "codeDesc": "电流:毫安(mA)",
        "id": "milliAmpere",
        "Desc": "毫安(mA)",
        "type": "电力单位",
        "codeValue": "milliAmpere"
    },
    {
        "code": "μA",
        "name": "微安",
        "codeDesc": "电流:微安(μA)",
        "id": "microAmpere",
        "Desc": "微安(μA)",
        "type": "电力单位",
        "codeValue": "microAmpere"
    },
    {
        "code": "nA",
        "name": "纳安",
        "codeDesc": "电流:纳安(nA)",
        "id": "nanoAmpere",
        "Desc": "纳安(nA)",
        "type": "电力单位",
        "codeValue": "nanoAmpere"
    },
    {
        "code": "Ω",
        "name": "欧姆",
        "codeDesc": "电阻:欧姆(Ω)",
        "id": "ohm",
        "Desc": "欧姆(Ω)",
        "type": "电力单位",
        "codeValue": "ohm"
    },
    {
        "code": "KΩ",
        "name": "千欧",
        "codeDesc": "电阻:千欧(KΩ)",
        "id": "kiloOhm",
        "Desc": "千欧(KΩ)",
        "type": "电力单位",
        "codeValue": "kiloOhm"
    },
    {
        "code": "MΩ",
        "name": "兆欧",
        "codeDesc": "电阻:兆欧(MΩ)",
        "id": "millionOhm",
        "Desc": "兆欧(MΩ)",
        "type": "电力单位",
        "codeValue": "millionOhm"
    },
    {
        "code": "eV",
        "name": "电子伏",
        "codeDesc": "能单位:电子伏(eV)",
        "id": "electronVolts",
        "Desc": "电子伏(eV)",
        "type": "电力单位",
        "codeValue": "electronVolts"
    },
    {
        "code": "kW·h",
        "name": "千瓦·时",
        "codeDesc": "能单位:千瓦·时(kW·h)",
        "id": "kWattsHour",
        "Desc": "千瓦·时(kW·h)",
        "type": "电力单位",
        "codeValue": "kWattsHour"
    },
    {
        "code": "kgce",
        "name": "Kg标准煤",
        "codeDesc": "能源单位/kgce",
        "id": "kgce",
        "Desc": "Kg标准煤(kgce)",
        "type": "能源单位",
        "codeValue": "kgce"
    }
]