<template>
    <div>
        <div v-clickoutside="closeTree">
            <div @click="inputFocus"><el-input v-model="inputValue" :disabled="disabled"
                    :style="'width:' + width"></el-input></div>
            <div class="treeModule">
                <el-tree class="ORGTree" v-show="ishowTree" ref="tree" show-checkbox :data="dataList"
                    :disabled="disabled" :key="defaultProps.value" :node-key="defaultProps.value"
                    :default-checked-keys="defaultCheckedKeys" highlight-current :check-on-click-node="true"
                    @check-change="handleNodeClick" :props="defaultProps" :filter-node-method="filterNode"
                    :check-strictly="checkStrictly" :default-expanded-keys="defaultExpandedKeys">
                </el-tree>
            </div>
        </div>
    </div>
</template>

<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
export default {
    directives: { Clickoutside },
    name: "jMenuTree",
    props: {
        value: {
            type: Array,
            default: () => []
        },
        dataList: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: true
        },
        defaultProps: {
            type: Object,
            default: () => {
                return { children: 'childOrgVos', label: 'orgName', value: 'orgNum' }
            }
        },
        width: {
            type: String,
            default: '100%'
        },
        checkStrictly: {
          type: Boolean,
          default: false,
        },
        defaultExpandedKeys: {
          type: Array
        },
    },
    data() {
        return {
            inputValue: '',
            ishowTree: false,
            defaultCheckedKeys: [],
        }
    },
    mounted() {

    },
    watch: {
        value: {
            handler(val) {
                if (!val) {
                    this.inputValue = ""
                } else {
                    this.defaultCheckedKeys = val
                    let inputValue = []
                    this.getTreeLabel(this.dataList, this.defaultCheckedKeys, inputValue)
                    this.inputValue = inputValue.join(',')
                }
            },
            immediate: true,
        },
        inputValue:{
            handler(val){
                // this.$refs.tree.filter(val);
            },
        }
    },
    methods: {
        filterNode(value, data) {
            if (!value||this.searchable) return true;
            return data[this.defaultProps.label].indexOf(value) !== -1;
        },
        getTreeLabel(list, ids, inputValue) {
            for (let li of list) {
                if (ids.includes(li[this.defaultProps.value])) {
                    li[this.defaultProps.label] && inputValue.push(li[this.defaultProps.label])
                }
                if (li[this.defaultProps.children] && li[this.defaultProps.children].length) {
                    this.getTreeLabel(li[this.defaultProps.children], ids, inputValue)
                }
            }
        },
        inputFocus() {
          console.log('inputFocus')
          if (!this.disabled) {
            this.ishowTree = !this.ishowTree
          }
        },
        closeTree() {
            this.ishowTree = false
        },
        handleNodeClick(e) {
            let checkNodes = this.$refs.tree.getCheckedNodes()
            // console.log(checkNodes, 'checkNodes')
            this.inputValue = checkNodes.map(node => node[this.defaultProps.label]).join(',')
            this.$emit('input', checkNodes.map(node => node[this.defaultProps.value]))
        }
    }

}
</script>

<style scoped>
.treeModule {
    position: absolute;
    /*这里一定要设置*/
    z-index: 999999;
    /*这里是该元素与显示屏的距离，据说越大越好，但是我也没有看到效果，因为没有它也是可以的*/
}

.ORGTree {
    min-width: 220px;
    height: 300px;
    overflow: auto;
    border: 1px solid #ccc7c7;

}
</style>