<template>
    <div>
        <el-upload ref="upload" class="upload-demo" :action="''" :disabled="disabled"
            :on-remove="handleRemove" :file-list="fileList" :http-request="httpRequest" :limit="1"
            :on-change="handleChange" :on-exceed="onExceed" :auto-upload="false" accept=".xlsx,.xls"
            :on-error="uploadFileError" :on-success="uploadFileSuccess"
        >
            <el-button size="small" :class="{ 'disabled': disabled }" :type="disabled ? 'info' : 'primary'"
                :plain="disabled">上传附件</el-button>
            <div slot="tip" class="el-upload__tip">{{ tips }}</div>
            <!-- <template slot="tips">
                <span>{{ tips }}</span>
            </template> -->
        </el-upload>
    </div>
</template>
<script>
export default {
    name: "importExecl",
    props: {
        value: {
            type: Array,
            default: () => []
        },
        actionUrl: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        limit: { //文件数量
            type: Number,
            default: 1
        },
        maxSize: { //文件大小
            type: Number,
            default: 2
        },
        maxNum: { //文件数量
            type: Number,
            default: 2
        },
        tip: {
            type: String,
            default: ""
        },
        fileType: {
            type: Array,
            default: () => ['txt', 'xlsx', 'docx', 'pptx', 'doc']
        },
    },
    data() {
        return {
            fileList: [],
            dialogImageUrl: '',
            dialogVisible: false,
            tips: `支持.xlsx/.xls格式，单个文件限制${this.maxSize}MB以内，最多上传${this.maxNum}个`
        };
    },
    watch: {
        value: {
            handler(val) {
                if (Array.isArray(val) && val.length) {
                    this.fileList = val
                } else {
                    this.fileList = []
                }
            },
            immediate: true,
        }
    },
    mounted() {

    },
    methods: {
        handleRemove(file) {
            this.fileList = this.fileList.filter(item => item.url != file.url)
            this.$emit('input', this.fileList)
        },

        handleChange(file, fileList) {
            this.$emit('input', fileList)
        },
        httpRequest(data) {
          // console.log('httpRequest:', data)
            // 封装FormData对象
            var formData = new FormData();
            formData.append("file", data.file);
            // formData.append("model", 'person');
            // formData.append("type", data.file.type);
            formData.append("type", 'annex');
            // 调用后端接口
            this.$api.uploadFile(formData).then(res => {
                let fileId = res.fileId
                this.$api[this.actionUrl]({ entity: { fileId } }).then(res => {
                  console.log('actionUrl res:', res)
                    if (res) {
                        if (res.code == 100012) {
                            this.$emit('onFinish')
                            this.$tool.downFileByUrl(res.data.fileId, '错误信息下载','错误文件下载成功')
                        } else {
                            this.$message.success(`导入成功`)
                            this.clearFiles()
                            this.$emit('close')
                        }
                    } else {
                      console.log('onUploadFinished')
                      this.$emit('onFinish')
                    }
                }).catch(err => {
                    this.clearFiles()
                    this.$emit('onFinish')
                })
            })

        },
        onExceed(file) {
            console.log(file)
            this.$message.error(`最多可以上传${this.limit}个文件`)
        },
        onUploadFinished() {
          console.log('onUploadFinished')
          this.$emit('onFinish')
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        uploadFile() {
            this.$refs.upload.submit()
        },
        clearFiles() {
            this.$refs.upload.clearFiles()
            this.fileList = []
        }
    },
}
</script>
<style scoped lang="scss">
.el-upload__tip {
    color: #F56C6C;
    line-height: 18px;
    font-size: 10px;
}

::v-deep .el-upload-list__item-name {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>