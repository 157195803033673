import person from "./childApi/person";
import com from "./childApi/com";
import device from "./childApi/device";
import monitor from "./childApi/monitor";
import warning from "./childApi/warning";
export default {
    ...person,
    ...com,
    ...device,
    ...monitor,
    ...warning
}