<template>
  <div class="menu-tree">
    <div v-for="(list, i) in dataList">
      <el-submenu v-if="list.children && list.children.length" :index="list.path">
        <span slot="title" :class="`${$route.path.includes(list.path)?'active':''}`">{{ list.meta.title }}</span>
        <child-menu :dataList="list.children" :index="list.path"></child-menu>
      </el-submenu>
      <router-link :to="{ name: list.name }" v-else>
        <el-menu-item :index="list.path" ><span :class="`${$route.path.includes(list.path)?'active':''}`">{{ list.meta.title }}</span></el-menu-item>
<!--        <el-menu-item :index="list.path" ><span :class="`${$route.path.startsWith(list.path)?'active':''}`">{{ list.meta.title }}</span></el-menu-item>-->
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "childMenu",
  props: {
    dataList: {
      type: [Object, Array]
    },
    index: {
      type: String,
      default: ''
    },
  },
  mounted(){
    console.log('dataList', this.dataList)
  },
};
</script>
<style scoped>
.active,.active.iconfont::before{
  color:#2859f3;
}
</style>